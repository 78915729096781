import { colors } from '@material-ui/core';

const backgroundColor = 'rgba(0,0,0,0)';


export const loginFrom = {
  loginFrom: {
    root: {
      '& .MuiTextField-root': {
        color: JSON.parse(localStorage.getItem("themeConfig"))?.requiereDarkLogin ? colors.common.black : colors.common.white
      },
      '& .MuiInputBase-root': {
        color: JSON.parse(localStorage.getItem("themeConfig"))?.requiereDarkLogin ? colors.common.black : colors.common.white
      },
      //Cambia el color del label del text field
      /*'& .MuiInputLabel': {
        color: requiereDarkLogin ? colors.common.black : colors.common.white
      },*/
    }
  },
  text: {
    primary: JSON.parse(localStorage.getItem("themeConfig"))?.requiereDarkLogin ? colors.common.black : colors.common.white,
    secondary: JSON.parse(localStorage.getItem("themeConfig"))?.requiereDarkLogin ? colors.common.black : colors.common.white
  },
  card: {
    backgroundColor: backgroundColor,
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    border: 'none',
    boxShadow: 'none',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%'
    }
  }
}
