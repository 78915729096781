function getOS() {
  const { userAgent } = window.navigator;
  // const { platform } = window.navigator;
  const platform = userAgent;
  let os = null;

  if (platform.indexOf("Win") !== -1) os = "Windows";
  if (platform.indexOf("Mac") !== -1) os = "MacOS";
  if (platform.indexOf("X11") !== -1) os = "UNIX";
  if (platform.indexOf("Linux") !== -1) os = "Linux";
  if (platform.indexOf("Android") !== -1) os = "Android";
  if (platform.indexOf("iPhone") !== -1) os = "iPhone";
  if (platform.indexOf("iPad") !== -1) os = "iPad";
  if (platform.indexOf("iPod") !== -1) os = "iPod";

  return os;
}

export default getOS;

