import { colors } from '@material-ui/core';

const backgroundColor = '#EEEEEE';

export const normalView = {
  text: {
    primary: colors.common.white,
    secondary: colors.common.white
  },
  box: {
    backgroundColor: backgroundColor,
    overflow: 'visible',
    border: 'none',
    boxShadow: 'none',
  },
  card: {
    backgroundColor: backgroundColor,
    overflow: 'visible',
    border: 'none',
    boxShadow: 'none',
  },
  cardTransparent: {
    backgroundColor: 'rgba(0,0,0,0)',
    overflow: 'visible',
    border: 'none',
    boxShadow: 'none',
  },
  boxTransparent: {
    backgroundColor: 'rgba(0,0,0,0)',
    overflow: 'visible',
    border: 'none',
    boxShadow: 'none',
  },
}
