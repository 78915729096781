//import axios from 'src/utils/axios';
import axios from '../utils/axios';

import { account_service_api_url as API_URL } from 'src/services/backendApiService';

let BODY_HEADER = {}

const direct_api_tar = false;

if (direct_api_tar)
{
  // API TARJETA HABIENTES DIRECTAMENTE
  BODY_HEADER = {
    idSesion: "102",
    idRequest: "123456",
    codigoEF: "csoto",
    canal: "102",
    idClienteEF: "prueba",
    ciCliente: "csoto"
  }
}
else
{
  // BACKEND DE DIMO
  BODY_HEADER = {
    appVersion:"1.0.2",
    token:"prueba",
    user:"csoto"
  }
}

const CONFIG = {
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  }
};


export const LISTA_INCIDENCIAS = {
  INCIDENCIA_CATASTRO_OTP: { id: 1, descripcion: ""},
  INCIDENCIA_COMP_REGISTRO_SELFIE: { id: 2, descripcion: ""},
  INCIDENCIA_VINCULAR_COOP_FALLO: { id: 3, descripcion: ""},
  INCIDENCIA_CAMBIO_CONTRASENHA: { id: 5, descripcion: 'Cambio de contraseña desde el Perfil del Usuario'},
  INCIDENCIA_POR_ACTIVACION: { id: 6, descripcion: ""},
  INCIDENCIA_COMP_REGISTRO_FRONT_CI: { id: 7, descripcion: ""},
  INCIDENCIA_COMP_REGISTRO_BACK_CI: { id: 8, descripcion: ""},
  INCIDENCIA_COMP_REGISTRO_NRO_DOC_CI_VS_CATASTRO: { id: 9, descripcion: ""},
  INCIDENCIA_COMP_REGISTRO_SELFIE_VS_FOTO_CI: { id: 10, descripcion: ""},
  INCIDENCIA_COMP_REGISTRO_NOMBRES_CI_VS_PN: { id: 11, descripcion: ""},
  INCIDENCIA_COMP_REGISTRO_APELLIDOS_CI_VS_PN: { id: 12, descripcion: ""},
  INCIDENCIA_COMP_REGISTRO_ANHO_CI_VS_PN: { id: 13, descripcion: ""},
  INCIDENCIA_COMP_REGISTRO_MES_CI_VS_PN: { id: 14, descripcion: ""},
  INCIDENCIA_COMP_REGISTRO_DIA_CI_VS_PN: { id: 15, descripcion: ""},

  INCIDENCIA_COMP_REGISTRO_LEER_DATOS: { id: 16, descripcion: ""},
  INCIDENCIA_COMP_REGISTRO_CI_VENCIDA: { id: 17, descripcion: ""},
  INCIDENCIA_COMP_REGISTRO_DESCONOCIDO: { id: 16, descripcion: ""},

  INCIDENCIA_CAMBIO_NOMBRE_APELLIDO: { id: 18, descripcion: "Cambio de Nombres y Apellidos desde el Perfil del Usuario"},
  INCIDENCIA_CAMBIO_EMAIL: { id: 19, descripcion: "Cambio de Correo Electronico desde el Perfil del Usuario"},
  INCIDENCIA_CAMBIO_NUMERO_TELEFONO: { id: 20, descripcion: "Cambio de Numero de Telefono desde el Perfil del Usuario"},

  INCIDENCIA_VERIFICAR_USUARIO: { id: 22, descripcion: "Verificacion de Usuario via ATC"},
  INCIDENCIA_BLOQUEO_USUARIO: { id: 23, descripcion: "Bloqueo a usuario por reintentos fallidos de la contrasena"},

}

class IncidenciasService {


  getIncidenciaRegistroFormatoData = (photoData, policeData, coincidencia) =>{
    let incidencia = {
      photoData: (photoData != null && photoData !== undefined) ? photoData : {},
      policeData: (policeData != null && policeData !== undefined) ? policeData : {},
      coincidencia: (coincidencia != null && coincidencia !== undefined) ? coincidencia : "",
    }

    return incidencia;
  }

  crearIncidenciaRegistro = (incidencia) => new Promise((resolve, reject) => {

    const dataPrm = {
      idTipoIncidencia: incidencia.hasOwnProperty("idTipoIncidencia") ? incidencia.idTipoIncidencia : 999,
      datosIncidencia: incidencia.hasOwnProperty("datosIncidencia") ? incidencia.datosIncidencia : null
    }

    axios.post(API_URL + '/v1/crearIncidenciaRegistro', { header: BODY_HEADER, data: dataPrm }, CONFIG)
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
        reject(error);
      });
  })

  crearIncidencia = (incidencia) => new Promise((resolve, reject) => {

    const dataPrm = {
      idTipoIncidencia: incidencia.hasOwnProperty("idTipoIncidencia") ? incidencia.idTipoIncidencia : 999,
      documento: incidencia.hasOwnProperty("documento") ? incidencia.documento : null,
      datosIncidencia: incidencia.hasOwnProperty("datosIncidencia") ? incidencia.datosIncidencia : null
    }

    axios.post(API_URL + '/v1/crearIncidencia', { header: BODY_HEADER, data: dataPrm }, CONFIG)
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
        reject(error);
      });
  })

  cerrarIncidencia = (body) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/cerrarIncidencia', { header: BODY_HEADER, data: body }, CONFIG)
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
        reject(error);
      });
  })


}

const incidenciasService = new IncidenciasService();

export default incidenciasService;
