import axios from 'src/utils/axios';

export const THEME_REQUEST = 'theme-request';
export const THEME_REQUEST_FAILURE = 'theme-request-failure';
export const THEME_REQUEST_LOADING = 'theme-request-loading';

export function themeRegister(themeConfigRemote) {
    return async (dispatch) => {
      try {
        await dispatch({
          type: THEME_REQUEST,
          payload: {
            themeConfigRemote
          }
        });
      } catch (error) {
        await dispatch({ type: THEME_REQUEST_FAILURE });
        throw error;
      }
    };
  }
