
export const menuView = {
  root: {
    backgroundColor: "#cecece",
  },
  mobileDrawer: {
    width: 260,
    opacity: '0.88',
  },
  desktopDrawer: {
    zIndex: 1300,
    width: '18%',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
}
