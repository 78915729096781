/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import _, { set } from 'lodash';
import { TextField, colors, responsiveFontSizes } from '@material-ui/core';
import { createTheme as createThemeMui } from '@material-ui/core/styles'
import typography from './typography';
import { softShadows, strongShadows } from './shadows';
import { loginFrom } from './loginView';
import { normalView } from './normalView';
import { getConfiguredColors} from './constans';
import { THEMES } from '../constants';
import { menuView } from './menuView';
import { handleGetStorage } from 'src/hooks/validationParticipantes';
import { useSelector } from 'react-redux';
import { dark, light } from '@material-ui/core/styles/createPalette';

const baseConfig = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    },
    MuiTextField: {
      root: {
        '& label': {
          color: '#3c3c3c',
        },
      },
    },
  }
};

/*const themeConfigs = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      }
    },
    palette: {
      type: 'light',
      action: {
        active: colors.blueGrey[600]
      },
      background: {
        default: colors.common.white,
        dark: backgroundColor,
        paper: colors.common.white
      },
      primary: {
        main: primary
      },
      secondary: {
        main: secondary
      },
      tertiary: {
        main: tertiary
      },
      text: {
        primary: textPrimary,
        secondary: textSecondary,
        tertiary: textTertiary,
      },
      backgroundColor: backgroundColor,
      cardBackground: cardBackground
    },
    shadows: softShadows,
    loginView: loginFrom,
    normalView: normalView,
    menuView: menuView,
  },
  {
    name: THEMES.ONE_DARK,
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#282C34',
        dark: '#1c2025',
        paper: '#282C34'
      },
      primary: {
        main: '#1c73fe'
      },
      secondary: {
        main: '#1c73fe'
      },
      text: {
        primary: '#e6e5e8',
        secondary: '#adb0bb'
      }
    },
    shadows: strongShadows
  },
  {
    name: THEMES.UNICORN,
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#2a2d3d',
        dark: '#222431',
        paper: '#2a2d3d'
      },
      primary: {
        main: '#a67dff'
      },
      secondary: {
        main: '#a67dff'
      },
      text: {
        primary: '#f6f5f8',
        secondary: '#9699a4'
      }
    },
    shadows: strongShadows
  }
];*/

const themeBase =  {
  name: THEMES.LIGHT,
  overrides: {
    MuiInputBase: {
      input: {
        '&::placeholder': {
          opacity: 1,
          color: colors.blueGrey[600]
        },
      },
    },
  },
  palette: {
    type: 'light',
    action: {
      active: colors.blueGrey[600]
    },
    background: {
      default: colors.common.white,
      dark: colors.common.black,
      paper: colors.common.white,
      primary:'',
      secondary: '',
      transparent: 'rgba(0,0,0,0)'
    },
    primary: {
      main: ''
    },
    secondary: {
      main: ''
    },
    tertiary: {
      main: ''
    },
    light: {
      main: ''
    },
    dark: {
      main: ''
    },
    text: {
      primary: '',
      secondary: '',
      tertiary: '',
    },
    icon:{
      primary:'',
      secondary:''
    },
    backgroundColor: '',
    cardBackground: '',
    button: {
      primary: '',
      secondary: '',
      light: '#FFFFFF',
      dark: '#000000',
    },
  },
  shadows: softShadows,
  loginView: loginFrom,
  normalView: normalView,
  menuView: menuView,
}

export function ThemeConfigurator(settings) {
  const {themeConfigRemote} = useSelector((state) => state.themeConfigRemote);

  const theme = createTheme({
    settings: settings,
    themeConfigRemote: themeConfigRemote, // Pasa los datos desde el estado
  });

  // ... Resto de tu código para configurar el tema
  return theme
}

export function createTheme({settings = {}, themeConfigRemote}) {
  // let themeConfig = themeConfigs.find((theme) => theme.name === settings.theme)

  if (!themeConfigRemote) {
    console.warn(new Error(`The theme ${settings.theme} is not valid`));
    // [themeConfig] = themeConfig;
    window.location.reload(true);
  }
  if (themeConfigRemote) {
    const {
      code,
      backgroundColor,
      cardBackground,
      primary,
      secondary,
      tertiary,
      textPrimary,
      textSecondary,
      textTertiary,
      button,
    } = themeConfigRemote;
    // Ahora puedes usar los valores en tu configuración de tema
    themeBase.palette.primary.main = primary;
    themeBase.palette.secondary.main = secondary;
    themeBase.palette.tertiary.main = tertiary;
    themeBase.palette.light.main = '#FFFFFF';
    themeBase.palette.dark.main = '#000000';
    themeBase.palette.text.primary = JSON.parse(localStorage.getItem("themeConfig"))?.requireFullTheme ? "#3c3c3c" : textSecondary; //Se convierte esto para uso secundario hasta encontrar como setear ciertos colores con otro
    themeBase.palette.text.secondary = JSON.parse(localStorage.getItem("themeConfig"))?.requireFullTheme ? "#FFFFFF" : textPrimary; //Se convierte esto para uso primario hasta encontrar como setear ciertos colores con otro
    themeBase.palette.text.tertiary = textTertiary;
    themeBase.palette.backgroundColor = backgroundColor;
    themeBase.palette.cardBackground = cardBackground;
    themeBase.menuView.root.backgroundColor = secondary;
    themeBase.palette.background.primary = JSON.parse(localStorage.getItem("themeConfig"))?.requireFullTheme ? primary : backgroundColor;
    themeBase.palette.background.secondary = JSON.parse(localStorage.getItem("themeConfig"))?.requireFullTheme ? secondary : cardBackground;
    themeBase.palette.background.transparent = 'rgba(0,0,0,0)';
    themeBase.palette.button.primary = JSON.parse(localStorage.getItem("themeConfig"))?.requireFullTheme ? primary : "#000000";
    themeBase.palette.button.secondary = JSON.parse(localStorage.getItem("themeConfig"))?.requireFullTheme ? secondary : "#FFFFFF";
    themeBase.palette.button.light = '#FFFFFF';
    themeBase.palette.button.dark = '#000000';
    themeBase.palette.icon.primary = JSON.parse(localStorage.getItem("themeConfig"))?.requireFullTheme ? "#FFFFFF" : primary;
    themeBase.palette.icon.secondary = JSON.parse(localStorage.getItem("themeConfig"))?.requireFullTheme ? "#3c3c3c" : secondary;
}

  let theme = createThemeMui(
    _.merge(
      {},
      baseConfig,
      themeBase,
      { direction: settings.direction }
      )
      );
      if (settings.responsiveFontSizes) {
        theme = responsiveFontSizes(theme);
      }
  return theme;
}
