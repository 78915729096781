import produce from "immer";
import { THEME_REQUEST } from "src/actions/themeActions";

const initialState = {
    themeConfigRemote: null,
  };


  const themeReducer = (state = initialState, action) => {

    switch (action.type) {
      case THEME_REQUEST: {
        const { themeConfigRemote } = action.payload;
          return produce(state, (draft) => {
          // Ensure we clear current session
          draft.themeConfigRemote = themeConfigRemote;
      })
    }
  
      default: {
        return state;
      }
    }
  };
  
  export default themeReducer;