import React from 'react';
import ahorro from 'src/components/Icons/ahorros.svg';
import Ayuda from 'src/components/Icons/ayuda.svg';
import CerrarSesion from 'src/components/Icons/cerrar_sesion.svg';
import deposito from 'src/components/Icons/deposito.svg';
import escanearQr from 'src/components/Icons/escanear_qr.svg';
import extraccion from 'src/components/Icons/extraccion.svg';
import inicio from 'src/components/Icons/inicio.svg';
import miCooperativa from 'src/components/Icons/mi_cooperativa.svg';
import pagoDeServicios from 'src/components/Icons/pago_de_servicios.svg';
import perfilUsuario from 'src/components/Icons/perfil_usuario.svg';
import recargaSaldo2 from 'src/components/Icons/recarga_saldo_2.svg';
import recargaSaldo from 'src/components/Icons/recarga_saldo.svg';
import transferencia from 'src/components/Icons/transferencia.svg';


export const icon_ahorro = (style) =>{
    return (
        // <img src={ahorro} style={style?.size ? {width:`${style?.size}px`, height:`${style?.size}px`} : {width:"25px", height:"25px"}} alt="ahorro" />
        <svg
        className={style?.className}
	 xmlns="http://www.w3.org/2000/svg" 
     viewBox="0 0 100 100"
	 style={{ enableBackground: "new 0 0 100 100" }} 
     xmlSpace="preserve">
<switch>
	<g>
				<path className={style?.className} d="M6.68,69.87c12.34,5.75,24.69,11.51,37.01,17.31c1.15,0.54,2.04,0.51,3.1-0.2
					c14.59-9.82,29.2-19.62,43.8-29.42c1.52-1.02,3.06-2.01,4.54-3.09c1.26-0.91,1.1-2.48-0.27-3.22c-0.3-0.16-0.61-0.29-0.92-0.43
					c-8.2-3.83-16.39-7.66-24.6-11.46c-0.59-0.27-0.77-0.61-0.76-1.23c0.03-2.95,0.02-5.9,0.02-8.85c0-0.35,0.05-2.87,0.03-3.33
					c-6.18,4.52-15.48,4.67-22.28,3.48c-5.06-0.78-9.71-3.48-9.71-3.48v3.43c-0.1,0.74,2.24,1.57,4.04,2.1l0,0
					c4.18,1.19,4.03,4.73,4.03,4.73v5.91c-0.55,0.35-1.13,0.71-1.74,1.09l0.01-5.43c-8.73,6.44-24.2,5.92-32.32,0.39
					c0,1.71,0.04,3.86-0.02,5.55c-0.04,1.07,0.34,1.92,1.21,2.41c1.41,0.79,2.84,1.63,4.36,2.11c5.49,1.74,11.13,1.8,16.88,1.16
					c-0.06,0.13-0.2,0.16-0.31,0.23c-1.68,1.04-3.06,2.4-5.27,2.37c-0.34,0-0.68-0.01-1.02,0.02C25.88,52.08,24.8,52,24.55,52
					c-5.16-0.07-7.03-0.9-12.15-3.01c-0.2-0.1-1.32-0.72-1.66-0.87c0,1.8-0.06,5.27-0.11,6.95c-0.03,1.02,0.34,1.81,1.15,2.34
					c0.88,0.57,1.8,1.07,2.74,1.54c0.62,0.31,1.29,0.5,2.04,0.78c-0.26,0.2-0.41,0.34-0.57,0.44c-3.18,2-6.37,4.01-9.56,6
					c-0.72,0.45-1.34,0.94-1.3,1.9C5.18,69.06,5.87,69.49,6.68,69.87z M45.26,83.55c-0.19,0.12-0.59,0.04-0.84-0.07
					c-1.69-0.77-3.36-1.57-5.04-2.36c1.57-3.24,7.57-3.58,10.34-0.53C48.23,81.59,46.76,82.59,45.26,83.55z M84.31,50.33
					c2.05,0.95,4.01,1.87,6.2,2.89c-1.99,1.34-3.77,2.54-5.67,3.82C83.84,54.86,83.67,52.73,84.31,50.33z M57.76,38.24
					c0.16-0.1,0.46-0.08,0.64,0c1.69,0.77,3.37,1.56,5.06,2.34c-2.14,3.31-7.48,3.7-10.59,0.7C54.55,40.22,56.15,39.21,57.76,38.24z
					 M20.56,61.56c7.77-4.84,15.51-9.71,23.26-14.57c1.92-1.21,3.84-2.41,5.87-3.69c1.75,1.9,3.83,3.11,6.32,3.52
					c4.16,0.68,7.61-0.7,10.35-3.84c0.49-0.56,0.82-0.67,1.5-0.35c4.06,1.94,8.15,3.85,12.25,5.72c0.68,0.31,0.82,0.67,0.63,1.33
					c-0.85,2.96-0.49,5.84,0.64,8.65c0.25,0.61,0.18,0.93-0.39,1.32c-9.13,6.1-18.24,12.23-27.34,18.37
					c-0.55,0.37-0.87,0.34-1.36-0.13c-4.59-4.38-11.74-3.99-15.91,0.89c-0.47,0.56-0.8,0.6-1.43,0.31
					c-4.59-2.18-9.2-4.32-13.82-6.45c-0.68-0.31-0.87-0.65-0.68-1.39c0.71-2.7,0.53-5.39-0.47-7.99
					C19.66,62.41,19.82,62.02,20.56,61.56z M16.41,64.19c0.88,2.13,1.08,4.17,0.42,6.4c-2.02-0.94-3.97-1.85-6.1-2.85
					C12.75,66.48,14.55,65.35,16.41,64.19z"/>
				<path className={style?.className} d="M40.38,54.4l1.36,0.75c-0.47,0.61-0.84,1.23-1.08,1.84c-0.41,1.02-0.53,2.02-0.35,3
					c0.18,0.99,0.65,1.93,1.41,2.84c0.77,0.91,1.78,1.74,3.05,2.5c1.29,0.77,2.6,1.32,3.94,1.66c1.34,0.33,2.65,0.45,3.94,0.35
					c1.28-0.1,2.51-0.42,3.68-0.96c0.95-0.44,1.82-1.01,2.63-1.7l1.92,1.06c0.18,0.1,0.37,0.15,0.56,0.15c0.41,0,0.8-0.22,1.02-0.6
					c0.31-0.56,0.11-1.27-0.45-1.58l-1.39-0.77c0.31-0.4,0.57-0.8,0.79-1.2c0.45-0.83,0.78-1.76,0.99-2.82
					c0.07-0.36,0.09-0.69,0.06-0.97c-0.03-0.28-0.14-0.53-0.33-0.74c-0.19-0.21-0.49-0.44-0.9-0.69l-3.12-1.87
					c-0.56-0.34-1.06-0.52-1.49-0.54c-0.43-0.02-0.81,0.06-1.13,0.25c-0.32,0.19-0.66,0.45-1.01,0.8l-2.84,2.79c0,0,0,0,0,0
					l-5.52-3.05c0.25-0.16,0.51-0.29,0.77-0.39c0.59-0.22,1.15-0.34,1.69-0.36c0.54-0.01,1.28,0.02,2.23,0.09
					c0.31,0.01,0.61-0.03,0.88-0.13c0.28-0.1,0.51-0.23,0.69-0.41c0.33-0.32,0.46-0.68,0.4-1.09c-0.06-0.4-0.3-0.73-0.71-0.98
					c-0.39-0.23-0.93-0.39-1.63-0.49c-0.7-0.1-1.47-0.08-2.31,0.04c-0.84,0.13-1.73,0.42-2.67,0.87c-0.71,0.34-1.39,0.79-2.03,1.35
					l-1.9-1.05c-0.56-0.31-1.27-0.11-1.58,0.45C39.62,53.38,39.82,54.09,40.38,54.4z M54.4,59.15l1.94-1.91l2.65,1.58
					c-0.15,0.74-0.38,1.41-0.69,2.03c-0.1,0.21-0.22,0.41-0.36,0.61l-3.78-2.09C54.24,59.3,54.32,59.23,54.4,59.15z M43.99,57.44
					c0.09-0.28,0.22-0.56,0.39-0.83l11.91,6.6c-1.05,0.79-2.27,1.21-3.67,1.24c-1.7,0.04-3.5-0.51-5.41-1.66
					c-0.88-0.53-1.6-1.09-2.15-1.69c-0.55-0.6-0.92-1.21-1.1-1.83C43.79,58.66,43.8,58.05,43.99,57.44z"/>
				<path className={style?.className} d="M95.96,60.14l0.54-6.45c-0.21,1.51-3.35,2.89-3.35,2.89l0.03,1.88c0,0.4,0.08,0.8-0.22,1
					c-15.73,10.43-31.46,20.86-47.18,31.3c-0.47,0.31-0.84,0.4-1.38,0.15c-10.42-4.74-20.85-9.45-31.28-14.17
					c-1.76-0.8-3.51-1.6-5.29-2.37c-0.06-0.02-0.11-0.05-0.17-0.07v-2.56l-2.54-1.16l0.01,5.2c-0.02,0.13-0.01,0.26,0,0.39v0.01l0,0
					c0.08,0.6,0.5,1.14,1.21,1.5c0.21,0.11,0.43,0.19,0.65,0.29C19.3,83.54,31.62,89.1,43.92,94.69c1.04,0.47,1.88,0.41,2.84-0.23
					c12.61-8.39,25.24-16.74,37.87-25.11c3.79-2.51,6.34-4.61,10.1-7.16c0.39-0.26,0.7-0.64,0.95-0.95
					C95.94,60.93,96.05,60.53,95.96,60.14C95.96,60.14,95.96,60.14,95.96,60.14z"/>
				<path className={style?.className} d="M11.72,36.3c0.76,0.5,1.62,0.94,2.51,1.27c4.03,1.45,8.3,1.8,12.18,1.88c4.22-0.04,7.97-0.34,11.53-1.44
					c1.5-0.46,2.93-1.17,4.22-1.96c1.09-0.67,1.04-1.49,0-2.23c-0.81-0.58-1.74-1.14-2.73-1.45c-8.11-2.57-16.3-2.52-24.48-0.29
					c-1.14,0.31-2.22,0.87-3.19,1.47C10.29,34.43,10.29,35.35,11.72,36.3z M21.28,32.99c0.15-0.18,0.5-0.27,0.78-0.2l0.93,0.22
					c0.32-0.19,0.65-0.35,1-0.48c0.46-0.17,0.9-0.3,1.31-0.37c0.41-0.07,0.79-0.11,1.14-0.11c0.34,0,0.61,0.02,0.8,0.07
					c0.2,0.05,0.32,0.14,0.35,0.25c0.03,0.12-0.04,0.23-0.2,0.33c-0.09,0.06-0.2,0.11-0.34,0.15c-0.14,0.04-0.28,0.07-0.43,0.08
					c-0.47,0.02-0.83,0.04-1.1,0.07c-0.27,0.03-0.54,0.09-0.83,0.18c-0.13,0.04-0.25,0.09-0.38,0.15l2.71,0.65c0,0,0,0,0,0l1.4-0.94
					c0.17-0.11,0.34-0.21,0.5-0.28c0.16-0.07,0.34-0.11,0.56-0.12c0.21-0.01,0.46,0.02,0.73,0.09l1.53,0.41
					c0.2,0.05,0.35,0.11,0.44,0.16c0.09,0.05,0.15,0.12,0.16,0.2c0.02,0.08,0.01,0.18-0.03,0.29c-0.11,0.32-0.27,0.6-0.49,0.86
					c-0.11,0.13-0.24,0.26-0.39,0.38l0.68,0.16c0.28,0.07,0.38,0.26,0.22,0.44c-0.1,0.12-0.3,0.2-0.5,0.22
					c-0.09,0.01-0.19,0-0.28-0.02l-0.94-0.23c-0.4,0.24-0.83,0.44-1.29,0.61c-0.57,0.21-1.18,0.35-1.81,0.44
					c-0.63,0.09-1.28,0.11-1.93,0.07c-0.66-0.04-1.3-0.14-1.94-0.31c-0.62-0.17-1.12-0.36-1.5-0.6c-0.38-0.23-0.61-0.49-0.69-0.77
					c-0.09-0.28-0.03-0.58,0.17-0.89c0.12-0.19,0.3-0.39,0.53-0.58l-0.67-0.16C21.22,33.36,21.12,33.17,21.28,32.99z"/>
				<path className={style?.className} d="M30.3,34.53c0.15-0.19,0.27-0.4,0.34-0.62l-1.3-0.35l-0.95,0.64c-0.04,0.03-0.08,0.05-0.12,0.08l1.86,0.45
					C30.19,34.66,30.25,34.6,30.3,34.53z"/>
				<path className={style?.className} d="M23.8,35.18c0.27,0.15,0.63,0.28,1.06,0.4c0.94,0.25,1.83,0.33,2.66,0.25c0.68-0.07,1.28-0.24,1.8-0.52
					l-5.85-1.41c-0.08,0.09-0.15,0.17-0.19,0.26c-0.09,0.18-0.1,0.36-0.01,0.53C23.35,34.87,23.53,35.03,23.8,35.18z"/>
				<path className={style?.className} d="M37.51,24.4c1.18,0.71,2.39,1.43,3.69,1.87c6.11,2.08,12.37,2.16,18.66,1.06c2.51-0.44,4.98-1.1,7.11-2.58
					c0.64-0.44,1.45-1.14,1.52-1.79c0.2-1.99,0.07-4.01,0.07-5.94c-10.73,4.4-21.36,4.41-32.19-0.02c0,1.67,0.04,3.36-0.01,5.05
					C36.32,23.08,36.68,23.91,37.51,24.4z"/>
				<path className={style?.className} d="M56.12,13.11c0.18-0.2,0.32-0.41,0.42-0.64l-0.35-0.12l-1.01-0.34l-0.97,0.55l-0.11,0.06
					c-0.05,0.03-0.09,0.05-0.14,0.07l1.94,0.61C55.99,13.24,56.06,13.18,56.12,13.11z"/>
				<path className={style?.className} d="M49.12,13.34c0.28,0.18,0.64,0.35,1.09,0.5c0.98,0.33,1.92,0.48,2.82,0.45c0.74-0.03,1.39-0.17,1.97-0.43
					l-0.46-0.14l-4.26-1.33l-1.4-0.44c-0.1,0.09-0.18,0.17-0.23,0.26c-0.12,0.19-0.14,0.38-0.06,0.57
					C48.67,12.98,48.84,13.16,49.12,13.34z"/>
				<path className={style?.className} d="M37.29,14.48c0.83,0.61,1.79,1.13,2.77,1.5c4.17,1.57,8.56,1.99,12.31,1.96c4.5-0.06,8.23-0.46,11.78-1.69
					c1.25-0.43,2.46-1.04,3.53-1.77c1.27-0.87,1.27-1.85,0-2.74c-1-0.7-2.14-1.33-3.34-1.7c-7.57-2.35-15.22-2.36-22.85-0.27
					c-1.46,0.4-2.87,1.11-4.13,1.91C36,12.52,36.02,13.54,37.29,14.48z M46.63,10.83c0.18-0.18,0.56-0.25,0.85-0.16l0.98,0.3
					c0.36-0.18,0.73-0.33,1.11-0.44c0.51-0.15,0.98-0.25,1.43-0.3c0.45-0.05,0.86-0.06,1.22-0.04c0.37,0.02,0.65,0.07,0.85,0.13
					c0.21,0.07,0.33,0.17,0.35,0.29c0.02,0.12-0.06,0.24-0.24,0.34c-0.1,0.06-0.22,0.1-0.37,0.13c-0.15,0.03-0.31,0.05-0.47,0.05
					c-0.02,0-0.05,0-0.07,0c-0.46-0.01-0.83-0.01-1.11,0c-0.29,0.01-0.59,0.05-0.9,0.13c-0.14,0.03-0.28,0.08-0.42,0.13l0.26,0.08
					l1.18,0.37l1.39,0.43c0,0,0,0,0,0l1.58-0.9c0.19-0.11,0.38-0.2,0.55-0.26c0.18-0.06,0.38-0.09,0.6-0.09
					c0.23,0,0.48,0.05,0.77,0.15l1.6,0.54c0.08,0.03,0.15,0.06,0.22,0.08c0.1,0.04,0.18,0.08,0.24,0.12
					c0.09,0.06,0.14,0.14,0.15,0.22c0.01,0.09-0.01,0.19-0.06,0.3c-0.14,0.33-0.34,0.62-0.6,0.88c-0.02,0.02-0.04,0.03-0.05,0.05
					c-0.12,0.11-0.25,0.22-0.4,0.33l0.64,0.2L58,13.96c0.29,0.09,0.38,0.31,0.2,0.48c-0.12,0.12-0.34,0.19-0.55,0.2
					c-0.1,0-0.2-0.01-0.29-0.04l-0.98-0.31c-0.44,0.23-0.92,0.41-1.44,0.56c-0.63,0.18-1.29,0.29-1.97,0.34
					c-0.68,0.05-1.37,0.03-2.07-0.06c-0.7-0.09-1.38-0.24-2.04-0.47c-0.65-0.22-1.16-0.47-1.54-0.74c-0.38-0.27-0.6-0.56-0.67-0.86
					c-0.07-0.3,0.02-0.61,0.27-0.93c0.11-0.14,0.25-0.29,0.43-0.43c0.06-0.05,0.13-0.1,0.19-0.15l-0.66-0.2l-0.04-0.01
					C46.54,11.23,46.45,11.01,46.63,10.83z"/>
	</g>
</switch>
</svg>
    )
}

export const icon_Ayuda = (style) =>{
    return (
        //<img src={Ayuda} style={style?.size ? {width:`${style?.size}px`, height:`${style?.size}px`} : {width:"25px", height:"25px"}} alt="Ayuda" />
		<svg
		className={style?.className}
	 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"
	 style={{enableBackground:"new 0 0 100 100"}} xmlSpace="preserve">
<switch>
	<g>
		<path className={style?.className} d="M48.27,3.99c21.88,0.46,29.27,18.13,30.75,22.46c0.17,0.48-0.17,0.99-0.68,1.03l-1.85,0.16
			c-0.56,0.05-1.08-0.26-1.31-0.78c-1.5-3.5-8.3-16.49-26.34-16.87C30.7,9.61,22.43,24.71,20.59,28.63
			c-0.26,0.56-0.84,0.91-1.46,0.9l-0.09,0c-0.76-0.02-1.31-0.73-1.13-1.47c0.86-3.52,3.15-10.23,9.35-15.89
			C35.78,4.38,45.75,3.94,48.27,3.99z"/>
		<path className={style?.className} d="M85.21,31.09c-1.31-1.54-3.3-2.34-5.32-2.34h-2.35h-0.8c0,0,0.31,2.62,0.68,6.04
			c-2.5-9.59-8.31-14.82-8.31-14.82L69.11,20c-5.31-5.46-13.17-9-23.5-7.52c0,0-9.79,0.28-17.72,7.48c0,0-0.03,0.02-0.06,0.06
			c-4.36,3.98-8.15,10.07-9.43,19.37c-0.28,2.37-0.21,4.69,0.16,6.93v0c1.09,14.86,3.5,17.16,1.79,21.96
			c-0.37,1.05,0.45,2.13,1.56,2.07c3.36-0.17,9.26-0.96,12.01-4.29c0.31-0.37,0.85-0.43,1.26-0.17c3.8,2.41,8.29,3.81,13.11,3.81
			c3.98,0,7.74-0.97,11.07-2.66c0.65-0.33,1.33-0.61,2.05-0.75c0.24-0.05,0.5-0.1,0.78-0.17c0.43-0.11,0.9,0,1.2,0.32
			c3.02,3.15,9,3.81,12.14,3.93c0.92,0.04,1.56-0.89,1.23-1.75c-1.25-3.25-0.67-5.26,0.21-10.32c3.71-1.91,6.1-3.71,5.62-4.67
			c-1.27-2.54-3.06-1.27-3.06-1.27c-0.58,0.68-1.23,1.3-1.93,1.89c0.3-2.17,0.59-4.77,0.83-7.95c0.01-0.17,0.02-0.33,0.03-0.5
			c0.08,1.09,0.14,2.07,0.16,2.84c0.03,1.1,0.96,1.98,2.06,1.92c2.38-0.13,5.91-1.04,6.04-5.26v-9.96c0-1.39-0.39-2.78-1.22-3.89
			C85.41,31.34,85.31,31.22,85.21,31.09z M66.36,54.03c-0.79,0.6-2.09,1.43-3.86,1.97c-0.99,0.3-3.68,1.12-6.43,0.15
			c-2.62-0.92-2.17-2.36-4.57-3.15c-3.77-1.24-7.38,1.47-7.94,1.89c-0.75,0.56-1.15,1.06-1.32,1.68c-0.43,1.56,0.86,3.04,0.86,3.04
			s1.15,1.73,6.22,1.61c5.07-0.11,5.53-1.96,5.53-1.96c2.3-0.1,5.02-0.61,7.86-1.37c-3.96,3.04-8.89,4.86-14.25,4.86
			c-3.52,0-6.85-0.8-9.84-2.19c0.22,0.04,0.43,0.1,0.65,0.14c-0.15-0.03-0.5-0.12-0.97-0.29c-1.68-0.81-3.26-1.8-4.69-2.97
			c-1.86-1.78-3.73-4.48-4.83-8.6C24.3,34.05,39.37,37.32,45.78,19.78c0,0,7.17,11.39,23.39,17.54
			C69.77,41.27,69.53,48.38,66.36,54.03z"/>
		<path className={style?.className} d="M82.9,85.51C77.17,75.76,65.85,68.88,52.57,68v0.25h-6.56v-0.19c-0.59,0.05-1.16,0.12-1.74,0.19h0
			c-10.89,1.38-20.26,6.82-25.94,14.49c-3.69,4.98-5.83,10.9-5.83,17.25h22.91H63.2h23.69C86.89,94.79,85.45,89.87,82.9,85.51z
			 M45.64,95.99c-0.07,0-0.13,0.01-0.2,0.01c-0.7,0-1.38-0.27-1.9-0.74c-0.39-0.36-0.66-0.82-0.79-1.32c-0.06-0.23-0.1-0.46-0.1-0.7
			v-7.8c0-2.08,1.68-3.76,3.76-3.76l0.03,0c-0.29-0.02-0.54,0.21-0.54,0.5v9.97c0,0.02,0,0.03,0,0.04l0,0
			c0,1.71,1.39,3.09,3.09,3.09c0.29,0,0.57-0.04,0.83-0.11C48.54,95.6,47.13,95.89,45.64,95.99z M60.36,85.73
			c-0.18,0.17-0.46,0.15-0.63-0.03c-0.57-0.62-1.06-1.16-2.2-1.29c-0.39-0.05-0.71-0.01-1,0.05c0.2,0.47,0.33,0.96,0.42,1.47
			c0.13,0,0.26,0.01,0.4,0.03c1.47,0.18,2.15,0.92,2.76,1.58c0.17,0.18,0.15,0.46-0.03,0.63s-0.46,0.15-0.63-0.03
			c-0.57-0.62-1.06-1.16-2.2-1.29c-0.08-0.01-0.13,0-0.2,0c0,0.04,0.01,0.08,0.01,0.13c0,0.48-0.06,0.94-0.16,1.4
			c0.06,0,0.11,0,0.17,0.01c1.47,0.18,2.15,0.92,2.76,1.58c0.17,0.18,0.15,0.46-0.03,0.63c-0.18,0.17-0.46,0.15-0.63-0.03
			c-0.57-0.62-1.06-1.16-2.2-1.29c-0.13-0.02-0.22,0-0.34,0c-0.96,2.52-3.44,4.6-6.78,5.72c-0.26,0.07-0.54,0.11-0.83,0.11
			c-1.71,0-3.09-1.38-3.09-3.09l0,0c0.03,0.29,0.28,0.5,0.57,0.46c1.81-0.26,3.42-0.84,4.66-1.62c-1.27-0.24-1.9-0.92-2.46-1.52
			c-0.17-0.18-0.15-0.46,0.03-0.63c0.18-0.17,0.46-0.15,0.63,0.03c0.57,0.62,1.06,1.16,2.21,1.29c0.26,0.03,0.49,0.03,0.7,0.01
			c0.58-0.53,1.02-1.11,1.27-1.73c-0.48,0.18-1.06,0.27-1.79,0.18c-1.47-0.18-2.15-0.92-2.76-1.58c-0.17-0.18-0.15-0.46,0.03-0.63
			c0.18-0.17,0.46-0.15,0.63,0.03c0.57,0.62,1.06,1.16,2.2,1.29c0.86,0.1,1.42-0.1,1.94-0.41c0-0.06,0.01-0.13,0.01-0.19
			c0-0.36-0.07-0.71-0.18-1.06c-0.44,0.13-0.95,0.2-1.59,0.12c-1.47-0.18-2.15-0.92-2.76-1.58c-0.17-0.18-0.15-0.46,0.03-0.63
			c0.18-0.17,0.46-0.15,0.63,0.03c0.57,0.62,1.06,1.16,2.21,1.29c0.43,0.05,0.78,0.01,1.09-0.07c-1.03-1.66-3.36-3-6.26-3.52
			c-0.01,0-0.02,0-0.03-0.01l-0.49-0.07l-0.03,0c-2.08,0-3.76,1.68-3.76,3.76v-4.34c0-0.77,0.32-1.51,0.88-2.03
			c0.56-0.52,1.33-0.79,2.09-0.74c4.82,0.35,8.76,2.53,10.47,5.5c0.43-0.12,0.92-0.17,1.52-0.1c1.47,0.18,2.15,0.92,2.76,1.58
			C60.55,85.28,60.54,85.56,60.36,85.73z"/>
	</g>
</switch>
</svg>
    )
}

export const icon_CerrarSesion = (style) =>{
    return (
        //<img src={CerrarSesion} style={style?.size ? {width:`${style?.size}px`, height:`${style?.size}px`} : {width:"25px", height:"25px"}} alt="CerrarSesion" />
		<svg
		className={style?.className}
	 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"
	 style={{enableBackground:"new 0 0 100 100"}} xmlSpace="preserve">
	<g>
			<g>
				<path className={style?.className} d="M71.14,90.32H23.17c-8.42,0-15.27-6.85-15.27-15.27V27.08c0-8.42,6.85-15.27,15.27-15.27h47.97
					c8.42,0,15.27,6.85,15.27,15.27c0,1.85-1.5,3.36-3.36,3.36c-1.85,0-3.36-1.5-3.36-3.36c0-4.72-3.84-8.56-8.56-8.56H23.17
					c-4.72,0-8.56,3.84-8.56,8.56v47.97c0,4.72,3.84,8.56,8.56,8.56h47.97c4.72,0,8.56-3.84,8.56-8.56c0-1.85,1.5-3.36,3.36-3.36
					c1.85,0,3.36,1.5,3.36,3.36C86.42,83.47,79.57,90.32,71.14,90.32z"/>
			</g>
			<path className={style?.className} d="M47.4,50.31c3.21-2.02,5.35-5.59,5.35-9.67c0-6.31-5.12-11.43-11.43-11.43c-6.31,0-11.43,5.12-11.43,11.43
				c0,4.08,2.14,7.64,5.35,9.67c-5.77,2.39-9.84,8.07-9.84,14.71v2.72c0,1.62,1.31,2.93,2.93,2.93h25.97c1.62,0,2.93-1.31,2.93-2.93
				v-2.72C57.24,58.39,53.18,52.7,47.4,50.31z"/>
			<path className={style?.className} d="M92.82,48.25H70.4l7-6.25c1.65-1.47,1.79-4.01,0.32-5.66c-1.47-1.65-4.01-1.79-5.66-0.32L57.23,49.27
				c-0.85,0.76-1.34,1.85-1.34,2.99s0.49,2.23,1.34,2.99L72.06,68.5c0.76,0.68,1.72,1.02,2.67,1.02c1.1,0,2.2-0.45,2.99-1.34
				c1.47-1.65,1.33-4.18-0.32-5.66l-7-6.25h22.43c2.21,0,4.01-1.79,4.01-4.01S95.04,48.25,92.82,48.25z"/>
	</g>
</svg>
    )
}

export const icon_deposito = (style) =>{
    return (
        <img src={deposito} style={style?.size ? {width:`${style?.size}px`, height:`${style?.size}px`} : {width:"25px", height:"25px"}} alt="deposito" />
    )
}

export const icon_escanearQr = (style) =>{
    return (
        //<img src={escanearQr} style={style?.size ? {width:`${style?.size}px`, height:`${style?.size}px`} : {width:"25px", height:"25px"}} alt="escanearQr" />
		<svg
		className={style?.className}
	 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"
	 style={{enableBackground:"new 0 0 100 100"}} xmlSpace="preserve">
	<g>
		<g>
				<path className={style?.className} d="M77.8,33.51c2.23-0.62,4.37-1.46,6.09-3.08c1.33-1.25,1.98-2.72,1.39-4.56c-0.44-1.37-1.41-2.07-2.82-2.2
					c-1.5-0.13-2.92,0.23-4.3,0.76c-0.28,0.11-0.56,0.21-0.98,0.38c0-0.38,0-0.67,0-0.96c0-3.51-0.05-7.03-0.02-10.55h0.01v-0.37
					c0.01-1,0.02-1.99,0.05-2.99c0.09-3.4-2.24-4.97-4.89-4.94C62.8,5.1,53.28,5.04,43.76,5.04c-2.85,0-4.51,1.65-4.51,4.48
					c0,0.79,0,1.57,0,2.36h0v1.42h0c0,5.32-0.01,10.63,0.01,15.95c0,1.1-0.52,1.85-1.4,2.36c-0.93,0.54-1.92,1-2.9,1.45
					c-1.49,0.69-2.54,1.78-3.4,3.17c-2.08,3.37-4.23,6.69-6.39,10.01c-0.36,0.55-0.9,0.98-1.32,1.49c-1.7,2.04-2.02,4.45-1.84,6.97
					c0.29,3.87,0.53,7.74,0.33,11.64c-0.11,2.11-0.75,3.87-2.11,5.44c-0.69,0.8-1.31,1.65-2,2.45c-0.51,0.58-0.49,1.02,0.1,1.54
					c5.68,5,11.35,10.02,17.02,15.04c1.07,0.95,1.24,1.01,2.15-0.04c2.12-2.44,4.72-3.86,7.82-4.84c6.35-2,12.21-5.08,17.57-9.06
					c0.31-0.23,0.61-0.48,1.12-0.88c-0.55,0-0.84,0-1.14,0c-6.63,0-13.26,0.01-19.9-0.01c-2.48-0.01-4.41-1.59-4.93-4
					c-0.09-0.4-0.09-0.83-0.09-1.24c-0.01-7.55,0-15.1,0-22.64c0-0.33,0-0.66,0-1.09c-0.4,0.2-0.72,0.35-1.05,0.51
					c-0.04-0.04-0.08-0.07-0.11-0.11c0.75-1.22,1.5-2.43,2.25-3.65c0.06,0.02,0.13,0.03,0.19,0.05c0,0.35,0,0.7,0,1.05
					c0,8.46,0,16.93,0,25.39c0,2.8,1.64,4.47,4.42,4.47c9.71,0,19.43,0,29.14-0.01c2.7,0,4.34-1.67,4.37-4.36
					c0-0.08,0.01-0.17,0-0.25c-0.12-1.16,0.01-1.94,1.34-2.6c1.87-0.94,2.86-2.82,3.17-4.93c0.28-1.93-1.35-3.78-3.34-3.92
					c-0.38-0.03-0.76-0.08-1.16-0.32c0.12-0.08,0.23-0.21,0.37-0.25c1.23-0.32,2.12-1.11,2.74-2.16c0.62-1.07,1.15-2.2,1.64-3.35
					c0.61-1.42,0.23-2.78-0.49-4.05c-0.62-1.11-1.56-1.78-2.85-1.9c-0.44-0.04-0.88-0.11-1.55-0.2c0.29-0.24,0.43-0.46,0.61-0.51
					c1.02-0.26,1.86-0.84,2.37-1.71c0.77-1.33,1.58-2.7,2.04-4.15c0.89-2.8-1.22-5.53-4.16-5.62c-0.19-0.01-0.38,0-0.58,0
					C77.01,33.85,77.26,33.66,77.8,33.51z M40.3,10.65c0-2.15,1.25-3.4,3.41-3.4c9.66,0,19.31,0,28.97,0c2.19,0,3.44,1.26,3.44,3.46
					c0,0.39,0,0.78,0,1.17H40.3C40.3,11.47,40.3,11.06,40.3,10.65z M76.12,13.3c0,7.65,0.01,15.31-0.02,22.96
					c0,0.48-0.26,0.98-0.49,1.42c-0.64,1.25-1.48,2.42-1.94,3.72c-0.61,1.71-0.07,3.23,1.38,3.94c1,0.49,1.08,1.14,1.1,2.08
					c0.03,1.07-0.3,1.87-0.91,2.73c-0.72,1.02-1.34,2.18-1.72,3.37c-0.67,2.05,0.16,3.57,2.12,4.45c0.21,0.09,0.45,0.4,0.46,0.62
					c0.08,1.13-0.02,2.11-0.9,3.1c-0.32,0.36-0.59,0.74-0.79,1.13H40.3c0-6.74,0-13.47-0.01-20.21c0-0.53,0.16-0.81,0.61-1.12
					c3.47-2.38,5.89-5.52,6.64-9.75c0.19-1.07,0.13-2.18,0.18-3.29c0.43,0,0.86,0,1.33,0c0.03,0.5,0.05,0.96,0.07,1.38
					c0.71,0,1.33,0,2.01,0c0-0.51,0-0.94,0-1.43c0.36,0.02,0.64,0.03,0.92,0.05c0,0.89,0,1.68,0,2.57c-1.06,0-2.07,0-3.09,0
					c-0.04,0.33-0.07,0.57-0.1,0.85c1.43,0,2.82,0,4.2,0c0-2.02,0-3.98,0-5.95c-1.72,0-3.38,0-5.05,0c0.11,0.72,0.46,1.06,1.23,0.99
					c0.72-0.07,1.44-0.03,2.16,0c0.23,0.01,0.46,0.16,0.69,0.24c-0.22,0.1-0.43,0.29-0.65,0.29c-1.19,0.03-2.39,0.05-3.58-0.01
					c-0.28-0.01-0.59-0.27-0.82-0.49c-0.63-0.61-1.14-1.36-1.84-1.87c-0.92-0.68-1.57-0.56-2.33,0.3c-0.84,0.95-1.62,1.96-2.58,3.12
					c0-0.47,0-0.73,0-1c0-4.72,0-9.44,0-14.16H76.12z M72.87,72.36c-9.79,0-19.59,0.01-29.38-0.01c-1.94,0-3.19-1.32-3.19-3.27
					c0-1.61,0-3.23,0-4.84h33.65c-0.16,1.27,0.39,2.54,1.88,3.5c0.12,0.08,0.25,0.24,0.27,0.38C76.45,70.66,75.09,72.36,72.87,72.36
					z"/>
				<path className={style?.className} d="M16.6,75.37c-0.33-0.3-0.78-0.46-1.27-0.74c-0.45,0.27-0.9,0.44-1.2,0.76c-1.03,1.09-2.01,2.21-2.99,3.34
					c-0.66,0.77-0.56,1.7,0.24,2.42c3.44,3.05,6.89,6.1,10.33,9.15c2.84,2.52,5.69,5.03,8.52,7.55c1.27,1.13,2.01,1.09,3.13-0.21
					c0.8-0.92,1.62-1.82,2.43-2.74c0.83-0.94,0.79-1.83-0.15-2.66C29.3,86.61,22.96,80.98,16.6,75.37z M32.15,95.61
					c-0.82,0.01-1.58-0.76-1.57-1.6c0.01-0.83,0.7-1.51,1.54-1.52c0.84-0.01,1.54,0.65,1.57,1.49
					C33.72,94.81,32.98,95.6,32.15,95.61z"/>
				<path className={style?.className} d="M54.12,37.04c0,0.32,0,0.64,0,0.96c0.65,0,1.3,0,2.03,0c0.02,0.38,0.04,0.67,0.06,1.03
					c-0.34,0.05-0.63,0.09-0.97,0.13c-0.03,0.28-0.06,0.57-0.09,0.9c-0.32,0.04-0.61,0.08-0.95,0.13c0,0.27,0,0.53,0,0.82
					c0.31,0.03,0.58,0.06,0.95,0.1c0,0.96,0,1.91,0,2.9c0.38,0.08,0.66,0.13,0.98,0.2c0.04,0.27,0.08,0.54,0.13,0.89
					c0.25,0.01,0.5,0.03,0.83,0.05c0-0.67,0-1.29,0-1.94c-0.3-0.03-0.57-0.05-0.91-0.08c-0.01-0.31-0.03-0.59-0.05-0.98
					c0.67-0.03,1.28-0.06,1.96-0.09c0-0.29,0-0.55,0-0.91c-0.64,0-1.26,0-1.91,0c0-0.4,0-0.69,0-1.02c0.3-0.03,0.57-0.06,0.89-0.1
					c0.04-0.27,0.09-0.56,0.15-0.91c1.31,0,2.62,0,3.99,0c0,0.64,0,1.26,0,1.99c-0.27,0.02-0.55,0.03-0.9,0.05
					c-0.06-0.33-0.11-0.62-0.17-0.96c-0.6,0-1.19,0-1.84,0c0,0.26,0,0.52,0,0.82c0.29,0.03,0.55,0.05,0.87,0.09
					c0.04,0.3,0.09,0.59,0.14,0.93c0.28,0.04,0.57,0.08,0.89,0.12c0.03,0.29,0.06,0.56,0.09,0.89c0.29,0,0.57,0,0.88,0
					c0.04-0.3,0.07-0.57,0.1-0.88c0.31-0.04,0.61-0.08,0.94-0.13c0.03-0.3,0.06-0.59,0.1-0.95c0.63,0,1.23,0,1.9,0
					c0-0.68,0-1.3,0-1.9c-0.35-0.08-0.69-0.16-1.02-0.24c0.02-0.31,0.04-0.61,0.06-0.92c-0.65,0-1.29,0-2.01,0
					c0.02-0.37,0.03-0.69,0.05-1c0.29,0,0.59,0,1,0c0-0.7,0-1.35,0-2c0.32-0.01,0.65-0.02,0.97-0.02c0-0.32,0-0.65,0.01-0.97
					c-0.33,0-0.65,0-0.98,0c-0.01,0.32-0.02,0.65-0.02,0.97c-0.33,0-0.65,0-0.98,0c0-0.33,0-0.65,0-0.98
					c0.32-0.01,0.65-0.01,0.97-0.02c0-0.65,0-1.3,0-2.03c-0.7,0-1.33,0-1.97,0c-0.05-0.35-0.08-0.62-0.12-0.93
					c-0.3-0.04-0.59-0.08-0.93-0.13c0-0.31,0-0.61,0-0.97c0.65,0,1.29,0,1.98,0c0.03,0.28,0.06,0.55,0.09,0.88c0.28,0,0.57,0,0.91,0
					c0-0.59,0-1.19,0-1.82c-0.3-0.04-0.6-0.08-0.94-0.12c0-0.65,0-1.28,0-1.96c0.3-0.04,0.59-0.07,0.93-0.11c0-0.29,0-0.57,0-0.9
					c-0.63,0-1.23,0-1.93,0c0,0.97,0,1.95,0,2.98c-0.39,0-0.68,0-1.02,0c-0.05-0.3-0.1-0.59-0.15-0.93
					c-0.24-0.01-0.48-0.03-0.84-0.05c0,1.02,0,2,0,3c-0.38,0.05-0.65,0.08-0.97,0.11c0,0.27,0,0.53,0,0.83
					c0.3,0.04,0.56,0.07,0.89,0.12c0,1.34,0,2.65,0,3.99c-0.3,0.03-0.57,0.06-0.88,0.1c0,0.29,0,0.58,0,0.91
					c0.28,0.03,0.55,0.05,0.88,0.08c0,0.29,0,0.58,0,0.94c-0.3,0-0.61,0-0.95,0c-0.04-0.3-0.08-0.6-0.13-0.96
					C56.09,37.04,55.1,37.04,54.12,37.04z M62.32,39.1c0.33-0.06,0.59-0.1,0.86-0.15c0.05,0.36,0.09,0.72,0.14,1.12
					c-0.42,0-0.71,0-1,0C62.32,39.71,62.32,39.4,62.32,39.1z M59.24,38.04c0-0.35,0-0.65,0-1.02c0.4,0.03,0.74,0.06,1.08,0.09
					c-0.05,0.31-0.09,0.61-0.14,0.94C59.86,38.04,59.57,38.04,59.24,38.04z M60.35,37.08c-0.02-0.68-0.04-1.36-0.06-2.04
					c0.32,0,0.65,0,0.97,0c0,0.66,0,1.31,0,1.97C60.96,37.03,60.65,37.06,60.35,37.08z M59.22,33.01c0.69,0,1.33,0,2.04,0
					c0,0.37,0,0.69,0,1.01c-0.31,0-0.62,0-1,0c0,0.38,0,0.69,0,1c-0.34,0-0.68,0-1.04,0C59.22,34.3,59.22,33.68,59.22,33.01z"/>
				<path className={style?.className} d="M63.25,38.04c0.01,0,0.02,0,0.03,0c0-0.01,0-0.02,0-0.03c-0.01,0-0.02,0-0.03,0
					C63.25,38.02,63.25,38.03,63.25,38.04z"/>
				<path className={style?.className} d="M54.12,37.04c0-0.01,0-0.02,0-0.03c-0.01,0-0.02,0-0.03,0c0,0.01,0,0.02,0,0.03
					C54.1,37.04,54.11,37.04,54.12,37.04z"/>
				<path className={style?.className} d="M63.37,48.12c1.94,0,3.88,0,5.89,0c0-1.94,0-3.89,0-5.93c-1.98,0-3.9,0-5.89,0
					C63.37,44.16,63.37,46.1,63.37,48.12z M64.33,43.14c1.36,0,2.67,0,4.02,0c0,1.37,0,2.68,0,4.04c-1.35,0-2.64,0-4.02,0
					C64.33,45.8,64.33,44.49,64.33,43.14z"/>
				<path className={style?.className} d="M69.27,25.92c-2.02,0-3.94,0-5.91,0c0,1.99,0,3.94,0,5.95c1.97,0,3.92,0,5.91,0
					C69.27,29.88,69.27,27.93,69.27,25.92z M68.36,30.94c-1.41,0-2.72,0-4.02,0c0-1.38,0-2.7,0-4c1.37,0,2.69,0,4.02,0
					C68.36,28.29,68.36,29.59,68.36,30.94z"/>
				<path className={style?.className} d="M70.37,30.63c0.35,0,0.61,0,0.94,0c0-2.21,0-4.44,0-6.72c-2.2,0-4.41,0-6.7,0c0,0.31,0,0.58,0,0.91
					c1.91,0,3.79,0,5.76,0C70.37,26.78,70.37,28.68,70.37,30.63z"/>
				<path className={style?.className} d="M70.43,49.21c-1.95,0-3.84,0-5.8,0c-0.02,0.36-0.04,0.62-0.06,0.97c2.32,0,4.52,0,6.77,0
					c0-2.23,0-4.43,0-6.7c-0.28,0-0.56,0-0.91,0C70.43,45.37,70.43,47.24,70.43,49.21z"/>
				<path className={style?.className} d="M46.02,43.44c-0.38,0-0.62,0-0.93,0c0,2.25,0,4.48,0,6.73c2.24,0,4.44,0,6.67,0c0-0.3,0-0.56,0-0.9
					c-1.91,0-3.79,0-5.74,0C46.02,47.31,46.02,45.42,46.02,43.44z"/>
				<path className={style?.className} d="M49.12,36.02c0,0,0.01-0.01,0.01-0.01c-0.01,0-0.02,0-0.03,0c0,0.01,0,0.02,0,0.02c0.01,0,0.02,0,0.03,0
					c0-0.01,0-0.02,0-0.02L49.12,36.02z"/>
				<path className={style?.className} d="M50.01,36.04c-0.35,0-0.62,0-0.88,0c0,0.65,0,1.3,0,1.96c-0.39,0.05-0.65,0.09-0.98,0.13
					c-0.04,0.28-0.08,0.56-0.12,0.9c-0.29,0.04-0.58,0.08-0.92,0.13c0,0.27,0,0.53,0,0.85c0.61,0,1.21,0,1.86,0
					c0.03-0.3,0.07-0.59,0.11-0.92c0.65,0,1.27,0,1.95,0c0.04,0.29,0.08,0.58,0.12,0.92c0.3,0,0.58,0,0.94,0c0-0.68,0-1.32,0-1.97
					c-0.67,0-1.33,0-2.08,0C50.01,37.31,50.01,36.69,50.01,36.04z"/>
				<path className={style?.className} d="M52.09,38.04c0.01,0,0.02,0,0.03,0c0-0.01,0-0.02,0-0.03c-0.01,0-0.02,0-0.03,0
					C52.09,38.02,52.09,38.03,52.09,38.04z"/>
				<path className={style?.className} d="M64.32,36.1c-0.02,0.33-0.03,0.62-0.04,0.91c0.32,0.02,0.64,0.03,1,0.05c0.04,0.31,0.08,0.6,0.13,0.94
					c0.59,0,1.19,0,1.85,0c0.03-0.3,0.06-0.59,0.1-0.91c0.31-0.04,0.57-0.07,0.91-0.11c0-0.61,0-1.23,0-1.9c-0.26,0-0.52,0-0.82,0
					c-0.03,0.28-0.05,0.55-0.09,0.88c-0.66,0-1.3,0-2,0c-0.03-0.28-0.07-0.57-0.12-0.92c-0.66,0-1.3,0-1.95,0
					c0.02,0.31,0.04,0.62,0.07,0.93C63.7,36.02,63.99,36.06,64.32,36.1z"/>
				<path className={style?.className} d="M63.28,35.05c0-0.01,0-0.02,0-0.03c-0.01,0-0.02,0-0.03,0c0,0.01,0,0.02,0,0.03
					C63.26,35.05,63.27,35.05,63.28,35.05z"/>
				<path className={style?.className} d="M54.09,37.01c0.02-0.29,0.04-0.58,0.06-0.92c0.3-0.04,0.59-0.07,0.93-0.12c0-0.29,0-0.58,0-0.93
					c-0.67,0-1.31,0-1.96,0c0,0.31,0,0.62,0,0.93c-0.39,0-0.68,0-1.02,0c0-0.35,0-0.64,0-0.94c-0.32,0-0.65,0-0.97,0
					c0,0.64,0,1.29,0,1.93c0.35,0.05,0.64,0.08,0.95,0.12c0.02,0.33,0.03,0.62,0.05,0.91c0.33,0,0.65,0,0.98,0
					c0.02-0.31,0.04-0.62,0.07-0.94C53.51,37.05,53.8,37.03,54.09,37.01z"/>
				<path className={style?.className} d="M53.12,35.05c0-0.01,0-0.02,0-0.03c-0.01,0-0.02,0-0.03,0c0,0.01,0,0.02,0,0.03
					C53.1,35.05,53.11,35.05,53.12,35.05z"/>
				<path className={style?.className} d="M53.1,38.01c0,0.01,0,0.02,0,0.03c0.01,0,0.01,0,0.02,0c0-0.01,0-0.02,0-0.03
					C53.11,38.01,53.1,38.01,53.1,38.01z"/>
				<path className={style?.className} d="M59.3,47.27c-0.02,0.28-0.04,0.55-0.06,0.87c0.66,0,1.28,0,1.95,0c0.03-0.3,0.05-0.57,0.08-0.88
					c0.3-0.04,0.59-0.07,0.94-0.12c0-0.28,0-0.57,0-0.91c-0.3-0.03-0.59-0.06-0.99-0.1c0.03-0.65,0.06-1.26,0.09-1.88
					c-0.65-0.04-1.3-0.07-2.01-0.12c-0.02,0.35-0.03,0.62-0.05,0.93c0.33,0.05,0.62,0.09,0.96,0.14c0,0.63,0,1.26,0,1.95
					C59.92,47.19,59.63,47.23,59.3,47.27z"/>
				<path className={style?.className} d="M61.3,44.23c0,0.01,0,0.01,0,0.02c0.01,0,0.01,0,0.02,0L61.3,44.23z"/>
				<path className={style?.className} d="M57.29,48.15c0.24,0.02,0.48,0.03,0.83,0.05c0-0.66,0-1.28,0-1.94c0.38-0.06,0.67-0.1,1-0.15
					c0-0.29,0-0.57,0-0.9c-0.63,0-1.23,0-1.85,0c-0.03,0.31-0.05,0.58-0.09,1.01c-1.03,0-2.06,0-3.09,0c0,0.3-0.01,0.6-0.01,0.9
					c1.01,0,2.02,0,3.06,0C57.2,47.53,57.24,47.82,57.29,48.15z"/>
				<path className={style?.className} d="M54.08,47.12c-0.01,0-0.02,0-0.02,0l0.01,0.01c0,0-0.01-0.01-0.01-0.01c0,0.01,0,0.02,0,0.03
					c0.01,0,0.01,0,0.02,0C54.08,47.14,54.08,47.13,54.08,47.12z"/>
				<path className={style?.className} d="M50,43.23c-0.61,0-1.23,0-1.87,0c0,0.28,0,0.54,0,0.84c0.3,0.03,0.56,0.07,0.88,0.1
					c0.07,0.69,0.13,1.38,0.2,2.07c0.26-0.04,0.52-0.08,0.81-0.12c0.03-0.31,0.06-0.6,0.09-0.94c0.35,0,0.67,0,0.98,0
					c0.03-0.3,0.05-0.6,0.08-0.91c-0.34-0.06-0.69-0.11-1.05-0.17C50.08,43.83,50.04,43.56,50,43.23z"/>
				<path className={style?.className} d="M49.21,46.25c0,0,0-0.01,0-0.01c-0.01,0-0.01,0-0.02,0L49.21,46.25z"/>
				<polygon className={style?.className} points="51.18,44.28 51.17,44.26 51.17,44.27 				"/>
				<path className={style?.className} d="M51.1,45.19c-0.01,0.01-0.01,0.01-0.01,0.01c0.01,0,0.02,0,0.03,0c0-0.01,0-0.02,0-0.03
					c-0.01,0-0.02,0-0.02,0c0,0.01,0,0.02,0,0.03L51.1,45.19z"/>
				<path className={style?.className} d="M52.12,35.02c-0.01,0-0.02,0-0.02,0c0,0.01,0,0.02,0,0.02c0.01,0,0.02,0,0.03,0
					C52.12,35.03,52.12,35.03,52.12,35.02z"/>
				<path className={style?.className} d="M52.12,35.02c0.32,0,0.65,0,0.97,0c0.02-0.31,0.04-0.63,0.07-1.07c0.64,0.05,1.25,0.1,1.85,0.14
					c0.05-0.31,0.1-0.63,0.15-1.02c-1.39,0-2.68,0-4.04,0c0,0.33,0,0.64,0,0.94c0.3,0,0.61,0,0.94,0
					C52.09,34.39,52.11,34.71,52.12,35.02z"/>
				<path className={style?.className} d="M51.13,34.02c-0.01,0-0.02,0-0.03,0c0,0.01,0,0.02,0,0.03c0.01,0,0.02,0,0.03,0
					C51.13,34.04,51.13,34.03,51.13,34.02z"/>
				<polygon className={style?.className} points="55.02,34.09 55.02,34.09 55.02,34.1 				"/>
				<polygon className={style?.className} points="51.03,43.31 51.03,43.31 51.03,43.31 				"/>
				<path className={style?.className} d="M51.18,44.27c0,0,0-0.01,0-0.01c0,0-0.01,0-0.01,0C51.17,44.26,51.18,44.28,51.18,44.27z"/>
				<path className={style?.className} d="M53.05,44.24c0,0,0.03-0.02,0.02-0.02c-0.01,0-0.01,0-0.02,0C53.05,44.22,53.05,44.23,53.05,44.24z"/>
				<path className={style?.className} d="M54.06,43.05c0-0.58,0-1.17,0-1.83c-0.27,0-0.55,0-0.88,0c-0.03,0.28-0.05,0.54-0.08,0.88
					c-0.3,0.03-0.59,0.07-0.93,0.11c-0.04,0.28-0.08,0.57-0.12,0.86c-0.35,0.08-0.68,0.17-1.02,0.25c0.05,0.32,0.1,0.63,0.14,0.95
					c0.63-0.01,1.25-0.03,1.87-0.04c0.03-0.32,0.07-0.64,0.11-1.06C53.43,43.13,53.72,43.09,54.06,43.05z"/>
				<path className={style?.className} d="M52.05,42.04c0.01-0.25,0.03-0.51,0.05-0.83c-0.32-0.04-0.61-0.08-0.95-0.13
					c-0.04-0.29-0.08-0.57-0.13-0.91c-0.61,0-1.2,0-1.88,0c-0.01,0.25-0.03,0.51-0.04,0.83c0.34,0.04,0.63,0.07,1,0.1
					c0,0.66,0,1.28,0,1.81c0.38,0.16,0.66,0.27,0.94,0.39c0.03-0.38,0.06-0.76,0.1-1.16C51.44,42.12,51.71,42.08,52.05,42.04z"/>
				<polygon className={style?.className} points="51.03,43.31 51.03,43.31 51.03,43.31 				"/>
				<path className={style?.className} d="M55.25,31.87c0.25,0.02,0.51,0.03,0.81,0.05c0.05-0.34,0.09-0.63,0.14-0.96c0.28-0.03,0.55-0.06,0.91-0.11
					c0.02-0.24,0.03-0.5,0.05-0.82c-0.34-0.05-0.63-0.1-0.96-0.15c-0.04-0.27-0.07-0.54-0.11-0.86c-0.26,0-0.51,0-0.82,0
					c-0.04,0.28-0.07,0.54-0.11,0.85c-0.31,0.05-0.6,0.09-0.94,0.15c0,0.27,0,0.53,0,0.82c0.28,0.03,0.55,0.06,0.92,0.1
					C55.16,31.22,55.2,31.51,55.25,31.87z"/>
				<path className={style?.className} d="M50.15,47.27c-0.02,0.27-0.04,0.54-0.07,0.87c0.66,0,1.28,0,1.96,0c0-0.93,0-1.88,0-2.93
					c-0.31,0-0.62,0-0.92,0c0,0.65,0,1.3,0,1.96C50.75,47.2,50.48,47.23,50.15,47.27z"/>
				<path className={style?.className} d="M55.17,27.96c0.27-0.04,0.56-0.08,0.91-0.13c0-0.61,0-1.24,0-1.91c-0.32,0-0.56,0-0.82,0
					c-0.04,0.32-0.08,0.62-0.13,0.93c-0.3,0.03-0.57,0.07-0.91,0.1c0,0.61,0,1.23,0,1.89c0.28,0,0.54,0,0.85,0
					C55.11,28.56,55.14,28.29,55.17,27.96z"/>
				<path className={style?.className} d="M69.28,33.08c-0.94,0-1.89,0-2.88,0c0,0.28,0,0.54,0,0.88c0.64,0,1.24,0,1.9,0
					c0.05,0.36,0.09,0.65,0.14,1.01c0.3,0,0.54,0,0.84,0C69.28,34.35,69.28,33.75,69.28,33.08z"/>
				<path className={style?.className} d="M66.24,39.15c-0.3,0-0.54,0-0.83,0c0,0.63,0,1.22,0,1.86c0.93,0,1.86,0,2.84,0c0-0.27,0-0.52,0-0.88
					c-0.64,0-1.26,0-1.9,0C66.31,39.76,66.28,39.49,66.24,39.15z"/>
				<path className={style?.className} d="M54.09,46.2c-0.01,0-0.02,0-0.02,0c0,0.01,0,0.02,0,0.03c0,0,0.01-0.01,0.02-0.02
					c-0.01,0.01-0.02,0.02-0.02,0.02c0.01,0,0.02,0,0.03,0C54.09,46.22,54.09,46.21,54.09,46.2C54.09,46.2,54.09,46.2,54.09,46.2z"
					/>
				<path className={style?.className} d="M55.09,45.1c0-0.29,0-0.56,0-0.86c-0.67,0-1.34,0-2.01,0c0.04,0.62,0.08,1.23,0.12,1.89
					c0.3,0.02,0.59,0.04,0.87,0.07c0.03-0.31,0.06-0.63,0.1-1C54.47,45.17,54.76,45.14,55.09,45.1z"/>
				<path className={style?.className} d="M53.05,44.24c0.01,0,0.02,0,0.02,0c0-0.01,0-0.01,0-0.02C53.07,44.22,53.05,44.24,53.05,44.24z"/>
				<path className={style?.className} d="M63.26,34.02l0.03,0.03c0-0.01,0-0.02,0-0.03C63.27,34.02,63.26,34.02,63.26,34.02z"/>
				<path className={style?.className} d="M65.31,33.07c-0.7,0-1.31,0-1.98,0c-0.02,0.33-0.03,0.64-0.05,0.95c0.63,0.02,1.27,0.04,1.9,0.06
					C65.22,33.76,65.26,33.45,65.31,33.07z"/>
				<path className={style?.className} d="M65.19,34.09c0.01,0,0.01,0,0.02,0c0,0,0-0.01,0-0.01c-0.01,0-0.01,0-0.02,0
					C65.19,34.08,65.19,34.08,65.19,34.09L65.19,34.09z"/>
				<path className={style?.className} d="M53.12,40.01c0.35,0.02,0.62,0.03,0.97,0.05c0-0.7,0-1.37,0-2.03c-0.32,0-0.65,0-0.97,0
					C53.12,38.68,53.12,39.32,53.12,40.01z"/>
				<path className={style?.className} d="M54.09,38.04c0.01,0,0.02,0,0.03,0c0-0.01,0-0.02,0-0.03c-0.01,0-0.02,0-0.03,0
					C54.09,38.01,54.09,38.03,54.09,38.04z"/>
				<path className={style?.className} d="M49.19,46.25c0.01,0,0.01,0,0.02,0c0,0-0.02-0.01-0.02-0.01C49.19,46.24,49.19,46.25,49.19,46.25z"/>
				<path className={style?.className} d="M48.15,48.15c0.24,0.01,0.49,0.03,0.78,0.05c0.09-0.68,0.17-1.32,0.25-1.95c-0.32-0.03-0.64-0.05-1.03-0.09
					C48.15,46.83,48.15,47.45,48.15,48.15z"/>
				<path className={style?.className} d="M49.14,35.02c-0.01,0-0.02,0-0.03,0c0,0.01,0,0.02,0,0.03c0.01,0,0.02,0,0.03,0
					C49.14,35.03,49.14,35.03,49.14,35.02z"/>
				<path className={style?.className} d="M51.1,35.02c0,0.01,0,0.02,0,0.02c0.01,0,0.02,0,0.02,0c0-0.01,0-0.02,0-0.02
					C51.12,35.02,51.11,35.02,51.1,35.02z"/>
				<path className={style?.className} d="M51.1,34.05c-0.64,0-1.28,0-1.96,0c0,0.35,0,0.66,0,0.97c0.65,0,1.31,0,1.96,0
					C51.1,34.7,51.1,34.37,51.1,34.05z"/>
				<path className={style?.className} d="M59.12,26.84c0.01-0.29,0.03-0.58,0.05-0.91c-0.65,0-1.24,0-1.93,0c0.02,0.34,0.03,0.6,0.04,0.91
					C57.89,26.84,58.46,26.84,59.12,26.84z"/>
				<path className={style?.className} d="M56.26,32.04c0,0.63,0,1.22,0,1.88c0.26,0,0.51,0,0.83,0c0-0.61,0-1.2,0-1.88
					C56.8,32.04,56.56,32.04,56.26,32.04z"/>
				<path className={style?.className} d="M59.13,42.21c-0.28-0.02-0.51-0.04-0.85-0.06c0,0.7,0,1.29,0,1.93c0.29,0,0.55,0,0.85,0
					C59.13,43.44,59.13,42.85,59.13,42.21z"/>
				<path className={style?.className} d="M63.26,34.02c0,0.01,0,0.02,0,0.03c0.01,0,0.02,0,0.03,0C63.28,34.05,63.26,34.02,63.26,34.02z"/>
				<path className={style?.className} d="M62.28,34.05c0-0.01,0-0.02,0-0.03c-0.01,0-0.02,0-0.03,0c0,0.01,0,0.02,0,0.03
					C62.26,34.05,62.27,34.05,62.28,34.05z"/>
				<path className={style?.className} d="M53.21,48.16c0.26,0.01,0.53,0.03,0.85,0.04c0-0.38,0-0.71,0-1.05c-0.26,0.04-0.52,0.08-0.85,0.12
					C53.21,47.54,53.21,47.82,53.21,48.16z"/>
				<path className={style?.className} d="M48.13,35.04c0,0.34,0,0.62,0,0.97c0.35,0,0.66,0,0.98,0c0-0.32,0-0.65,0-0.97
					C48.79,35.04,48.48,35.04,48.13,35.04z"/>
				<path className={style?.className} d="M62.27,43.22c-0.33,0-0.61,0-0.99,0c0.02,0.34,0.03,0.67,0.05,1.01c0.28-0.06,0.56-0.12,0.9-0.19
					C62.24,43.8,62.25,43.54,62.27,43.22z"/>
				<path className={style?.className} d="M61.32,44.23c-0.01,0-0.01,0-0.02,0c0,0,0.02,0.02,0.02,0.02C61.32,44.25,61.32,44.24,61.32,44.23z"/>
				<path className={style?.className} d="M64.25,37.01c0,0.01,0,0.02,0,0.03c0.01,0,0.02,0,0.03,0c0-0.01,0-0.02,0-0.03
					C64.27,37.01,64.26,37.01,64.25,37.01L64.25,37.01z"/>
				<path className={style?.className} d="M63.28,38.01c0.31,0,0.62,0,0.96,0c0-0.35,0-0.66,0-0.96c-0.29,0.02-0.58,0.04-0.9,0.06
					C63.32,37.43,63.3,37.72,63.28,38.01z"/>
				<path className={style?.className} d="M66.24,35c0-0.32,0-0.61,0-0.99c-0.36,0.02-0.7,0.04-1.04,0.07c0.08,0.27,0.16,0.55,0.25,0.88
					C65.68,34.98,65.93,34.99,66.24,35z"/>
				<path className={style?.className} d="M48.13,41.2c0,0.28,0,0.54,0,0.84c0.28,0,0.54,0,0.85,0c0-0.28,0-0.54,0-0.84
					C48.7,41.2,48.44,41.2,48.13,41.2z"/>
				<polygon className={style?.className} points="55.03,34.1 55.02,34.09 55.02,34.1 				"/>
				<path className={style?.className} d="M56.08,34.95c0-0.28,0-0.57,0-0.94c-0.37,0.03-0.71,0.06-1.05,0.09c0.09,0.27,0.18,0.53,0.29,0.86
					C55.51,34.95,55.77,34.95,56.08,34.95z"/>
				<path className={style?.className} d="M57.1,27.99c-0.3,0-0.56,0-0.86,0c0,0.29,0,0.53,0,0.83c0.28,0,0.56,0,0.86,0
					C57.1,28.54,57.1,28.28,57.1,27.99z"/>
				<path className={style?.className} d="M56.24,35.96c0.28,0,0.55,0,0.87,0c0-0.28,0-0.55,0-0.87c-0.28,0-0.55,0-0.87,0
					C56.24,35.38,56.24,35.64,56.24,35.96z"/>
				<path className={style?.className} d="M68.41,38c0.27,0.02,0.52,0.03,0.85,0.05c0-0.33,0-0.62,0-0.94c-0.26,0-0.52,0-0.85,0
					C68.41,37.42,68.41,37.67,68.41,38z"/>
				<path className={style?.className} d="M68.27,38.13c-0.29,0-0.55,0-0.84,0c0,0.3,0,0.56,0,0.87c0.29,0,0.55,0,0.84,0
					C68.27,38.7,68.27,38.44,68.27,38.13z"/>
				<path className={style?.className} d="M65.41,46.15c0.62,0,1.19,0,1.83,0c0-0.63,0-1.24,0-1.92c-0.58,0-1.17,0-1.83,0
					C65.41,44.84,65.41,45.45,65.41,46.15z"/>
				<path className={style?.className} d="M67.24,28c-0.59,0-1.18,0-1.83,0c0,0.59,0,1.18,0,1.87c0.63,0,1.2,0,1.83,0
					C67.24,29.25,67.24,28.65,67.24,28z"/>
				<path className={style?.className} d="M64.1,8.66H52.29c-0.38,0-0.69,0.31-0.69,0.69s0.31,0.69,0.69,0.69H64.1c0.38,0,0.69-0.31,0.69-0.69
					S64.48,8.66,64.1,8.66z"/>
				<path className={style?.className} d="M58.28,65.6c-1.46,0-2.65,1.19-2.65,2.65c0,1.46,1.19,2.65,2.65,2.65c1.46,0,2.65-1.19,2.65-2.65
					C60.93,66.79,59.74,65.6,58.28,65.6z"/>
			</g>
			<circle className={style?.className} cx="58.23" cy="68.3" r="3.44"/>
	</g>
		</svg>
    )
}

export const icon_extraccion = (style) =>{
    return (
        <img src={extraccion} style={style?.size ? {width:`${style?.size}px`, height:`${style?.size}px`} : {width:"25px", height:"25px"}} alt="extraccion" />
    )
}

export const icon_inicio = (style) =>{
    return (
        // <img src={inicio} className={style?.className} alt="inicio"/>
        <svg
        className={style?.className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            style={{ enableBackground: "new 0 0 100 100" }}
            xmlSpace="preserve"
        >
            <style>
                {`
                    .st0 { fill: ${"#cfcfcf"}; }
                    .st1 { fill: none; stroke: ${"#cfcfcf"}; stroke-width: 0.7426; stroke-miterlimit: 10; }
                    .st2 { fill: ${"#cfcfcf"}; stroke: ${"#cfcfcf"}; stroke-width: 0.7426; stroke-miterlimit: 10; }
                    .st3 { fill: ${"#cfcfcf"}; stroke: ${"#cfcfcf"}; stroke-width: 0.7468; stroke-miterlimit: 10; }
                    .st4 { fill: ${"#cfcfcf"}; stroke: ${"#cfcfcf"}; stroke-miterlimit: 10; }
                `}
            </style>
            <g>
                        <path className={style?.className} d="M61.64,80.17c0-2.36,0-4.7,0-7.04c0-4.91,0-9.82-0.01-14.74c0-2.3-1.25-3.39-3.84-3.4
                            c-5.07,0-10.14,0-15.21,0c-2.67,0-3.89,1.07-3.89,3.42c-0.01,7.01-0.01,14.02-0.01,21.03c0,0.24-0.04,10.83-0.04,11.12
                            c-0.38,0.02-2.19-0.01-2.49-0.01c-5.44,0-9.66-0.01-15.1-0.02c-1.8,0-3.47-0.38-4.39-1.9c-0.42-0.69-0.7-1.55-0.7-2.33
                            c-0.04-12.54-0.02-25.08-0.04-37.61c0-1.64,0.8-2.85,2.19-3.85c6.7-4.86,13.38-9.75,20.07-14.62c2.78-2.02,5.55-4.05,8.32-6.08
                            c2.25-1.64,4.2-1.71,6.46-0.09c4.2,3.02,8.36,6.09,12.53,9.13c4.27,3.11,8.54,6.23,12.81,9.34c1.16,0.85,2.32,1.7,3.49,2.54
                            c1.28,0.92,1.9,2.1,1.9,3.56c0,12.57,0.01,25.13-0.01,37.7c0,2.5-1.79,4.16-4.67,4.19c-5.81,0.06-11.58,0.02-17.39,0.02
                            C61.6,90.55,61.84,80.23,61.64,80.17z"/>
                        <path className={style?.className} d="M95.43,43c-0.05,0.86,0.03,1.75-0.19,2.56c-0.63,2.31-2.67,3.54-5.18,3.26c-2.14-0.24-3.85-2.1-3.87-4.37
                            c-0.01-0.78-0.31-1.23-0.92-1.69c-6.52-4.82-13.01-9.66-19.51-14.5c-4.27-3.18-8.53-6.36-12.81-9.53
                            c-2.14-1.58-4.27-1.47-6.43,0.16c-5.53,4.14-11.09,8.24-16.63,12.36c-5.2,3.87-10.4,7.75-15.61,11.61
                            c-0.54,0.4-0.76,0.83-0.77,1.5c-0.04,2.93-2.46,4.71-5.08,4.48c-1.69-0.15-3.6-1.31-3.85-2.95c-0.28-1.82-0.2-3.7-0.09-5.55
                            c0.06-1.06,0.88-1.86,1.77-2.52c7.61-5.65,15.23-11.3,22.83-16.96c4.61-3.43,9.22-6.85,13.79-10.31
                            C44.87,9.04,47.1,8.02,49.7,8c2.46-0.02,4.66,0.75,6.6,2.2c4.33,3.24,8.69,6.45,13.03,9.68c4.97,3.69,9.93,7.39,14.89,11.09
                            c3.09,2.3,6.19,4.6,9.28,6.91c1.24,0.93,1.91,2.13,1.87,3.65c-0.01,0.49,0,0.98,0,1.47C95.39,42.99,95.41,43,95.43,43z"/>
            </g>
        </svg>
    )
}

export const icon_miCooperativa = (style) =>{
    return (
        // <img src={miCooperativa} className={style?.className} alt="miCooperativa" />
        <svg 
        className={style?.className}
	 xmlns="http://www.w3.org/2000/svg" 
     viewBox="0 0 100 100"
	 style={{ enableBackground: "new 0 0 100 100" }}
     xmlSpace="preserve">
	<g>
				<path className={style?.className} d="M48.06,6C31.46,6,17.89,19.55,17.89,36.13c-0.01,16.59,13.53,30.02,30.23,30
					C64.78,66.12,78.25,52.62,78.22,36C78.19,19.52,64.59,6,48.06,6z M32.28,58.35c-3.25-2.28-5.62-4.92-7.55-7.99
					c-0.36-0.58-0.38-0.88,0.45-0.87c2.09,0.03,4.18,0.02,6.27,0.01c0.55,0,0.84,0.2,0.83,0.8C32.27,52.88,32.28,55.46,32.28,58.35z
					 M23.24,47.25c-5.72-11.18-1.55-27.48,10.53-34.21C30.24,24.5,26.75,35.83,23.24,47.25z M56.75,62.01
					c-5.85,1.86-11.7,1.83-17.54-0.05c-0.62-0.2-0.94-0.5-0.94-1.25c0.04-3.37,0.06-6.73,0-10.1c-0.02-0.97,0.37-1.13,1.2-1.12
					c2.82,0.04,5.64,0.01,8.46,0.01c2.87,0,5.73,0.03,8.6-0.02c0.91-0.02,1.17,0.3,1.16,1.18c-0.04,3.32-0.04,6.64,0,9.96
					C57.72,61.4,57.47,61.78,56.75,62.01z M59.35,12.39c-3.49,11.69-6.94,23.4-10.41,35.1c-0.04,0.12-0.14,0.22-0.31,0.46
					c-4.23-11.81-8.39-23.47-12.59-35.12c-0.28-0.76-0.29-1.16,0.57-1.54c7.28-3.19,14.6-3.31,21.99-0.4
					C59.38,11.2,59.6,11.55,59.35,12.39z M71.45,50.27c-1.18,2.37-5.24,6.66-7.75,8.03c0-2.73,0.01-5.39-0.01-8.04
					c0-0.62,0.32-0.76,0.86-0.75c2.13,0.01,4.26,0.01,6.4,0C71.56,49.5,71.78,49.6,71.45,50.27z M73.11,47.01
					c-3.97-11.69-7.85-23.12-11.77-34.68C72.81,18.47,78.98,33.26,73.11,47.01z"/>
			<path className={style?.className} d="M79.82,73.53c0.06-0.04,0.1-0.07,0.15-0.11c0,0,0,0,0,0C79.91,73.46,79.86,73.5,79.82,73.53
				C79.81,73.54,79.81,73.53,79.82,73.53z"/>
			<path className={style?.className} d="M84.55,83.28c-0.92-2.6-2.75-4.04-5.51-4.21c-0.85-0.05-1.71-0.01-2.69-0.32c1.52-1.02,3.04-2.06,4.56-3.07
				c2.39-1.58,2.43-2.55,0.17-4.42c-1.26-1.04-2.7-1.47-4.32-1.48c-0.46,0-1.05-0.12-1.34-0.41c-2.21-2.19-4.77-2.22-7.53-1.43
				c-0.43,0.12-1.07,0.21-1.35-0.01c-2.28-1.75-4.74-1.65-7.14-0.57c-3.5,1.58-6.92,3.34-10.33,5.11c-0.93,0.48-1.55,0.53-2.43-0.16
				c-5.21-4.08-11.08-5.91-17.72-5.01c-0.54,0.07-1.09,0.1-1.63,0.14c-0.04,0-0.09-0.02-0.31-0.09c-0.04-0.38-0.09-0.74-0.1-1.09
				c-0.04-1.19-0.85-1.17-2.44-1.07c-2.77,0.17-6.34,0.17-10.06,0.09c-1.85-0.04-2.67-0.24-2.7,0.99c0,0.23-0.01,0.46-0.01,0.69
				c0,8.19,0,16.39,0,24.58c0,1.79,0.38,2.1,2.16,1.73c3.85-0.82,7.69-1.68,11.54-2.47c1.08-0.22,1.65-0.73,1.51-1.86
				c-0.12-1.02,0.38-1.33,1.29-1.16c1.34,0.26,2.73,0.41,4.01,0.84c4.12,1.37,8.22,2.84,12.31,4.29c3.02,1.07,6.06,1.18,9.18,0.38
				c9.84-2.52,19.69-4.99,29.54-7.49C84.92,85.37,85.14,84.95,84.55,83.28z M22,71.5c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2s2,0.9,2,2
				C24,70.6,23.1,71.5,22,71.5z M75.84,72.3c1.44-0.63,2.81-0.15,4.12,1.12c0.38-0.29,0.68-0.5,0,0c0.02,0.02,0.05,0.04,0.07,0.07
				c-1.04,0.69-0.67,0.38-0.23,0.05c-1.94,1.45-9.92,6.15-12.31,6.47c-2.09,0.28-3.59-0.44-5.2-1.39c0.08-0.11,0.11-0.22,0.18-0.25
				C66.93,76.33,71.36,74.25,75.84,72.3z M67.79,70.48c0.82-0.37,1.73-0.6,2.63-0.73c1.04-0.15,2.03,0.09,2.93,0.97
				c-0.92,0.5-1.21,0.76-2.3,0.93c-3.27,0.52-6.35,1.87-8.87,4.01c-2.63,2.22-4.22,1.61-6.34,0.59c0.36-0.23,0.56-0.4,0.79-0.51
				C60.34,73.97,64.05,72.19,67.79,70.48z M61.35,69.13c0.82-0.35,1.91-0.05,2.88-0.05c0.03,0.15,0.06,0.3,0.1,0.46
				c-0.44,0.22-0.87,0.45-1.31,0.66c-2.64,1.24-5.3,2.46-7.92,3.73c-0.7,0.34-1.32,0.86-2.13,1.4c-0.63-0.23-1.46-0.52-2.29-0.82
				c-0.01-0.23-0.01-0.24-0.02-0.47C54.22,72.39,57.75,70.67,61.35,69.13z M81.4,83.93C81.4,83.93,81.4,83.93,81.4,83.93
				c-9.71,2.46-19.4,4.91-29.11,7.35c-1.95,0.49-3.9,0.47-5.82-0.16c-2.65-0.87-5.27-1.82-7.92-2.68
				c-3.59-1.17-7.02-2.94-10.93-2.96c-0.25,0-0.71-0.73-0.72-1.13c-0.06-3.52-0.03-7.04-0.03-10.57c0-4.22,0-4.18,4.25-4.4
				c5.93-0.31,10.85,2.02,15.33,5.63c0.61,0.49,1.29,0.87,2.02,1.14c4.57,1.7,9.18,3.33,13.75,5.04c1.38,0.52,2.05,1.63,1.7,2.61
				c-0.45,1.25-1.56,1.23-2.63,1.19c-0.23-0.01-0.45-0.06-0.68-0.09c-4.54-0.51-9.08-1.01-13.62-1.52
				c-0.18-0.02-0.36-0.05-0.55-0.06c-0.78-0.07-1.51,0.17-1.42,1.03c0.05,0.44,0.7,1.1,1.15,1.15c4.81,0.62,9.62,1.18,14.44,1.67
				c3.27,0.33,4.83-0.46,5.49-3.14c0.28-1.13,1.29-1.93,2.46-2c3.56-0.22,7.15-0.47,10.74-0.6c1.06-0.04,1.91,0.5,2.49,1.46
				C82.02,83.28,81.84,83.81,81.4,83.93z"/>
	</g>
</svg>
    )
}

export const icon_pagoDeServicios = (style) =>{
    return (
        // <img src={pagoDeServicios} style={style?.size ? {width:`${style?.size}px`, height:`${style?.size}px`} : {width:"25px", height:"25px"}} alt="pagoDeServicios" />
        <svg
        className={style?.className}
	 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"
	 style={{ enableBackground: "new 0 0 100 100" }} xmlSpace="preserve">
<switch>
	<g>
				<path className={style?.className} d="M26.38,82.67c-1.93,0-3.5,1.57-3.5,3.5c0,1.93,1.57,3.5,3.5,3.5s3.5-1.57,3.5-3.5
					C29.88,84.23,28.31,82.67,26.38,82.67z"/>
				<path className={style?.className} d="M60.75,26.47c3.98-0.51,7.58-0.93,11.16-1.46c0.39-0.06,0.68-0.77,1.02-1.18
					c-0.46-0.32-0.96-0.96-1.38-0.92c-3.56,0.35-7.11,0.78-10.65,1.3c-0.42,0.06-1.02,0.79-1.04,1.23
					C59.84,25.82,60.53,26.23,60.75,26.47z"/>
				<path className={style?.className} d="M55.43,28.57c1.39-0.02,2.48-1.17,2.43-2.56c-0.05-1.32-1.17-2.4-2.49-2.38c-1.37,0.01-2.46,1.18-2.42,2.57
					C53,27.52,54.11,28.59,55.43,28.57z"/>
				<g>
					<path className={style?.className} d="M26.38,82.67c-1.93,0-3.5,1.57-3.5,3.5c0,1.93,1.57,3.5,3.5,3.5s3.5-1.57,3.5-3.5
						C29.88,84.23,28.31,82.67,26.38,82.67z"/>
					<path className={style?.className} d="M58.44,61.8c-1.49-0.3-2.07,0.35-2.4,1.65c1.72,1.64,3.44,0.61,5.12-0.08c3.17-1.32,4.24-2.32,4.28-3.97
						C63.38,61.16,61.14,62.34,58.44,61.8z"/>
					<path className={style?.className} d="M18.34,61.3c5.19,6.58,10.27,13.02,15.41,19.54c1.15-0.88,2.14-1.65,3.18-2.45
						c-5.23-6.54-10.36-12.96-15.53-19.43L18.34,61.3z"/>
					<path className={style?.className} d="M49.91,28.51c0.28,2.29,0.57,4.59,0.87,7.04c2.84-0.35,5.45-0.67,8.07-0.98c2.34-0.28,4.64-0.52,6.83-1.62
						c0.97-0.49,2.37-0.16,3.58-0.15c0.8,0.01,1.62,0.24,2.4,0.16c4.11-0.47,8.21-1,12.31-1.52c2.92-0.37,5.83-0.74,8.9-1.14
						c-0.61-4.77-1.16-9.37-1.81-13.95c-0.15-1.03-1.01-1.49-2.05-1.39c-1.58,0.16-3.16,0.36-4.74,0.56
						c-11.05,1.37-22.1,2.73-33.15,4.11c-1.53,0.19-2.14,0.98-1.97,2.52C49.39,24.27,49.65,26.39,49.91,28.51z"/>
					<path className={style?.className} d="M62.19,48.66c-1.22,1.97-1.63,4.44-2.49,6.97c0.62-0.17,1.06-0.17,1.33-0.38
						c2.29-1.84,4.61-3.64,6.77-5.62c1.05-0.96,1.15-2.39,0.82-3.76C65.82,45.48,63.5,46.54,62.19,48.66z"/>
					<path className={style?.className} d="M70.34,36.47c-0.56-1.13-1.98-1.79-3.22-1.53c-0.68,0.14-1.35,0.4-1.97,0.71
						c-6.36,3.14-12.7,6.33-19.05,9.5c-0.72,0.36-1.55,0.71-2.02-0.22c-0.49-0.98,0.26-1.46,1.07-1.83
						c1.11-0.51,2.23-1.03,3.29-1.64c0.31-0.18,0.66-0.69,0.62-1.01c-0.32-2.98-0.71-5.94-1.1-9.04c-4.95,0.25-8.5,2.33-10.4,7.68
						c-2.52,7.08-6.23,13.66-11.16,19.33c-1.16,1.33-1.02,1.86-0.41,2.62c3.27,4.08,6.54,8.16,9.78,12.27
						c0.52,0.65,1.03,0.79,1.76,0.48c2.55-1.07,5.17-2,7.66-3.21c3.77-1.84,6.67-4.65,8.76-8.33c2.48-4.37,4.23-9.04,5.82-13.79
						c0.26-0.77,0.75-1.58,1.37-2.08c2.33-1.88,4.77-3.63,7.15-5.46c0.61-0.47,1.21-0.96,1.71-1.54
						C70.73,38.53,70.85,37.5,70.34,36.47z"/>
					<path className={style?.className} d="M93.57,35.95c-0.01-0.73-0.41-0.91-1.08-0.82c-6.22,0.78-12.45,1.54-18.67,2.33
						c-0.33,0.04-0.85,0.35-0.88,0.6c-0.35,2.3-1.73,3.83-3.54,5.19c0.13,0,0.26,0.01,0.39,0c6.76-0.84,13.51-1.72,20.27-2.52
						c3.81-0.45,4.07-0.71,3.53-4.5C93.58,36.13,93.57,36.04,93.57,35.95z"/>
				</g>
			</g>
			<g>
				<path className={style?.className} d="M18.34,61.3c5.19,6.58,10.27,13.02,15.41,19.54c1.15-0.88,2.14-1.65,3.18-2.45
					c-5.23-6.54-10.36-12.96-15.53-19.43L18.34,61.3z"/>
				<path className={style?.className} d="M58.44,61.8c-1.49-0.3-2.07,0.35-2.4,1.65c1.72,1.64,3.44,0.61,5.12-0.08c3.17-1.32,4.24-2.32,4.28-3.97
					C63.38,61.16,61.14,62.34,58.44,61.8z"/>
				<path className={style?.className} d="M26.38,82.67c-1.93,0-3.5,1.57-3.5,3.5c0,1.93,1.57,3.5,3.5,3.5s3.5-1.57,3.5-3.5
					C29.88,84.23,28.31,82.67,26.38,82.67z"/>
				<path className={style?.className} d="M49.91,28.51c0.28,2.29,0.57,4.59,0.87,7.04c2.84-0.35,5.45-0.67,8.07-0.98c2.34-0.28,4.64-0.52,6.83-1.62
					c0.97-0.49,2.37-0.16,3.58-0.15c0.8,0.01,1.62,0.24,2.4,0.16c4.11-0.47,8.21-1,12.31-1.52c2.92-0.37,5.83-0.74,8.9-1.14
					c-0.61-4.77-1.16-9.37-1.81-13.95c-0.15-1.03-1.01-1.49-2.05-1.39c-1.58,0.16-3.16,0.36-4.74,0.56
					c-11.05,1.37-22.1,2.73-33.15,4.11c-1.53,0.19-2.14,0.98-1.97,2.52C49.39,24.27,49.65,26.39,49.91,28.51z"/>
				<path className={style?.className} d="M93.57,35.95c-0.01-0.73-0.41-0.91-1.08-0.82c-6.22,0.78-12.45,1.54-18.67,2.33
					c-0.33,0.04-0.85,0.35-0.88,0.6c-0.35,2.3-1.73,3.83-3.54,5.19c0.13,0,0.26,0.01,0.39,0c6.76-0.84,13.51-1.72,20.27-2.52
					c3.81-0.45,4.07-0.71,3.53-4.5C93.58,36.13,93.57,36.04,93.57,35.95z"/>
				<path className={style?.className} d="M70.34,36.47c-0.56-1.13-1.98-1.79-3.22-1.53c-0.68,0.14-1.35,0.4-1.97,0.71
					c-6.36,3.14-12.7,6.33-19.05,9.5c-0.72,0.36-1.55,0.71-2.02-0.22c-0.49-0.98,0.26-1.46,1.07-1.83c1.11-0.51,2.23-1.03,3.29-1.64
					c0.31-0.18,0.66-0.69,0.62-1.01c-0.32-2.98-0.71-5.94-1.1-9.04c-4.95,0.25-8.5,2.33-10.4,7.68
					c-2.52,7.08-6.23,13.66-11.16,19.33c-1.16,1.33-1.02,1.86-0.41,2.62c3.27,4.08,6.54,8.16,9.78,12.27
					c0.52,0.65,1.03,0.79,1.76,0.48c2.55-1.07,5.17-2,7.66-3.21c3.77-1.84,6.67-4.65,8.76-8.33c2.48-4.37,4.23-9.04,5.82-13.79
					c0.26-0.77,0.75-1.58,1.37-2.08c2.33-1.88,4.77-3.63,7.15-5.46c0.61-0.47,1.21-0.96,1.71-1.54
					C70.73,38.53,70.85,37.5,70.34,36.47z"/>
				<path className={style?.className} d="M62.19,48.66c-1.22,1.97-1.63,4.44-2.49,6.97c0.62-0.17,1.06-0.17,1.33-0.38c2.29-1.84,4.61-3.64,6.77-5.62
					c1.05-0.96,1.15-2.39,0.82-3.76C65.82,45.48,63.5,46.54,62.19,48.66z"/>
			</g>
			<g>
				<path className={style?.className} d="M57.48,61.75c0,0,0.35,0.05,0.93,0.05C58.05,61.73,57.74,61.71,57.48,61.75z"/>
				<path className={style?.className} d="M56.51,62.34c0.44-0.39,0.97-0.59,0.97-0.59C57.05,61.81,56.74,62.02,56.51,62.34z"/>
				<path className={style?.className} d="M18.34,61.3c5.19,6.58,10.27,13.02,15.41,19.54c1.15-0.88,2.14-1.65,3.18-2.45
					c-5.23-6.54-10.36-12.96-15.53-19.43L18.34,61.3z"/>
				<path className={style?.className} d="M26.38,82.67c-1.93,0-3.5,1.57-3.5,3.5c0,1.93,1.57,3.5,3.5,3.5s3.5-1.57,3.5-3.5
					C29.88,84.23,28.31,82.67,26.38,82.67z"/>
				<path className={style?.className} d="M49.91,28.51c0.28,2.29,0.57,4.59,0.87,7.04c2.84-0.35,5.45-0.67,8.07-0.98c2.34-0.28,4.64-0.52,6.83-1.62
					c0.97-0.49,2.37-0.16,3.58-0.15c0.8,0.01,1.62,0.24,2.4,0.16c4.11-0.47,8.21-1,12.31-1.52c2.92-0.37,5.83-0.74,8.9-1.14
					c-0.61-4.77-1.16-9.37-1.81-13.95c-0.15-1.03-1.01-1.49-2.05-1.39c-1.58,0.16-3.16,0.36-4.74,0.56
					c-11.05,1.37-22.1,2.73-33.15,4.11c-1.53,0.19-2.14,0.98-1.97,2.52C49.39,24.27,49.65,26.39,49.91,28.51z"/>
				<path className={style?.className} d="M93.57,35.95c-0.01-0.73-0.41-0.91-1.08-0.82c-6.22,0.78-12.45,1.54-18.67,2.33
					c-0.33,0.04-0.85,0.35-0.88,0.6c-0.35,2.3-1.73,3.83-3.54,5.19c0.13,0,0.26,0.01,0.39,0c6.76-0.84,13.51-1.72,20.27-2.52
					c3.81-0.45,4.07-0.71,3.53-4.5C93.58,36.13,93.57,36.04,93.57,35.95z"/>
				<path className={style?.className} d="M62.19,48.66c-1.22,1.97-1.63,4.44-2.49,6.97c0.62-0.17,1.06-0.17,1.33-0.38c2.29-1.84,4.61-3.64,6.77-5.62
					c1.05-0.96,1.15-2.39,0.82-3.76C65.82,45.48,63.5,46.54,62.19,48.66z"/>
				<path className={style?.className} d="M70.34,36.47c-0.56-1.13-1.98-1.79-3.22-1.53c-0.68,0.14-1.35,0.4-1.97,0.71
					c-6.36,3.14-12.7,6.33-19.05,9.5c-0.72,0.36-1.55,0.71-2.02-0.22c-0.49-0.98,0.26-1.46,1.07-1.83c1.11-0.51,2.23-1.03,3.29-1.64
					c0.31-0.18,0.66-0.69,0.62-1.01c-0.32-2.98-0.71-5.94-1.1-9.04c-4.95,0.25-8.5,2.33-10.4,7.68
					c-2.52,7.08-6.23,13.66-11.16,19.33c-1.16,1.33-1.02,1.86-0.41,2.62c3.27,4.08,6.54,8.16,9.78,12.27
					c0.52,0.65,1.03,0.79,1.76,0.48c2.55-1.07,5.17-2,7.66-3.21c3.77-1.84,6.67-4.65,8.76-8.33c2.48-4.37,4.23-9.04,5.82-13.79
					c0.26-0.77,0.75-1.58,1.37-2.08c2.33-1.88,4.77-3.63,7.15-5.46c0.61-0.47,1.21-0.96,1.71-1.54
					C70.73,38.53,70.85,37.5,70.34,36.47z"/>
				<path className={style?.className} d="M66.58,53.24c-4.69,4.41-7.35,3.74-7.35,3.74l-1.37,3.13c7.11-1.04,6.78-2.94,8.58-3.7
					c1.8-0.76,1.28-4.17,1.28-4.17L66.58,53.24z"/>
				<path className={style?.className} d="M96.08,37.7c-0.85-6.9-1.72-13.8-2.62-20.7c-0.43-3.33-2.06-4.64-5.38-4.23
					c-12.46,1.52-24.92,3.07-37.38,4.64c-2.64,0.33-4.09,2.24-3.8,4.87c0.24,2.2,0.5,4.4,0.74,6.56c-0.26,0.13-0.37,0.22-0.5,0.25
					c-0.35,0.08-0.71,0.17-1.07,0.21c-4.34,0.54-7.47,2.78-9.6,6.6c-3.59,6.44-7.25,12.83-10.9,19.23
					c-0.55,0.97-1.14,1.92-1.89,3.19c-0.31-0.48-0.46-0.76-0.66-1c-1.17-1.4-1.37-1.42-2.87-0.28c-1.01,0.77-2.03,1.53-3.15,2.38
					c-0.84-0.76-1.4-1.71-2.72-0.94c-2.09,1.22-4.19,2.41-6.31,3.59c-3.59,2-4.57,6.39-2.18,9.74c6.14,8.6,9.18,12.86,15.33,21.46
					c0.89,1.24,3.97,3.97,7.53,0.72c4.05-2.58,4.66-3.72,7.97-7.48c0.58-0.66,0.56-1.29,0.03-1.96c-0.47-0.6-0.93-1.21-1.45-1.9
					c1.23-0.94,2.36-1.85,3.54-2.7c1.01-0.72,1.28-1.48,0.37-2.48c-0.36-0.39-0.64-0.85-1.05-1.4c6.49-2.21,12.54-4.86,16.6-10.6
					c1.63,0.97,3.27,1.59,5.01,0.88c1.89-0.77,3.77-1.58,5.54-2.59c1.97-1.12,2.82-3.01,2.85-5.26c0.01-0.5-0.14-1.11,0.1-1.47
					c0.98-1.46,1.09-3.05,1.01-4.71c-0.01-0.26-0.01-0.61,0.14-0.78c1.5-1.73,1.85-3.79,1.68-6.07c0.65-0.11,1.22-0.23,1.8-0.3
					c6.45-0.81,12.91-1.61,19.36-2.44C94.99,42.35,96.43,40.52,96.08,37.7z M51.12,19.63c11.05-1.37,22.1-2.74,33.15-4.11
					c1.58-0.2,3.16-0.4,4.74-0.56c1.04-0.11,1.9,0.36,2.05,1.39c0.65,4.58,1.2,9.18,1.81,13.95c-3.07,0.39-5.99,0.77-8.9,1.14
					c-4.1,0.52-8.21,1.05-12.31,1.52c-0.78,0.09-1.6-0.15-2.4-0.16c-1.21-0.01-2.61-0.34-3.58,0.15c-2.19,1.1-4.49,1.34-6.83,1.62
					c-2.61,0.31-5.22,0.63-8.07,0.98c-0.3-2.46-0.59-4.75-0.87-7.04c-0.26-2.12-0.53-4.24-0.76-6.37
					C48.98,20.6,49.6,19.82,51.12,19.63z M26.38,89.66c-1.93,0-3.5-1.57-3.5-3.5c0-1.93,1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5
					C29.88,88.1,28.31,89.66,26.38,89.66z M33.76,80.84c-5.14-6.52-10.22-12.96-15.41-19.54l3.07-2.34
					c5.17,6.47,10.3,12.89,15.53,19.43C35.89,79.19,34.9,79.95,33.76,80.84z M62.6,63.05c-3.79,1.56-6.08,2.32-6.57,0.85
					c-0.23-0.7,0.08-1.21,0.48-1.57c0.23-0.32,0.54-0.52,0.97-0.59c0.26-0.04,0.57-0.02,0.93,0.05c1.47,0.01,4.45-0.28,7.02-2.39
					C65.43,59.4,66.39,61.48,62.6,63.05z M66.44,56.41c-1.8,0.76-1.47,2.65-8.58,3.7l1.37-3.13c0,0,2.65,0.66,7.35-3.74l1.14-1
					C67.72,52.24,68.24,55.65,66.44,56.41z M67.8,49.63c-2.16,1.98-4.48,3.78-6.77,5.62c-0.27,0.22-0.71,0.22-1.33,0.38
					c0.86-2.53,1.27-5,2.49-6.97c1.31-2.12,3.63-3.18,6.43-2.79C68.95,47.24,68.84,48.67,67.8,49.63z M68.28,40.93
					c-2.38,1.82-4.81,3.57-7.15,5.46c-0.62,0.5-1.11,1.31-1.37,2.08c-1.59,4.75-3.33,9.41-5.82,13.79
					c-2.09,3.68-4.99,6.49-8.76,8.33c-2.48,1.21-5.1,2.14-7.66,3.21c-0.73,0.31-1.24,0.17-1.76-0.48c-3.24-4.11-6.5-8.19-9.78-12.27
					c-0.61-0.76-0.75-1.29,0.41-2.62c4.93-5.68,8.64-12.25,11.16-19.33c1.91-5.35,5.45-7.43,10.4-7.68c0.39,3.1,0.78,6.06,1.1,9.04
					c0.03,0.32-0.31,0.83-0.62,1.01c-1.06,0.61-2.18,1.13-3.29,1.64c-0.81,0.37-1.56,0.85-1.07,1.83c0.46,0.93,1.29,0.58,2.02,0.22
					c6.35-3.17,12.69-6.35,19.05-9.5c0.62-0.31,1.29-0.57,1.97-0.71c1.24-0.26,2.66,0.4,3.22,1.53c0.51,1.03,0.4,2.05-0.35,2.92
					C69.49,39.97,68.89,40.46,68.28,40.93z M90.06,40.72c-6.76,0.8-13.51,1.68-20.27,2.52c-0.13,0.02-0.26,0-0.39,0
					c1.81-1.36,3.19-2.89,3.54-5.19c0.04-0.24,0.56-0.55,0.88-0.6c6.22-0.8,12.45-1.55,18.67-2.33c0.67-0.08,1.07,0.09,1.08,0.82
					c0,0.09,0.01,0.18,0.03,0.27C94.13,40.02,93.87,40.27,90.06,40.72z"/>
				<path className={style?.className} d="M65.43,59.4c-2.57,2.12-5.55,2.4-7.02,2.39c0.01,0,0.02,0,0.03,0C61.14,62.34,63.38,61.16,65.43,59.4z"/>
	</g>
</switch>
</svg>
    )
}

export const icon_perfilUsuario = (style) =>{
    return (
        //<img src={perfilUsuario} style={style?.size ? {width:`${style?.size}px`, height:`${style?.size}px`} : {width:"25px", height:"25px"}} alt="perfilUsuario" />
		<svg
		className={style?.className}
	 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"
	 style={{enableBackground:"new 0 0 100 100"}} xmlSpace="preserve">
<switch>
	<g>
		<g>
			<path className={style?.className} d="M95.82,81.91c0,16.89-20.4,9.47-45.57,9.47S4.68,98.79,4.68,81.91s20.11-31.03,45.28-31.03
				C71.44,50.88,95.82,65.02,95.82,81.91z"/>
			<path id="XMLID_00000045617058192240657550000001138033673422088351_" className={style?.className} d="M32.09,13.38c4.1-3.9,9.55-6.04,15.34-6.04
				c11.96,0,21.7,9.23,21.71,20.58c0,5.49-2.26,10.65-6.37,14.55c-4.1,3.89-9.55,6.04-15.33,6.04h-0.01
				c-11.96,0-21.7-9.23-21.71-20.57C25.72,22.44,27.98,17.27,32.09,13.38z"/>
		</g>
	</g>
</switch>
</svg>
    )
}

export const icon_recargaSaldo2 = (style) =>{
    return (
        //<img src={recargaSaldo2} style={style?.size ? {width:`${style?.size}px`, height:`${style?.size}px`} : {width:"25px", height:"25px"}} alt="recargaSaldo2" />
		<svg
		className={style?.className}
	 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"
	 style={{enableBackground:"new 0 0 100 100"}} xmlSpace="preserve">
	<g>
		<g>
			<path className={style?.className} d="M47.09,96.6H18.98c-2.03,0-3.67-1.64-3.67-3.67V64.81c0-2.03,1.64-3.67,3.67-3.67h28.11
				c2.03,0,3.67,1.64,3.67,3.67v28.11C50.77,94.95,49.12,96.6,47.09,96.6z"/>
			<path className={style?.className} d="M50.77,45.39v6.76c0,3.26-2.65,5.91-5.91,5.91H15.31c-3.26,0-5.91-2.65-5.91-5.91V40.33
				c0-3.26,2.65-5.91,5.91-5.91h29.55h0c3.26,0,5.91,2.65,5.91,5.91V45.39z"/>
			<path className={style?.className} d="M50.24,38.86c-2.56,0-4.64-2.08-4.64-4.64c0-0.89,0.27-1.72,0.73-2.4c0.08-0.11,0.07-0.26-0.03-0.36
				c-2.35-2.35-12.39-11.77-16.75-5.63c-4.08,5.74,6.13,11.1,9.88,12.82c0.28,0.13,0.18,0.55-0.13,0.54
				c-2.42-0.08-12.89,0.04-17.71-7.46c-0.6-0.93-3.77-5.87-1.73-11.08c1.88-4.78,6.98-6.24,7.75-6.46c2.11-0.22,5.46-0.23,9.04,1.29
				c9.23,3.91,11.41,14.36,11.62,15.5c-0.32-0.17-0.64-0.35-0.96-0.52c0.09,0.1,0.25,0.12,0.37,0.05c0.66-0.41,1.44-0.64,2.27-0.64
				h3.68c0.83,0,1.61,0.24,2.27,0.64c0.12,0.07,0.27,0.06,0.37-0.05c0.85-4.06,2.5-6.9,3.63-8.52c1.35-1.95,4.7-6.78,10.33-7.75
				c0.76-0.13,2.14-0.36,3.87,0c4.96,1.02,7.38,5.7,7.75,6.46c1.69,3.41,1.8,7.66,0.14,11.08c-3.85,7.94-15.19,7.38-17.71,7.46
				c-0.31,0.01-0.41-0.41-0.13-0.54c3.75-1.72,13.96-7.09,9.88-12.82c-4.36-6.13-14.39,3.28-16.75,5.63c-0.1,0.1-0.1,0.24-0.03,0.36
				c0.46,0.69,0.73,1.51,0.73,2.4v0c0,2.56-2.08,4.64-4.64,4.64H50.24z"/>
			<path className={style?.className} d="M84.79,96.64H56.93c-2.1,0-3.8-1.7-3.8-3.8V64.98c0-2.1,1.7-3.8,3.8-3.8h27.87c2.1,0,3.8,1.7,3.8,3.8v27.87
				C88.59,94.94,86.89,96.64,84.79,96.64z"/>
			<path className={style?.className} d="M88.66,58.06h-29.7c-3.22,0-5.84-2.61-5.84-5.84v-6.84v-5.13c0-3.22,2.61-5.84,5.84-5.84h0.07h29.62
				c3.22,0,5.84,2.61,5.84,5.84v11.97C94.5,55.45,91.89,58.06,88.66,58.06z"/>
		</g>
	</g>
</svg>
    )
}

export const icon_recargaSaldo = (style) =>{
    return (
        //<img src={recargaSaldo} style={style?.size ? {width:`${style?.size}px`, height:`${style?.size}px`} : {width:"25px", height:"25px"}} alt="recargaSaldo" />
		<svg
		className={style?.className}
	 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"
	 style={{enableBackground:"new 0 0 100 100"}} xmlSpace="preserve">
	<g>
				<g>
					<g>
						<path className={style?.className} d="M94.94,45.44c-2.04-8.79-9.36-15.7-17.46-17.38c-0.32-0.07-0.56-0.35-0.56-0.69
							c0.01-3.97,0.02-7.94,0.03-11.91c0-0.04,0-0.07,0-0.11c-0.01-4.22-3.47-7.63-7.69-7.63H34.03c-4.22,0-7.68,3.4-7.69,7.63
							c0,0.04,0,0.07,0,0.11c0.01,4.21-0.01,6.57,0,10.78l4.22,1.54l0.03-9.96c0-0.39,0.31-0.7,0.7-0.7h40.63
							c0.39,0,0.7,0.31,0.7,0.7l0.04,51.69l4.28-9.15c-0.01-9.09-0.05-16.62-0.04-25.7c0-0.47,0.46-0.81,0.9-0.67
							c8.38,2.59,11.59,7.77,12.03,15.61c0.43,7.62-4.2,14.78-11.07,17.2c-0.31,0.11-0.66-0.01-0.83-0.29l-1.09-1.75
							c-1.16,2.56-2.2,4.86-3.23,7.13c2.48,1.13,4.8,2.17,7.36,3.34l-0.6-2.06c-0.1-0.33,0.07-0.69,0.38-0.83
							C93.42,66.73,97.05,54.55,94.94,45.44z M57.2,13.4c-3.71,0.06-7.42,0.06-11.13,0c-0.09,0-0.17-0.03-0.26-0.07
							c-0.61-0.29-0.5-1.22,0.17-1.32c0.03,0,0.06-0.01,0.09-0.01c1.85-0.06,3.71-0.03,5.56-0.03c1.85,0,3.71-0.04,5.56,0.03
							c0,0,0.01,0,0.01,0c0.75,0.04,0.88,1.1,0.18,1.36C57.33,13.38,57.27,13.4,57.2,13.4z"/>
						<path className={style?.className} d="M72.64,74.1l-0.03,9.54H30.59l0.18-49.94l-4.21,9.48c0.01,9.58-0.18,15.99-0.19,25.57
							c-9.19-2.47-12.66-7.75-13.12-15.92C12.81,45,17.7,37.67,24.86,35.44c0.44,0.7,0.88,1.41,1.38,2.22
							c1.16-2.56,2.2-4.86,3.23-7.13c-2.48-1.13-4.8-2.17-7.36-3.34c0.32,1.09,0.52,1.78,0.77,2.65
							C9.78,35.34,6.01,47.74,8.16,56.98c2.1,9.05,9.81,16.12,18.21,17.52c-0.01,4.11-0.02,8.22-0.03,12.32c0,0.04,0,0.07,0,0.11
							c0.01,4.22,3.47,7.63,7.69,7.63h35.22c4.22,0,7.68-3.41,7.69-7.63c0-0.04,0-0.07,0-0.11c-0.01-4.18,0.01-6.6,0-10.77
							L72.64,74.1z M51.65,91.64c-1.63,0-2.9-1.28-2.9-2.94c0-1.61,1.32-2.97,2.89-2.98c1.58,0,2.89,1.35,2.9,2.96
							C54.54,90.35,53.28,91.64,51.65,91.64z"/>
					</g>
				</g>
				<path className={style?.className} d="M69.69,52.87c0-0.56-0.33-0.88-0.89-0.9c-0.3-0.01-0.6,0-0.92,0c-0.01-0.18-0.02-0.32-0.02-0.46
					c0-1.57,0.01-3.14,0-4.71c-0.02-2.13-1.55-3.63-3.67-3.64c-0.34,0-0.68,0-1.07,0c0.1-0.2,0.16-0.34,0.24-0.48
					c1.03-1.96,2.07-3.92,3.1-5.87c0.93-1.76,0.43-3.35-1.32-4.28c-2.82-1.49-5.64-2.99-8.47-4.46c-1.83-0.95-3.69-1.86-5.54-2.78
					c-0.32,0-0.63,0-0.95,0c-1.2,0.29-1.95,1.07-2.44,2.17c-0.29,0.65-0.67,1.27-1,1.91c-2.36,4.46-4.72,8.92-7.06,13.39
					c-0.17,0.32-0.35,0.43-0.69,0.41c-0.49-0.03-0.98-0.01-1.47-0.01c-2.26,0-3.75,1.5-3.75,3.77c0,6.28,0,12.55,0,18.83
					c0,2.26,1.51,3.76,3.77,3.76c8.85,0,17.71,0,26.56,0c2.28,0,3.78-1.51,3.78-3.79c0-1.51,0-3.02,0-4.53c0-0.15,0-0.3,0-0.48
					c0.35,0,0.64,0.01,0.92,0c0.57-0.02,0.91-0.34,0.91-0.92C69.7,57.48,69.7,55.17,69.69,52.87z M66.13,51.45c0,0.15,0,0.31,0,0.52
					c-1.21,0-2.35-0.02-3.49,0c-2.42,0.05-4.31,2.05-4.26,4.46c0.05,2.31,1.95,4.22,4.26,4.26c1.01,0.02,2.01,0,3.02,0.01
					c0.14,0,0.28,0,0.45,0c0.01,0.18,0.02,0.32,0.02,0.46c0,0.32,0,0.64,0,0.96c-6.5,1.63-11.06-2.55-9.4-7.65
					c1.63-4.98,9.04-4.25,9.39-4.22C66.13,50.66,66.13,51.05,66.13,51.45z M59.04,38.77c0.03,0.03,0.05,0.07,0.09,0.13
					c-0.72,1.36-1.44,2.74-2.17,4.1c-0.05,0.09-0.2,0.16-0.3,0.16c-3.62,0.01-7.25,0.01-10.97,0.01c1.58-2.99,3.13-5.93,4.67-8.85
					c0.68,0,1.34,0.07,1.98-0.02c0.82-0.1,1.52-0.52,2.09-1.12c0.18-0.19,0.32-0.22,0.55-0.09c0.84,0.46,1.7,0.91,2.55,1.35
					c0.19,0.1,0.29,0.18,0.24,0.42C57.47,36.39,57.91,37.69,59.04,38.77z M41.65,42.69c2.6-4.93,5.21-9.86,7.81-14.8
					c0.49-0.93,1.06-1.1,1.98-0.62c4.27,2.25,8.55,4.49,12.82,6.74c0.99,0.52,1.15,1.05,0.64,2.03c-1.19,2.25-2.38,4.5-3.56,6.75
					c-0.14,0.27-0.29,0.38-0.59,0.36c-0.6-0.02-1.21-0.01-1.89-0.01c0.07-0.16,0.12-0.29,0.19-0.4c0.62-1.18,1.25-2.37,1.87-3.55
					c0.38-0.71,0.29-1.05-0.38-1.48c-1.05-0.68-1.41-1.87-0.93-3.02c0.29-0.68,0.17-1.04-0.48-1.38c-1.4-0.74-2.8-1.47-4.2-2.21
					c-0.63-0.33-1-0.22-1.38,0.37c-0.71,1.09-1.88,1.45-3.08,0.95c-0.65-0.27-1.01-0.15-1.34,0.48c-1.73,3.28-3.47,6.56-5.19,9.84
					c-0.15,0.29-0.31,0.42-0.66,0.4c-0.6-0.03-1.2-0.01-1.85-0.01C41.51,42.97,41.57,42.83,41.65,42.69z M37.58,44.89
					c0.31,0,0.62,0,0.93,0l-1.97-0.16C36.79,45.17,37.17,44.89,37.58,44.89z M35.48,55.8l-0.5,10.17c-0.21-0.33,0.5,0.26,0.5-0.26
					C35.48,62.41,35.48,59.1,35.48,55.8z M67.96,58.94c-0.09,0.01-0.17,0.03-0.25,0.03c-1.65,0-3.31,0.01-4.96,0
					c-1.47-0.01-2.63-1.19-2.63-2.64c0-1.43,1.14-2.61,2.59-2.63c1.74-0.02,3.48-0.01,5.26-0.01C67.96,55.48,67.96,57.2,67.96,58.94
					z"/>
	</g>
</svg>
    )
}

export const icon_transferencia = (style) =>{
    return (
        // <img src={transferencia} style={style?.size ? {width:`${style?.size}px`, height:`${style?.size}px`} : {width:"25px", height:"25px"}} alt="transferencia" />
        <svg
        className={style?.className}
	 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"
	 style={{enableBackground:"new 0 0 100 100"}} xmlSpace="preserve">
<switch>
	<g>
		<g>
			<path  className={style?.className} d="M62.34,13.66c19.19,5.69,30.95,24.49,28.49,43.74c-0.13,1.01,0.52,1.96,1.5,2.25l1.7,0.5
				c1.59,0.47,2.04,2.51,0.8,3.61L83.45,73.81c-1.17,1.03-3.02,0.48-3.43-1.02l-4.05-14.63c-0.44-1.6,1.05-3.06,2.63-2.59l0.31,0.09
				c1.3,0.39,2.64-0.53,2.71-1.88c0.67-13.85-8.11-26.92-21.98-31.04c-11.03-3.27-22.47-0.05-30.21,7.43
				c-1.15,1.11-3.06,0.59-3.49-0.95l-1.55-5.62c-0.22-0.79,0.03-1.65,0.66-2.17C35.06,13.07,48.92,9.68,62.34,13.66z"/>
			<path  className={style?.className} d="M10.42,44.13l-3.05-0.9c-1.19-0.35-1.53-1.88-0.6-2.71l12.71-11.23c0.88-0.77,2.26-0.36,2.58,0.76
				l4.53,16.35c0.33,1.2-0.78,2.3-1.98,1.94l-1.85-0.55c-0.97-0.29-1.97,0.4-2.04,1.41c-0.99,14.09,7.85,27.52,21.94,31.7
				c11.31,3.36,23.05-0.11,30.78-8c0.86-0.88,2.35-0.53,2.68,0.66l1.88,6.8c0.16,0.59-0.02,1.23-0.49,1.63
				C67.49,90.53,53.51,94.02,39.97,90c-19.31-5.73-31.1-24.73-28.44-44.11C11.64,45.1,11.18,44.36,10.42,44.13z"/>
			<g>
				<path  className={style?.className} d="M30.86,60.33c-1.53-3.84-1.69-8.03-0.45-11.2l0.79-2.02l-1.33,1.72c-0.7,0.91-1.2,2.04-1.49,3.36
					c-0.58,2.66-0.25,5.77,0.94,8.76c1.69,4.27,5.1,7.63,8.48,8.35l2.4,0.51l-2.2-1.1C35.03,67.23,32.36,64.1,30.86,60.33z"/>
				<g>
					<path  className={style?.className} d="M70.31,35.22l-1.14-1.61l0.59,1.88c0.22,0.72,0.38,1.48,0.46,2.26c0.35,3.19-0.42,6.78-2.17,10.1
						c-2.18,4.14-5.64,7.28-9.25,8.38l-1.87,0.57l2.05,0.04c3.87,0,8.2-3.26,10.75-8.11C72.33,43.83,72.56,38.4,70.31,35.22z"/>
					<path  className={style?.className} d="M68.72,32.09l-2.83-1.49c-1.03-0.54-2.16-0.82-3.38-0.82c-4.35,0-9.13,3.54-11.9,8.8
						c-2.19,4.13-2.83,8.74-1.73,12.31c0.68,2.21,1.98,3.88,3.76,4.82l2.55,1.35l0.05-0.1l0.27,0.27c1.02,0.53,2.14,0.79,3.35,0.79
						c4.35,0,9.13-3.54,11.9-8.8C74.41,42.3,73.5,34.61,68.72,32.09z M54.86,55.11c-0.47-0.09-0.92-0.23-1.32-0.41l-0.06,0.11
						l-0.27-0.27c-0.02-0.01-0.05-0.03-0.07-0.04c-0.81-0.47-1.45-1.06-1.97-1.81c-0.45-0.64-0.8-1.37-1.05-2.17
						c-1-3.25-0.39-7.49,1.64-11.32c2.55-4.85,6.87-8.11,10.75-8.11l0.09,0c0.89,0.01,1.73,0.2,2.5,0.57
						c0.11,0.05,0.15,0.07,0.2,0.09c0.5,0.26,0.96,0.61,1.38,1.02c1.18,1.18,1.92,2.91,2.15,4.99c0.32,2.94-0.4,6.26-2.03,9.35
						c-2.56,4.85-6.88,8.11-10.75,8.11C55.66,55.23,55.28,55.19,54.86,55.11z M69.61,48.62c-2.56,4.85-6.88,8.11-10.75,8.11
						l-2.05-0.04l1.87-0.57c3.61-1.1,7.07-4.24,9.25-8.38c1.75-3.32,2.52-6.9,2.17-10.1c-0.09-0.79-0.24-1.55-0.46-2.26l-0.59-1.88
						l1.14,1.61C72.43,38.28,72.2,43.71,69.61,48.62z"/>
				</g>
				<path  className={style?.className} d="M36.23,44.78c-1.05,0-2.05,0.23-2.96,0.68l-0.28,0.14l0,0l-2.38,1.17c-2.23,1.09-3.68,3.38-4.11,6.43
					c-0.41,2.96,0.22,6.3,1.75,9.42c2.49,5.07,6.92,8.48,11.03,8.48c1.06,0,2.05-0.23,2.96-0.67l2.66-1.31
					c2.23-1.09,3.68-3.38,4.11-6.43c0.41-2.95-0.21-6.3-1.75-9.41C44.76,48.19,40.33,44.78,36.23,44.78z M38.54,69.7
					c-3.43-0.46-7.09-3.54-9.11-7.67c-1.42-2.89-2-5.97-1.63-8.67c0.18-1.34,0.59-2.51,1.22-3.47l1.19-1.82l-0.63,2.08
					c-0.99,3.26-0.49,7.43,1.33,11.14c1.79,3.64,4.7,6.56,7.79,7.8l2.28,0.92L38.54,69.7z M47.71,62.5
					c-0.18,1.33-0.59,2.5-1.22,3.46c-0.5,0.76-1.13,1.37-1.87,1.8l-0.09,0.06c-0.07,0.04-0.14,0.09-0.21,0.12
					c-0.45,0.22-0.96,0.38-1.5,0.46c-0.33,0.05-0.61,0.08-0.88,0.08c-3.56,0-7.61-3.19-9.86-7.75c-2.47-5.03-2.28-10.57,0.45-13.18
					c0.32-0.3,0.67-0.56,1.04-0.78l0-0.01l0.28-0.14c0.08-0.04,0.16-0.07,0.24-0.1l0.08-0.03c0.84-0.34,1.75-0.46,2.69-0.37
					c3.43,0.34,7.14,3.44,9.24,7.72C47.51,56.72,48.08,59.8,47.71,62.5z"/>
				<g>
					<path  className={style?.className} d="M58.61,39.56c-0.31,0.11-0.6,0.32-0.89,0.63c-0.28,0.3-0.54,0.69-0.78,1.16
						c-0.51,1.01-0.72,1.95-0.64,2.81c0.07,0.71,0.33,1.31,0.77,1.81l2.9-6.3c-0.14-0.08-0.29-0.13-0.44-0.17
						C59.23,39.43,58.92,39.45,58.61,39.56z"/>
					<path  className={style?.className} d="M58.95,44.74l-0.92,2c0.11,0.06,0.21,0.11,0.32,0.16c0.33,0.13,0.68,0.22,1.06,0.27l0.7-1.4l-1.04-0.91
						C59.03,44.81,58.99,44.77,58.95,44.74z"/>
					<path  className={style?.className} d="M68.81,37.77c-0.23-2.09-0.97-3.82-2.15-4.99c-0.42-0.42-0.88-0.76-1.38-1.02
						c-0.04-0.02-0.09-0.04-0.2-0.09c-0.77-0.37-1.61-0.56-2.5-0.57l-0.09,0c-3.88,0-8.2,3.26-10.75,8.11
						c-2.03,3.83-2.64,8.06-1.64,11.32c0.25,0.8,0.6,1.53,1.05,2.17c0.52,0.75,1.16,1.35,1.97,1.81c0.02,0.01,0.05,0.03,0.07,0.04
						l0.27,0.27l0.06-0.11c0.41,0.18,0.85,0.32,1.32,0.41c0.42,0.08,0.8,0.12,1.17,0.12c3.87,0,8.19-3.26,10.75-8.11
						C68.41,44.03,69.13,40.71,68.81,37.77z M62.79,43.37c-0.11,0.22-0.27,0.35-0.47,0.4c-0.2,0.05-0.39-0.01-0.57-0.16
						c-0.1-0.08-0.17-0.2-0.23-0.33c-0.06-0.14-0.09-0.29-0.1-0.44c0-0.48-0.01-0.86-0.04-1.14c-0.03-0.27-0.11-0.55-0.25-0.84
						c-0.06-0.13-0.14-0.25-0.23-0.37l-1.34,2.92c0,0,0,0,0,0l1.52,1.34c0.19,0.16,0.33,0.32,0.44,0.48
						c0.11,0.16,0.16,0.35,0.17,0.56c0.01,0.22-0.07,0.48-0.22,0.78l-0.83,1.66c-0.11,0.22-0.21,0.38-0.31,0.48
						c-0.1,0.1-0.22,0.17-0.36,0.2c-0.14,0.03-0.31,0.03-0.5,0.01c-0.54-0.07-1.03-0.2-1.47-0.4c-0.21-0.1-0.43-0.21-0.64-0.35
						l-0.34,0.73c-0.14,0.3-0.49,0.43-0.78,0.29c-0.2-0.09-0.33-0.29-0.34-0.49c-0.01-0.1,0.01-0.2,0.05-0.29l0.47-1.01
						c-0.38-0.38-0.71-0.81-0.96-1.27c-0.32-0.57-0.53-1.18-0.63-1.83c-0.1-0.65-0.09-1.32,0.03-2.01c0.12-0.69,0.35-1.38,0.69-2.06
						c0.34-0.67,0.72-1.22,1.15-1.64c0.43-0.42,0.9-0.7,1.39-0.83c0.49-0.13,1.01-0.11,1.54,0.06c0.32,0.1,0.65,0.26,0.98,0.48
						l0.33-0.72c0.14-0.3,0.49-0.43,0.78-0.29c0.3,0.14,0.43,0.49,0.29,0.78l-0.46,1c0.3,0.31,0.56,0.64,0.76,0.98
						c0.27,0.46,0.45,0.9,0.54,1.32c0.1,0.42,0.13,0.81,0.11,1.17C62.95,42.89,62.89,43.17,62.79,43.37z"/>
				</g>
				<g>
					<path  className={style?.className} d="M36.8,54.89c-0.17,0.35-0.26,0.72-0.27,1.11c-0.01,0.39,0.05,0.8,0.2,1.22c0.15,0.42,0.37,0.83,0.67,1.24
						c0.64,0.88,1.33,1.4,2.05,1.54c0.59,0.12,1.17-0.01,1.72-0.39l-4.08-5.18C36.98,54.57,36.88,54.73,36.8,54.89z"/>
					<path  className={style?.className} d="M40.95,56.95c-0.05,0.04-0.09,0.08-0.13,0.11l1.3,1.64c0.08-0.11,0.16-0.22,0.24-0.33
						c0.22-0.34,0.41-0.73,0.58-1.16l-0.89-1.22L40.95,56.95z"/>
					<path  className={style?.className} d="M46.08,53.84c-2.1-4.28-5.81-7.38-9.24-7.72c-0.94-0.09-1.85,0.03-2.69,0.37l-0.08,0.03
						c-0.08,0.03-0.16,0.06-0.24,0.1l-0.28,0.14l0,0.01c-0.38,0.21-0.73,0.47-1.04,0.78c-2.73,2.61-2.92,8.15-0.45,13.18
						c2.25,4.56,6.3,7.75,9.86,7.75c0.27,0,0.55-0.02,0.88-0.08c0.54-0.09,1.05-0.24,1.5-0.46c0.07-0.04,0.14-0.08,0.21-0.12
						l0.09-0.06c0.74-0.43,1.37-1.04,1.87-1.8c0.62-0.96,1.03-2.12,1.22-3.46C48.08,59.8,47.51,56.72,46.08,53.84z M44.51,57.03
						c-0.03,0.17-0.08,0.37-0.16,0.58c-0.24,0.62-0.51,1.15-0.82,1.61c-0.15,0.22-0.32,0.44-0.5,0.65l0.48,0.6
						c0.19,0.24,0.18,0.69-0.03,1c-0.14,0.21-0.34,0.3-0.51,0.26c-0.08-0.02-0.15-0.07-0.22-0.14l-0.66-0.83
						c-0.44,0.34-0.89,0.6-1.35,0.77c-0.57,0.21-1.13,0.28-1.69,0.22c-0.56-0.07-1.09-0.27-1.61-0.6c-0.52-0.34-0.99-0.8-1.43-1.4
						c-0.43-0.58-0.74-1.19-0.93-1.82c-0.19-0.63-0.26-1.25-0.19-1.86c0.07-0.61,0.26-1.21,0.58-1.78c0.19-0.35,0.43-0.69,0.72-1.01
						l-0.47-0.59c-0.19-0.24-0.18-0.69,0.03-1c0.21-0.31,0.54-0.36,0.73-0.12l0.65,0.83c0.35-0.27,0.7-0.47,1.05-0.61
						c0.46-0.18,0.88-0.27,1.25-0.26c0.37,0.01,0.7,0.08,0.98,0.2c0.28,0.13,0.49,0.28,0.62,0.46c0.14,0.19,0.19,0.41,0.16,0.66
						c-0.03,0.25-0.14,0.46-0.32,0.62c-0.1,0.09-0.22,0.15-0.35,0.18c-0.13,0.03-0.26,0.03-0.39-0.01c-0.39-0.14-0.7-0.23-0.93-0.28
						c-0.23-0.04-0.48-0.03-0.76,0.05c-0.12,0.03-0.25,0.09-0.38,0.16l1.89,2.4c0,0,0,0,0,0l1.6-1.41c0.2-0.17,0.38-0.3,0.54-0.38
						c0.16-0.08,0.33-0.1,0.51-0.04c0.18,0.06,0.36,0.21,0.55,0.47l1.05,1.44c0.14,0.19,0.23,0.36,0.28,0.5
						C44.53,56.7,44.54,56.86,44.51,57.03z"/>
				</g>
			</g>
		</g>
	</g>
</switch>
</svg>
    )
}

export const icon_tarifario = (style) =>{
	return(
	<svg version="1.1" id="Capa_1"
	 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"
	 style={{enableBackground:"new 0 0 100 100"}} xmlSpace="preserve" className={style?.className}>
			<g>
				<g>
					<path d="M87.29,6.21H19.96c-1.74,0-3.12,1.41-3.12,3.12v83.55c0,1.71,1.41,3.12,3.12,3.12h67.3
						c1.74,0,3.12-1.41,3.12-3.12V9.36C90.41,7.62,89,6.21,87.29,6.21z M85.58,90.1c0,0.58-0.49,1.07-1.07,1.07H22.8
						c-0.58,0-1.07-0.49-1.07-1.07V12.15c0-0.58,0.49-1.07,1.07-1.07h61.71c0.58,0,1.07,0.49,1.07,1.07V90.1z"/>
				</g>
				<g>
					<path d="M23.84,17.13v67.99c0,2.16,1.75,3.91,3.91,3.91H79.5c2.16,0,3.91-1.75,3.91-3.91V17.13
						c0-2.16-1.75-3.91-3.91-3.91H27.75C25.59,13.22,23.84,14.97,23.84,17.13z M78.6,80.37c0,0.58-0.49,1.07-1.07,1.07H42.78
						c-0.71,0-1.28-0.58-1.28-1.28v-6.24c0-0.71,0.58-1.28,1.28-1.28h34.54c0.71,0,1.28,0.58,1.28,1.28V80.37L78.6,80.37z
						 M77.6,54.79c0,0.58-0.49,1.07-1.07,1.07H41.78c-0.71,0-1.28-0.58-1.28-1.28v-6.24c0-0.71,0.58-1.28,1.28-1.28h34.54
						c0.71,0,1.28,0.58,1.28,1.28V54.79L77.6,54.79z M78.6,26.98c0,0.58-0.49,1.07-1.07,1.07H42.78c-0.71,0-1.28-0.58-1.28-1.28
						v-6.21c0-0.71,0.58-1.28,1.28-1.28h34.54c0.71,0,1.28,0.58,1.28,1.28V26.98L78.6,26.98z"/>
				</g>
				<g>
					<g>
						<g>
							<path d="M45.08,21.42h29.94c0.8,0,1.44,0.65,1.44,1.44v1.64c0,0.8-0.65,1.44-1.44,1.44H45.08
								c-0.8,0-1.44-0.65-1.44-1.44v-1.64C43.64,22.06,44.28,21.42,45.08,21.42z"/>
						</g>
					</g>
					<g>
						<g>
							<path d="M44.08,49.22h29.94c0.8,0,1.44,0.65,1.44,1.44v1.64c0,0.8-0.65,1.44-1.44,1.44H44.08
								c-0.8,0-1.44-0.65-1.44-1.44v-1.64C42.64,49.87,43.28,49.22,44.08,49.22z"/>
						</g>
					</g>
					<g>
						<g>
							<path d="M45.08,74.8h29.94c0.8,0,1.44,0.65,1.44,1.44v1.64c0,0.8-0.65,1.44-1.44,1.44H45.08
								c-0.8,0-1.44-0.65-1.44-1.44v-1.64C43.64,75.45,44.28,74.8,45.08,74.8z"/>
						</g>
					</g>
				</g>
			</g>
			<g>
				<g>
					<path class="st5" d="M25.51,23.54v-0.03c0-3.36,2.62-6.11,6.19-6.11c1.79,0,2.97,0.41,4.03,1.15c0.25,0.19,0.54,0.54,0.54,1.05
						c0,0.71-0.57,1.28-1.28,1.28c-0.37,0-0.61-0.14-0.79-0.27c-0.71-0.51-1.47-0.81-2.58-0.81c-1.89,0-3.39,1.67-3.39,3.68v0.03
						c0,2.16,1.49,3.75,3.58,3.75c0.94,0,1.79-0.24,2.45-0.71v-1.69h-1.75c-0.62,0-1.13-0.49-1.13-1.11c0-0.62,0.51-1.13,1.13-1.13
						h2.97c0.73,0,1.3,0.57,1.3,1.3v2.87c0,0.76-0.29,1.28-0.91,1.65c-0.96,0.59-2.36,1.18-4.13,1.18
						C28.04,29.62,25.51,27.03,25.51,23.54z"/>
				</g>
				<path class="st6" d="M31.18,31.66h-0.11c-0.36,0-0.65-0.29-0.65-0.65V16.83c0-0.36,0.29-0.65,0.65-0.65h0.11
					c0.36,0,0.65,0.29,0.65,0.65v14.18C31.83,31.37,31.54,31.66,31.18,31.66z"/>
			</g>
			<g>
				<g>
					<path class="st5" d="M25.84,50.32v-0.03c0-3.36,2.62-6.11,6.19-6.11c1.79,0,2.97,0.41,4.03,1.15c0.25,0.19,0.54,0.54,0.54,1.05
						c0,0.71-0.57,1.28-1.28,1.28c-0.37,0-0.61-0.14-0.79-0.27c-0.71-0.51-1.47-0.81-2.58-0.81c-1.89,0-3.39,1.67-3.39,3.68v0.03
						c0,2.16,1.49,3.75,3.58,3.75c0.94,0,1.79-0.24,2.45-0.71v-1.69h-1.75c-0.62,0-1.13-0.49-1.13-1.11c0-0.62,0.51-1.13,1.13-1.13
						h2.97c0.73,0,1.3,0.57,1.3,1.3v2.87c0,0.76-0.29,1.28-0.91,1.65c-0.96,0.59-2.36,1.18-4.13,1.18
						C28.37,56.4,25.84,53.82,25.84,50.32z"/>
				</g>
				<path class="st6" d="M31.52,58.44h-0.11c-0.36,0-0.65-0.29-0.65-0.65V43.61c0-0.36,0.29-0.65,0.65-0.65h0.11
					c0.36,0,0.65,0.29,0.65,0.65V57.8C32.17,58.15,31.88,58.44,31.52,58.44z"/>
			</g>
			<g>
				<g>
					<path class="st5" d="M26.75,77.36v-0.03c0-3.36,2.62-6.11,6.19-6.11c1.79,0,2.97,0.41,4.03,1.15c0.25,0.19,0.54,0.54,0.54,1.05
						c0,0.71-0.57,1.28-1.28,1.28c-0.37,0-0.61-0.14-0.79-0.27c-0.71-0.51-1.47-0.81-2.58-0.81c-1.89,0-3.39,1.67-3.39,3.68v0.03
						c0,2.16,1.49,3.75,3.58,3.75c0.94,0,1.79-0.24,2.45-0.71v-1.69h-1.75c-0.62,0-1.13-0.49-1.13-1.11c0-0.62,0.51-1.13,1.13-1.13
						h2.97c0.73,0,1.3,0.57,1.3,1.3v2.87c0,0.76-0.29,1.28-0.91,1.65c-0.96,0.59-2.36,1.18-4.13,1.18
						C29.28,83.43,26.75,80.85,26.75,77.36z"/>
				</g>
				<path class="st6" d="M32.42,85.48h-0.11c-0.36,0-0.65-0.29-0.65-0.65V70.65c0-0.36,0.29-0.65,0.65-0.65h0.11
					c0.36,0,0.65,0.29,0.65,0.65v14.18C33.07,85.19,32.78,85.48,32.42,85.48z"/>
			</g>
	</svg>)
}

export const icon_dimo = (style) =>{
	return(
		<svg version="1.1" id="Capa_1"
	 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"
	 style={{enableBackground:"new 0 0 100 100;"}} xmlSpace="preserve"
	 className={style?.className}
	 >
		<g>
			<path className={style?.className} d="M20.78,93.5c-0.33,0.02-0.66,0.04-0.98,0.04c-3.45,0-6.81-1.31-9.35-3.67c-1.9-1.77-3.23-4.06-3.89-6.54
				c-0.3-1.13-0.47-2.29-0.47-3.47V41.38c0-10.24,8.3-18.54,18.54-18.54l0.13,0.02c-1.43-0.11-2.68,1.02-2.68,2.48V74.5
				c0,0.07,0.02,0.14,0.02,0.22l-0.02,0.02c0,8.43,6.83,15.26,15.26,15.26c1.42,0,2.79-0.2,4.09-0.56
				C35.1,91.56,28.15,92.97,20.78,93.5z"/>
			<path className={style?.className} d="M93.29,42.85c-0.9,0.82-2.29,0.76-3.11-0.13c-2.81-3.06-5.24-5.71-10.88-6.38
				c-1.93-0.23-3.48-0.07-4.91,0.23c0.97,2.34,1.64,4.76,2.05,7.23c0.63,0.02,1.27,0.04,1.95,0.12c7.26,0.87,10.63,4.54,13.6,7.78
				c0.82,0.9,0.76,2.29-0.13,3.11c-0.9,0.82-2.29,0.76-3.11-0.13c-2.81-3.06-5.24-5.71-10.87-6.38c-0.37-0.04-0.65,0.01-1-0.01
				c0,0.21,0.04,0.42,0.04,0.63c0,2.35-0.29,4.66-0.79,6.92c0.29,0.02,0.55,0.02,0.84,0.05c7.26,0.87,10.63,4.54,13.6,7.78
				c0.82,0.9,0.76,2.29-0.13,3.11c-0.9,0.82-2.29,0.76-3.11-0.13c-2.81-3.06-5.24-5.71-10.88-6.38c-0.62-0.07-1.1-0.01-1.66-0.02
				c-4.72,12.42-16.99,22.71-33.42,28.2c-1.3,0.36-2.67,0.56-4.09,0.56c-8.43,0-15.26-6.83-15.26-15.26l0.02-0.02
				c0.12,1.41,1.4,2.46,2.83,2.26c8.91-1.3,16.85-4.15,23.01-8c-6.24-1.16-9.38-4.51-12.14-7.52c-0.82-0.9-0.76-2.29,0.13-3.11
				c0.9-0.82,2.29-0.76,3.11,0.13c2.81,3.06,5.24,5.71,10.88,6.38c1.28,0.15,2.39,0.13,3.44,0.04c2.86-2.6,5.01-5.48,6.29-8.53
				c-2.39,0.87-5.21,1.34-8.81,0.91c-7.26-0.87-10.63-4.54-13.6-7.78c-0.82-0.9-0.76-2.29,0.13-3.11c0.9-0.82,2.29-0.76,3.11,0.13
				c2.81,3.06,5.24,5.71,10.87,6.38c4.26,0.51,7.01-0.5,9.58-2.03c0.02-0.32,0.07-0.63,0.07-0.95c0-1.77-0.33-3.51-0.9-5.21
				c-2.18,0.66-4.71,0.98-7.84,0.61c-7.26-0.87-10.63-4.54-13.6-7.78c-0.82-0.9-0.76-2.29,0.13-3.11c0.9-0.82,2.29-0.76,3.11,0.13
				c2.81,3.06,5.24,5.71,10.88,6.38c2.14,0.26,3.82,0.05,5.37-0.34c-5.09-8.18-16.57-14.78-30.86-17.35
				c-0.05-0.01-0.09-0.02-0.14-0.03l-2.4-0.37l-0.13-0.02C14.3,21.83,6,30.13,6,40.37V18.97c0-3.78,1.59-7.43,4.36-10
				c2.78-2.58,6.55-3.91,10.33-3.64c23.78,1.72,43.2,12.49,51.65,27.13c2.1-0.59,4.53-0.85,7.49-0.5c7.26,0.87,10.63,4.54,13.6,7.78
				C94.24,40.64,94.18,42.03,93.29,42.85z"/>
		</g>
</svg>
	)
}

export const icon_banco = (style) =>{
	return(
		<svg version="1.1" id="Capa_1"
	 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"
	 style={{enableBackground:"new 0 0 100 100;"}} xmlSpace="preserve"
	 className={style?.className}
	 >
			<g>
				<g>
					<g>
						<path className={style?.className} d="M91.51,31.13H7.92c-0.66,0-1.18-0.54-1.18-1.18V24.4c0-0.45,0.24-0.84,0.63-1.06l41.81-21.9
							c0.33-0.18,0.75-0.18,1.12,0l41.81,21.9c0.39,0.21,0.63,0.6,0.63,1.06v5.55C92.72,30.61,92.18,31.13,91.51,31.13z M9.12,28.74
							h81.21v-3.62L49.73,3.85L9.12,25.12V28.74z"/>
						<path className={style?.className} d="M91.51,31.54H7.92c-0.88,0-1.59-0.71-1.59-1.59V24.4c0-0.6,0.32-1.13,0.85-1.42L49,1.07
							c0.45-0.24,1.01-0.25,1.49,0l41.82,21.91c0.54,0.29,0.86,0.82,0.86,1.42v5.55C93.12,30.85,92.4,31.54,91.51,31.54z
							 M49.74,1.72c-0.12,0-0.25,0.03-0.35,0.09L7.57,23.71c-0.26,0.14-0.41,0.39-0.41,0.69v5.55c0,0.42,0.34,0.76,0.76,0.76h83.59
							c0.44,0,0.8-0.34,0.82-0.78l0-5.53c0-0.3-0.15-0.55-0.41-0.69L50.11,1.81C49.99,1.75,49.86,1.72,49.74,1.72z M90.75,29.16
							H8.71v-4.29l0.22-0.12l40.8-21.37l0.19,0.1l40.83,21.38V29.16z M9.54,28.33h80.38v-2.95L49.73,4.32L9.54,25.37V28.33z"/>
					</g>
				</g>
				<g>
					<g>
						<path className={style?.className} d="M94.41,41.52H5.05c-0.66,0-1.18-0.63-1.18-1.36v-9.93c0-0.77,0.54-1.36,1.18-1.36h89.36
							c0.66,0,1.18,0.63,1.18,1.36v9.93C95.59,40.89,95.07,41.52,94.41,41.52z M6.23,38.77H93.2v-7.18H6.23V38.77z"/>
						<path className={style?.className} d="M94.41,41.97H5.05c-0.89,0-1.62-0.81-1.62-1.81v-9.93c0-1,0.73-1.81,1.62-1.81h89.36
							c0.89,0,1.62,0.81,1.62,1.81v9.93C96.03,41.16,95.3,41.97,94.41,41.97z M5.05,29.31c-0.4,0-0.73,0.41-0.73,0.91v9.93
							c0,0.5,0.33,0.91,0.73,0.91h89.36c0.4,0,0.73-0.41,0.73-0.91v-9.93c0-0.5-0.33-0.91-0.73-0.91H5.05z M93.65,39.21H5.78v-8.07
							h87.87V39.21z M6.67,38.32h86.08v-6.29H6.67V38.32z"/>
					</g>
				</g>
				<g>
					<g>
						<path className={style?.className} d="M94.41,96.24H5.05c-0.66,0-1.18-0.54-1.18-1.18v-8.6c0-0.66,0.54-1.18,1.18-1.18h89.36
							c0.66,0,1.18,0.54,1.18,1.18v8.6C95.59,95.7,95.07,96.24,94.41,96.24z M6.23,93.86H93.2v-6.21H6.23V93.86z"/>
						<path className={style?.className} d="M94.41,96.66H5.05c-0.88,0-1.59-0.71-1.59-1.59v-8.6c0-0.88,0.71-1.59,1.59-1.59h89.36
							c0.88,0,1.59,0.71,1.59,1.59v8.6C96,95.95,95.29,96.66,94.41,96.66z M5.05,85.71c-0.42,0-0.76,0.34-0.76,0.76v8.6
							c0,0.42,0.34,0.76,0.76,0.76h89.36c0.42,0,0.76-0.34,0.76-0.76v-8.6c0-0.42-0.34-0.76-0.76-0.76H5.05z M93.62,94.28H5.81
							v-7.04h87.8V94.28z M6.64,93.45h86.14v-5.38H6.64V93.45z"/>
					</g>
				</g>
				<g>
					<g>
						<path className={style?.className} d="M22.01,51.39h-8.69c-2.78,0-5.01-2.26-5.01-5.01v-3.83c0-0.66,0.54-1.18,1.18-1.18h16.35
							c0.66,0,1.18,0.54,1.18,1.18v3.83C27.01,49.12,24.78,51.39,22.01,51.39z M10.66,43.75v2.62c0,1.45,1.18,2.62,2.62,2.62h8.69
							c1.45,0,2.62-1.18,2.62-2.62v-2.62H10.66z"/>
						<path className={style?.className} d="M22.01,51.8h-8.69c-2.99,0-5.42-2.43-5.42-5.42v-3.83c0-0.88,0.71-1.59,1.59-1.59h16.35
							c0.88,0,1.59,0.71,1.59,1.59v3.83C27.43,49.37,25,51.8,22.01,51.8z M9.49,41.79c-0.42,0-0.76,0.34-0.76,0.76v3.83
							c0,2.53,2.06,4.59,4.59,4.59h8.69c2.53,0,4.59-2.06,4.59-4.59v-3.83c0-0.42-0.34-0.76-0.76-0.76H9.49z M21.98,49.42h-8.69
							c-1.68,0-3.04-1.36-3.04-3.04v-3.04h14.77v3.04C25.02,48.05,23.65,49.42,21.98,49.42z M11.08,44.17v2.21
							c0,1.22,0.99,2.21,2.21,2.21h8.69c1.22,0,2.21-0.99,2.21-2.21v-2.21H11.08z"/>
					</g>
				</g>
				<g>
					<g>
						<path className={style?.className} d="M22.13,87.65h-8.96c-0.66,0-1.18-0.54-1.18-1.18V50.21c0-0.66,0.54-1.18,1.18-1.18h8.96
							c0.66,0,1.18,0.54,1.18,1.18v36.26C23.33,87.1,22.79,87.65,22.13,87.65z M14.34,85.26h6.58V51.39h-6.58V85.26z"/>
						<path className={style?.className} d="M22.13,88.06h-8.96c-0.88,0-1.59-0.71-1.59-1.59V50.21c0-0.88,0.71-1.59,1.59-1.59h8.96
							c0.88,0,1.59,0.71,1.59,1.59v36.26c0.02,0.39-0.13,0.79-0.42,1.09C22.99,87.88,22.57,88.06,22.13,88.06z M13.17,49.45
							c-0.42,0-0.76,0.34-0.76,0.76v36.26c0,0.42,0.34,0.76,0.76,0.76h8.96c0.21,0,0.42-0.09,0.57-0.24c0.13-0.14,0.2-0.32,0.19-0.5
							l0-36.28c0-0.42-0.34-0.76-0.76-0.76H13.17z M21.33,85.68h-7.41V50.97h7.41V85.68z M14.76,84.85h5.75V51.8h-5.75V84.85z"/>
					</g>
				</g>
				<g>
					<g>
						<path className={style?.className} d="M86.14,51.39h-8.69c-2.78,0-5.01-2.26-5.01-5.01v-3.83c0-0.66,0.54-1.18,1.18-1.18h16.35
							c0.66,0,1.18,0.54,1.18,1.18v3.83C91.15,49.12,88.92,51.39,86.14,51.39z M74.8,43.75v2.62c0,1.45,1.18,2.62,2.62,2.62h8.69
							c1.45,0,2.62-1.18,2.62-2.62v-2.62H74.8z"/>
						<path className={style?.className} d="M86.14,51.8h-8.69c-2.99,0-5.42-2.43-5.42-5.42v-3.83c0-0.88,0.71-1.59,1.59-1.59h16.35
							c0.88,0,1.59,0.71,1.59,1.59v3.83C91.57,49.37,89.13,51.8,86.14,51.8z M73.62,41.79c-0.42,0-0.76,0.34-0.76,0.76v3.83
							c0,2.53,2.06,4.59,4.59,4.59h8.69c2.53,0,4.59-2.06,4.59-4.59v-3.83c0-0.42-0.34-0.76-0.76-0.76H73.62z M86.11,49.42h-8.69
							c-1.68,0-3.04-1.36-3.04-3.04v-3.04h14.77v3.04C89.15,48.05,87.79,49.42,86.11,49.42z M75.21,44.17v2.21
							c0,1.22,0.99,2.21,2.21,2.21h8.69c1.22,0,2.21-0.99,2.21-2.21v-2.21H75.21z"/>
					</g>
				</g>
				<g>
					<g>
						<path className={style?.className} d="M86.29,87.65h-8.96c-0.66,0-1.18-0.54-1.18-1.18V50.21c0-0.66,0.54-1.18,1.18-1.18h8.96
							c0.66,0,1.18,0.54,1.18,1.18v36.26C87.47,87.1,86.93,87.65,86.29,87.65z M78.51,85.26h6.58V51.39h-6.58V85.26z"/>
						<path className={style?.className} d="M86.29,88.06h-8.96c-0.88,0-1.59-0.71-1.59-1.59V50.21c0-0.88,0.71-1.59,1.59-1.59h8.96
							c0.88,0,1.59,0.71,1.59,1.59v36.26C87.88,87.33,87.16,88.06,86.29,88.06z M77.33,49.45c-0.42,0-0.76,0.34-0.76,0.76v36.26
							c0,0.42,0.34,0.76,0.76,0.76h8.96c0.41,0,0.76-0.36,0.76-0.76V50.21c0-0.42-0.34-0.76-0.76-0.76H77.33z M85.5,85.68H78.1
							V50.97h7.41V85.68z M78.93,84.85h5.75V51.8h-5.75V84.85z"/>
					</g>
				</g>
				<g>
					<g>
						<path className={style?.className} d="M49.73,25.51c-4.71,0-8.57-3.83-8.57-8.57s3.83-8.57,8.57-8.57s8.57,3.83,8.57,8.57
							S54.44,25.51,49.73,25.51z M49.73,10.79c-3.41,0-6.18,2.78-6.18,6.18s2.78,6.18,6.18,6.18c3.41,0,6.18-2.78,6.18-6.18
							S53.14,10.79,49.73,10.79z"/>
						<path className={style?.className} d="M49.73,25.93c-4.95,0-8.98-4.03-8.98-8.98s4.03-8.98,8.98-8.98s8.98,4.03,8.98,8.98
							S54.68,25.93,49.73,25.93z M49.73,8.79c-4.5,0-8.15,3.66-8.15,8.15s3.66,8.15,8.15,8.15s8.15-3.66,8.15-8.15
							S54.23,8.79,49.73,8.79z M49.73,23.58c-3.64,0-6.6-2.96-6.6-6.6s2.96-6.6,6.6-6.6s6.6,2.96,6.6,6.6S53.37,23.58,49.73,23.58z
							 M49.73,11.21c-3.18,0-5.77,2.59-5.77,5.77s2.59,5.77,5.77,5.77s5.77-2.59,5.77-5.77S52.91,11.21,49.73,11.21z"/>
					</g>
				</g>
			</g>
			<g>
				<path className={style?.className} d="M53.02,51.53h-5.76c-5.23,0-9.48,4.37-9.48,9.76v22.93c0,0.42,0.33,0.76,0.74,0.76h1.86V62.52
					c0-4.59,3.62-8.32,8.08-8.32h3.36c4.45,0,8.05,3.71,8.05,8.3v22.48h1.86c0.41,0,0.74-0.34,0.74-0.76V61.26
					C62.47,55.88,58.24,51.53,53.02,51.53z"/>
				<path className={style?.className} d="M61.73,85.51h-2.39V62.5c0-4.28-3.38-7.77-7.52-7.77h-3.36c-4.16,0-7.55,3.49-7.55,7.79v22.99h-2.39
					c-0.7,0-1.26-0.58-1.26-1.29V61.29c0-5.67,4.49-10.29,10.01-10.29h5.75c5.5,0,9.98,4.6,9.98,10.26v22.96
					C63,84.93,62.43,85.51,61.73,85.51z M60.4,84.45h1.33c0.11,0,0.21-0.1,0.21-0.23V61.26c0-5.08-4-9.2-8.92-9.2h-5.75
					c-4.93,0-8.95,4.14-8.95,9.23v22.93c0,0.13,0.09,0.23,0.21,0.23h1.33V62.52c0-4.88,3.86-8.85,8.61-8.85h3.36
					c4.73,0,8.58,3.96,8.58,8.83V84.45z"/>
			</g>
</svg>

	)
}