import React from 'react';

import GAException from 'src/components/GAException';
import { Box } from 'react-feather';
import { handleGetStorage } from 'src/hooks/validationParticipantes';

const AFINIDADES_LOGO_LOGIN = [
  {
    KEY: 'TODOS',
    VALUE:{MIN: "300", MAX: "500"},
  },
  {
    KEY: '1620',
    VALUE:{MIN: "300", MAX: "500"},
  },
  {
    KEY: '1631',
    VALUE:{MIN: "300", MAX: "500"},
  },
  {
    KEY: '115003',
    VALUE:{MIN: "220", MAX: "220"},
  },
  {
    KEY: '1612',
    VALUE:{MIN: "300", MAX: "500"},
  },
  {
    KEY: '1659',
    VALUE:{MIN: "200", MAX: "450"},
  },
  {
    KEY: '1611',
    VALUE:{MIN: "200", MAX: "200"},
  },
  {
    KEY: '1619',
    VALUE:{MIN: "200", MAX: "200"},
  },
]
handleGetStorage();

const key = JSON.parse(localStorage.getItem('themeConfig'))?.code ? JSON.parse(localStorage.getItem('themeConfig'))?.code : JSON.parse(localStorage.getItem('themeConfig'))?.afinidad ? JSON.parse(localStorage.getItem('themeConfig'))?.afinidad : 'TODOS';

function Logo(props) {

  let logoConfig = localStorage.getItem("themeConfig") !== null ? JSON.parse(localStorage.getItem("themeConfig")).loginView.logoUrl : "/static/images/dimo/logo_dimo_white.png";
  let logoWidth = {MIN: "250", MAX: "250"}

  for (let i = 0; i < AFINIDADES_LOGO_LOGIN.length; i++) {
    if (AFINIDADES_LOGO_LOGIN[i].KEY === key) {
      logoWidth = AFINIDADES_LOGO_LOGIN[i].VALUE;
      break;
    }
  }
  
  return (
    <img
      style={{ width: `${props.width ? logoWidth.MAX : logoWidth.MIN}px` }}
      alt="Logo"
      src={logoConfig}
      {...props}
    />
  );
}

export function LogoDimo(props) {

  let logoConfig = JSON.parse(localStorage.getItem('themeConfig'))?.requiereDarkLogin ? "/static/images/dimo/logo_dimo_blue.png" : "/static/images/dimo/logo_dimo_white.png";

  return (
    <img
      style={{ width: props.width ? props.width : '100px' }}
      alt="Logo"
      src={logoConfig}
      {...props}
    />
  );
}

export function LogoResize(props) {

  let logoConfig = localStorage.getItem("themeConfig") !== null ? JSON.parse(localStorage.getItem("themeConfig")).loginView.logoUrl : "/static/images/dimo/logo_dimo_white.png";

  return (
    <img
      style={props.width ? { width: props.width } : { width: '220px' }}
      alt="Logo"
      src={logoConfig}
      {...props}
    />
  );
}

export function LogoBanderaPya({ tamano, classes }){
  return (
    <img
      src="/static/images/dimo/bandera_paraguaya.png"
      alt="banderaParaguaya"
      height={tamano}
      width={tamano}
      className={classes}
      style={{ marginRight: '5px' }}
    />
  );
}

export function LogoMin(props) {
  return (
    <img
      style={{ width: '50px' }}
      alt="Logo"
      src={"/static/logo_dimo_blue.jpg"}
      {...props}
    />
  );
}

export function LogoBlue(props) {
  let logoConfig = localStorage.getItem("themeConfig") !== null || undefined ? JSON.parse(localStorage.getItem("themeConfig")).headerIcon : "/static/images/dimo/logo_dimo_white.png";
  return (
    <img
      style={{ width: '25%' }}
      alt="Logo"
      // src={"/static/images/dimo/logo_dimo_blue.png"}
      src={logoConfig}
      {...props}
    />
  );
}

export function LogoCabal(props) {
  return (
    <img
      style={{ width: '50px' }}
      alt="Logo"
      src={"/static/logo_cabal_vertical.jpg"}
      {...props}
    />
  );
}

export function LogoCabalResize(props) {
  return (
    <img
      className={props.className}
      alt="Logo"
      src={"/static/logo_cabal_vertical.jpg"}
    />
  );
}

export function LogoCabalHorizontal(props) {
  return (
    <img
      style={{ width: '100%' }}
      alt="Logo"
      src={"https://estatico.cabal.coop.py/public/institucional/logos/logo.png"}
      {...props}
    />
  );
}

export function LogoCoop({ logoUrl, ...props }) {
  return (
    <img
      style={{ width: '100%' }}
      alt="Logo"
      src={(logoUrl && logoUrl !== null) ? logoUrl : ""}
      {...props}
    />
  );
}

export function LogoCabalPanal(props) {
  return (
    <img
      style={{ width: '70%', maxWidth:'250px' }}
      alt="Logo"
      src={"https://estatico.cabal.coop.py/public/dimo/cabal_panal.png"}
      {...props}
    />
  );
}

export function LogoSicoopLogin(props) {
  return (
    <img
      style={{ width: '100px' }}
      alt="Logo"
      src={JSON.parse(localStorage.getItem('themeConfig'))?.requiereDarkLogin ? "/static/images/dimo/logo-sicoop.png" : "/static/images/dimo/SICOOP_BLANCO.png"}
      {...props}
    />
  );
}

export default Logo;
