export const handdleGetStorage = (key) => {
    const dataAuxi = localStorage.getItem(key);
    if (dataAuxi === null) {
        return null;
    } else {
        return JSON.parse(dataAuxi);
    }
}
export const handdleSetStorage = (key, value) => {
    try {
        localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        return null;
    }
}