import React, { useState, useEffect } from 'react';

import GAException from 'src/components/GAException';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
  SvgIcon
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import Measure from 'react-measure';
import { useHistory,useLocation } from 'react-router';

import { Menu as MenuIcon, ArrowLeft } from 'react-feather';
import { THEMES } from 'src/constants';

import { DimoWave } from './icons';
import { LogoBanderaPya } from '../../../components/Logo';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...theme.name === THEMES.LIGHT ? {
      boxShadow: 'none',
      backgroundColor: theme.palette.backgroundColor
    } : {},
    ...theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.default
    } : {}
  },
  toolbar: {
    minHeight: 64 - 8,
    paddingLeft: 0,
    paddingRight: 0,
  },
  menuIcon: {
    color: theme.palette.primary.main
  },
  menuIconRight: {
    color: theme.palette.text.primary
  },
  userNameBox: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    justifyContent: 'center',
    display: 'flex'
  },
  topName: {
    fontSize: '18px',
    color: theme.palette.dark.main,
    fontWeight: 400,
    marginTop: '3px'
  },
  logo: {
    width: '299px',
    backgroundColor: '#1C73FE',
  },
  backgroundColor: {
    backgroundColor: '#ffffff',
  }
}));

function TopBar({
  className,
  onMobileNavOpen,
  ...rest
}) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation() ;
  const { user } = useSelector((state) => state.account);
  const [topName, setTopName] = useState('');
  const [isMainPage, setMainPage] = useState(false);

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const { width } = dimensions;

  const handleBtnClickBack = () => {
    // Si estamos en las raices de los submenus principales, la Flecha en lugar de Ir Atras
    // Nos enviara al Home
    if (
      window.location.pathname === '/app/payment' ||
      window.location.pathname === '/app/transferencias' ||
      window.location.pathname === '/app/cooperativas' ||
      window.location.pathname === '/app/prepaidcard' ||
      window.location.pathname === '/app/creditcard' ||
      window.location.pathname === '/app/recargasaldo' ||
      window.location.pathname === '/app/gestion-efectivo' ||
      window.location.pathname === '/app/completar/registro' ||
      window.location.pathname === '/app/account' ||
      window.location.pathname === '/app/creditcard/vincularDimoTC/exitoso' ||
      window.location.pathname === '/app/creditcard/vincularDimoTC/fallido'
    ) {
      history.push("/app")
    } else if (
        window.location.pathname.includes('/app/payment') && (
        window.location.pathname === '/app/payment/creditCard' ||
        window.location.pathname === '/app/payment/creditCard/tarjetaPago' ||
        window.location.pathname === '/app/payment/cooperativa'
      )
      ) {
        history.push("/app")
    } else if (window.location.pathname.includes("/app/compras/qr/resultado"))  {
      history.push("/app")
    } else if (window.location.pathname.includes("/app/seguridad"))  {
      history.push("/app")
    }
    else {
      history.goBack();
    }
  }

  useEffect(() => {

    if (window.location.pathname === '/app' || window.location.pathname === '/app/') {
      setMainPage(true)
    } else {
      setMainPage(false)
    }

    let v_topName = '';
    const location_href = window.location.href+'';

    if (window.location.pathname.includes('/app/payment/ticket/listar')) {
      v_topName = 'Listar Tickets';
    }else if (window.location.pathname.includes('/app/payment')) {
      v_topName = 'Pagos';
    } else if (window.location.pathname.includes('/app/compras/qr')) {
      if (location.state !== undefined && location.state.titleName === 'DEPO') {
        v_topName = 'Deposito';
      } else if(location.state != undefined && location.state.titleName === 'EXTR') {
        v_topName = 'Extracción';
      } else {
        v_topName = 'Compras QR';
      }

    } else if (window.location.pathname.includes('/app/transferencias') || window.location.pathname.includes('/app/transaccion') ) {
      if(location.state && location.state.hasOwnProperty("titleName") && (location.state.titleName === 'Extracción' ||
        location.state.titleName === 'EXTR')) {
        v_topName = 'Extracción';
      } else {
        v_topName = 'Transferencias y Giros';
      }
    } else if (window.location.pathname.includes('/app/cooperativas')) {
      v_topName = 'Mi Cooperativa';
    } else if (window.location.pathname.includes('/app/prepaidcard')) {
      v_topName = user.firstName + ' ' + user.lastName;
    } else if (window.location.pathname.includes('/app/creditcard')) {
      v_topName = 'Tarjetas de Crédito';
    } else if (window.location.pathname.includes('/app/recargasaldo')) {
      v_topName = 'Recarga de Saldo';
    } else if (window.location.pathname.includes('/app/gestion-efectivo')) {
      v_topName = 'Gestion de Efectivo';
    } else if (window.location.pathname.includes('/app/generar/deposito-extraccion/aqui-pago')
    || window.location.pathname.includes('/app/leer/qr/origen')) {
      if (location.state.titleName === 'Deposito' ||
      location.state.titleName === 'DEPO') {
        v_topName = 'Deposito';
      } else if(location.state.titleName === 'Extracción' ||
        location.state.titleName === 'EXTR') {
        v_topName = 'Extracción';
      } else {
        v_topName = 'Hola, ' + user.firstName + ' ' + user.lastName;
      }
    } else if (window.location.pathname.includes('/app/generar/qr')) {
      v_topName = 'Generar QR';
    } else if (window.location.pathname.includes('/app/generar/qr')) {
      v_topName = 'Leer QR';
    } else if (window.location.pathname.includes('/app/account')) {
      v_topName = 'Perfil de Usuario';
    } else if (window.location.pathname === '/app' || window.location.pathname === '/app/') {
      v_topName = 'Hola, ' + user.firstName + ' ' + user.lastName;
    } else {
      v_topName = 'Hola, ' + user.firstName + ' ' + user.lastName;
    }

    // Agregar texto para identificar si el entorno es productivo o de Test
    if (location_href.includes('dimotest.cabal.coop.py') || location_href.includes('localhost')){
      v_topName = v_topName + " ( Pruebas )"
    }

    setTopName(v_topName);

  }, [location]);

  return (
    <Measure
      bounds
      onResize={contentRect => setDimensions(contentRect.bounds)}
    >
      {({ measureRef }) => (
        <AppBar
          ref={measureRef}
          className={classes.root}
          {...rest}
        >
          <Toolbar
            className={width > 600 ? clsx(classes.toolbar, classes.backgroundColor) : clsx(classes.toolbar)}
          >
            <Hidden lgUp>
              {!isMainPage && <IconButton
                className={classes.menuButton}
                color="inherit"
                onClick={handleBtnClickBack}
              >
                <SvgIcon fontSize="small">
                  <ArrowLeft className={isMainPage ? classes.menuIconRight : classes.menuIcon} />
                </SvgIcon>
              </IconButton>}
            </Hidden>
            <Hidden mdDown>
              <Box className={classes.logo} />
            </Hidden>
            <Box
              flexGrow={1}
              className={classes.userNameBox}
            >
              {/*{ isMainPage && (*/}
              {/*  <LogoBanderaPya*/}
              {/*    tamano={30}*/}
              {/*    classes={{ margin: '0px 3px' }}*/}
              {/*  />*/}
              {/*)}*/}
              <Typography
                className={classes.topName}
                variant="h4"
              >
                {topName}
              </Typography>
            </Box>
            <Hidden lgUp>
              <IconButton
                className={classes.menuButton}
                color="inherit"
                onClick={onMobileNavOpen}
              >
                <SvgIcon fontSize="small">
                  <MenuIcon className={classes.menuIcon} />
                </SvgIcon>
              </IconButton>
            </Hidden>
          </Toolbar>
        </AppBar>
      )}
    </Measure>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
