import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const url = window.location.href;

export const useConstans = (item) => {
  let dataAuxi = localStorage.getItem('themeConfig');
  // let themeConfigRemote = {
  //   backgroundColor: '#EEEEEE',//Fondos que no afectan objetos
  //   primary: '#03873E',//Barra de acciones, circulo de carga...,
  //   secondary: '#03873E',//Barra lateral, botones,
  //   tertiary: '#0AFB3A',//0AFB3A Flechas barra lateral y 2 iconos, Código Promotor titulo
  //   textPrimary: '#026014',//Mayor parte de titulos de componenetes, botones dashboard
  //   textSecondary: '#152C3B',
  //   textTertiary: '#707070',
  //   loginView: {
  //     logoUrl:
  //       'https://www.yoayu.coop.py/wp-content/uploads/2021/04/yoayu.png',
  //     backgroundWebImage:
  //       'https://res.cloudinary.com/arnix/image/upload/v1690393534/97438e8f-dddc-4367-a635-1a7effff5717_jyzp07.png',
  //     backgroundMobileImage:
  //       'https://res.cloudinary.com/arnix/image/upload/v1690393532/03a70cf2-e0e2-4860-850e-570c3a485180_zrxmdc.png'
  //   }
  // };
  let themeConfigRemote = {
    codParticipante: item.code,
    backgroundColor: item.backgroundColor,//Fondos que no afectan objetos
    primary: item.primary,//Barra de acciones, circulo de carga...,
    secondary: item.secondary,//Barra lateral, botones,
    tertiary: item.tertiary,//0AFB3A Flechas barra lateral y 2 iconos, Código Promotor titulo
    textPrimary: item.item.tertiary,//Mayor parte de titulos de componenetes, botones dashboard
    textSecondary: item.textSecondary,
    textTertiary: item.textTertiary,
    loginView: {
      logoUrl: item.logoUrl,
      backgroundWebImage:item.backgroundWebImage,
      backgroundMobileImage:item.backgroundMobileImage
    }
  }

  if (dataAuxi === null || dataAuxi !== JSON.stringify(themeConfigRemote)) {
    console.log(
      'Seteando configuracion remota',
      JSON.parse(localStorage.getItem('themeConfig'))
    );
    localStorage.setItem('themeConfig', JSON.stringify(themeConfigRemote));
    return JSON.parse(localStorage.getItem('themeConfig'));
  }else{
    console.log("No seteo la config")
    return JSON.parse(localStorage.getItem('themeConfig'));
  }
};
