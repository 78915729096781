import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import {
  makeStyles,
} from '@material-ui/core';

import NavBar from './NavBar';
import TopBar from './TopBar';
import BottomBar from './BottomBar';

import { useLocation } from 'react-router';
import useRegistrarActividadDimo from 'src/hooks/useRegistrarActividadDimo';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.primary,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  rootImageWeb: {
    backgroundImage: JSON.parse(localStorage.getItem('configTheme'))?.backgroundWebInicio ? `URL(${JSON.parse(localStorage.getItem('configTheme'))?.backgroundWebInicio})` : 'URL(/static/images/dimo/background_web_inicio.png)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  rootImageMobile: {
    backgroundImage: JSON.parse(localStorage.getItem('configTheme'))?.backgroundMobileInicio ? `URL(${JSON.parse(localStorage.getItem('configTheme'))?.backgroundMobileInicio})` : 'URL(/static/images/dimo/background_web_inicio.png)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    paddingBottom: 30,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 280
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: 380
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    paddingBottom: '50px',
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(8),
    right: theme.spacing(2),
    zIndex: '1306',
  },
  bottomButton: {
    position: 'absolute',
    zIndex: '1302',
    height: '50px',
    bottom: theme.spacing(0),
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
  contenFooter: {
    width: '100%',
    height: '90px',
  }
}));

function DashboardLayout({ children }) {
  const classes = useStyles();
  const location = useLocation();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [isShowDialog, setShowDialog] = useState(false);
  const [isMainPage, setMainPage] = useState(false);
  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
  const { width } = dimensions;
  const themeConfigRemote = JSON.parse(localStorage.getItem("themeConfig"));

  let themeConfigWeb = {
    backgroundImage: `URL(${themeConfigRemote?.backgroundWebInicio})`,
  };

  let themeConfigMobile = {
    backgroundImage: `URL(${themeConfigRemote?.backgroundMobileInicio})`,
  }

  useRegistrarActividadDimo(location.pathname, location.state);

  const handleFloatButton = () => {
    setShowDialog(!isShowDialog);
  }

  useEffect(() => {
    if (location.pathname === '/app' || location.pathname === '/app/') {
      setMainPage(true)
    } else {
      setMainPage(false)
    }
  }, [location.pathname])

  const returnBackground = () => {;
    if(!JSON.parse(localStorage.getItem("themeConfig"))?.requireFullTheme || themeConfigRemote?.backgroundWebInicio === null || themeConfigRemote?.backgroundMobileInicio === null){
      return classes.root;
    }
    if (width > 475) {
      return classes.rootImageWeb;
    }else{
      return classes.rootImageMobile;
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }
  , []);

  return (
    <div
    className={returnBackground()}
    style={themeConfigRemote?.backgroundWebInicio !== null || themeConfigRemote?.backgroundMobileInicio !== null ? (width > 475 ? themeConfigWeb : themeConfigMobile) : {}}
    >
      {
        //isShowDialog ? <DirectAccess isShowDialog={(value) => setShowDialog(value)} /> : null
      }
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      {
        /*!isShowDialog
          ? <Fab
            aria-label="action"
            className={classes.fab}
            onClick={handleFloatButton}
          >
            <img
              style={{ width: '100%' }}
              alt="float_button"
              src={"/static/images/dimo/float_plus_button.png"}
            />
          </Fab>
          : <Fab
            aria-label="action"
            className={classes.fab}
            onClick={handleFloatButton}
          >
            <img
              style={{ width: '100%' }}
              alt="float_minus_button"
              src={"/static/images/dimo/float_minus_button.png"}
            />
          </Fab>
          */
      }
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {children}
          </div>
        </div>
      </div>
      <footer
        className={classes.bottomButton}
        //style={isMainPage ? {} : { display : 'none' }}
      >
        <BottomBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={!isMobileNavOpen}
        />
      </footer>
    </div>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.any
};

export default DashboardLayout;
