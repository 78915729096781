import axios from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { themeRegister } from 'src/actions/themeActions';
import { backgroundColor, cardBackground, primary, secondary, tertiary, textPrimary, textSecondary, textTertiary } from 'src/theme/constans';
import { stringify } from 'uuid';
import { handdleSetStorage } from './useSocioValidacion';

const URL_STATIC_THEME = 'https://estaticopre.cabal.coop.py/public/dimo/theme1/images/'
const URL_STATIC_CONFIG = 'https://estaticopre.cabal.coop.py/public/dimo/1631/config.js'
const arrayUrls = [
  {
    id: 1,
    url: '192.168.254.52:3000',
    code: '1631',
    afinidad: null,
    requireAuth: true
  },
  {
    id: 2,
    url: '10.5.2.144:3000',
    code: '1659',
    afinidad: null,
    requireAuth: true
  },
  {
    id: 3,
    url: 'localhost:3000',
    code: '1634',
    afinidad: null,
    requireAuth: true
  },
  {
    id: 4,
    url: 'yoayu.dimo.com.py',
    code: '1631',
    afinidad: null,
    requireAuth: true
  },
  {
    id: 5,
    url: 'yoayu.cabal.coop.py',
    code: '1631',
    afinidad: null,
    requireAuth: true
  },
  {
    id: 6,
    url: 'yoayutest.cabal.coop.py',
    code: '1631',
    afinidad: null,
    requireAuth: true
  },
  {
    id: 7,
    url: 'ypacarai.dimo.com.py',
    code: '1620',
    afinidad: null,
    requireAuth: false
  },
  {
    id: 8,
    url: 'ypacaraitest.cabal.coop.py',
    code: '1620',
    afinidad: null,
    requireAuth: false
  },
  {
    id: 9,
    url: 'fundclubtest.cabal.coop.py',
    code: null,
    afinidad: '115003',
    requireAuth: true
  },
  {
    id: 10,
    url: 'fundclub.dimo.com.py',
    code: null,
    afinidad: '115003',
    requireAuth: true
  },
  {
    id: 11,
    url: '8demarzotest.cabal.coop.py',
    code: '1612',
    afinidad: null,
    requireAuth: true
  },
  {
    id: 12,
    url: '8demarzo.dimo.com.py',
    code: '1612',
    afinidad: null,
    requireAuth: true
  },
  {
    id: 13,
    url: 'judicialtest.cabal.coop.py',
    code: '1659',
    afinidad: null,
    requireAuth: true
  },
  {
    id: 14,
    url: 'judicial.dimo.com.py',
    code: '1659',
    afinidad: null,
    requireAuth: true
  },
  {
    id: 15,
    url: 'credivilltest.cabal.coop.py',
    code: '1611',
    afinidad: null,
    requireAuth: true
  },
  {
    id: 16,
    url: 'credivill.dimo.com.py',
    code: '1611',
    afinidad: null,
    requireAuth: true
  },
  {
    id: 17,
    url: 'nembytest.cabal.coop.py',
    code: '1619',
    afinidad: null,
    requireAuth: true
  },
  {
    id: 18,
    url: 'nemby.dimo.com.py',
    code: '1619',
    afinidad: null,
    requireAuth: true
  },
  {
    id: 19,
    url: 'serranatest.cabal.coop.py',
    code: '1634',
    afinidad: null,
    requireAuth: true
  },
  {
    id: 20,
    url: 'serrana.dimo.com.py',
    code: '1634',
    afinidad: null,
    requireAuth: true
  },
];

async function fetchData (url) {
  let data;
  let error;
  let loading = true; // Inicializar loading en true

  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const dataText = await response.text();
    const fixText = dataText.replace(/'/g, '"').replace(/(\w+)\s*:/g, '"$1":').replace(/"https"/g, 'https').replace(/,\s*}/g, '}');
    const jsonData = JSON.parse(fixText)
    data = jsonData
  } catch (err) {
    error = err.message; // Asignar el mensaje de error
  } finally {
    loading = false;
  }

  // Devolver un objeto con los resultados
  return { data, loading, error };
};

const MockStyles = { ...{
  "code": "1634",
	"afinidad": null,
  "tarjetaEmisor":["68"],
  "paleta":"https://estaticopre.cabal.coop.py/public/dimo/theme/1634/palette/palette.js",
  "loginView": {
    "logoUrl": "https://estaticopre.cabal.coop.py/public/dimo/1634/images/logoUrl.png",
    "backgroundWebImage":"https://estaticopre.cabal.coop.py/public/dimo/theme/1634/images/backgroundWebImage.jpg",
    "backgroundMobileImage":"https://estaticopre.cabal.coop.py/public/dimo/theme/1634/images/backgroundMobileImage.jpg"
  },
  "headerIcon":"https://estaticopre.cabal.coop.py/public/dimo/1634/images/headerIcon.png",
  "splashIcon":"https://estaticopre.cabal.coop.py/public/dimo/1634/images/logoUrl.png",
  "buttonIcon":"https://estaticopre.cabal.coop.py/public/dimo/1634/images/headerIcon.png",
	"institucional":"https://dpago.com/",
	"nombre":"Dpago",
  "requireAuth": false,
	"backgroundWebInicio": null,
	"backgroundMobileInicio": null,
	"requireFullTheme": false,
	"requiereDarkLogin": false,
	"promotor": "PAGO"
},
...{
  "backgroundColor": "#EEEEEE",
  "primary": "#E1D000",
  "secondary": "#00562E",
  "tertiary": "#00562E",
  "textPrimary": "#727C8E",
  "textSecondary": "#152C3B",
  "textTertiary": "#707070",
  "cardBackground":"#dcdcdc",
  "spinner": "#1591CC",
  "navbarLeft": {
    "backgroundColor": "#1591CC",
    "text": "#FFFFFF",
    "icon": "#FFFFFF",
  },
  "navbarButtom": {
    "backgroundColor": "#FFFFFF",
    "text": "#000000",
    "icon": "#000000",
  },
  "codPromotor":{
    "title": "#FFFFFF",
    "code": "#FFFFFF",
  },
  "buttonSection": {
    "backgroundColor": "#FFFFFF",
    "text": "#FFFFFF",
    "icon": "#1591CC",
  },
  "buttonStep": {
    "backgroundColor": "#1591CC",
    "text": "#FFFFFF",
    "icon": "#FFFFFF",
  },
  "buttonExtra": {
    "backgroundColor": "#FFFFFF",
    "text": "#1591CC",
    "icon": "#1591CC",
  },
}
}

const MockStyles2 = {
  ...{
    "code": "1634",
    "afinidad": null,
    "tarjetaEmisor":["68"],
    "paleta":"https://estaticopre.cabal.coop.py/public/dimo/theme/115003/palette/palette.js",
    "loginView": {
      "logoUrl": "https://estaticopre.cabal.coop.py/public/dimo/115003/images/logoUrl.png",
      "backgroundWebImage":"https://estaticopre.cabal.coop.py/public/dimo/theme/115003/images/backgroundWebImage.jpg",
      "backgroundMobileImage":"https://estaticopre.cabal.coop.py/public/dimo/theme/115003/images/backgroundMobileImage.jpg"
    },
    "headerIcon":"https://estaticopre.cabal.coop.py/public/dimo/115003/images/headerIcon.png",
    "splashIcon":"https://estaticopre.cabal.coop.py/public/dimo/115003/images/headerIcon.png",
    "buttonIcon":"https://estaticopre.cabal.coop.py/public/dimo/115003/images/headerIcon.png",
    "institucional":"https://www.naveropro.com/",
    "nombre":"Fund Club",
    "requireAuth": true,
    "backgroundWebInicio": null,
    "backgroundMobileInicio": null,
    "requireFullTheme": false,
    "requiereDarkLogin": true,
    "promotor": "FUND"
  },
  ...{
    "backgroundColor": "#EEEEEE",
    "primary": "#2E30CF",
    "secondary": "#1C73FE",
    "tertiary": "#A1F750",
    "textPrimary": "#152C3B",
    "textSecondary": "#707070",
    "textTertiary": "#727C8E",
    "cardBackground":"#dcdcdc",
    "spinner": "#1C73FE",
    "navbarLeft": {
      "backgroundColor": "#1C73FE",
      "text": "#FFFFFF",
      "icon": "#FFFFFF",
    },
    "navbarButtom": {
      "backgroundColor": "#2E30CF",
      "text": "#A1F750",
      "icon": "#A1F750",
    },
    "codPromotor":{
      "title": "#FFFFFF",
      "code": "#FFFFFF",
    },
    "buttonSection": {
      "backgroundColor": "#FFFFFF",
      "text": "#FFFFFF",
      "icon": "#2E30CF",
    },
    "buttonStep": {
      "backgroundColor": "#2E30CF",
      "text": "#FFFFFF",
      "icon": "#FFFFFF",
    },
    "buttonExtra": {
      "backgroundColor": "#FFFFFF",
      "text": "#2E30CF",
      "icon": "#2E30CF",
    },
  }
}

async function loadThemeConfig(dispatch, themeConfigRemote) {
  await dispatch(themeRegister(themeConfigRemote));
  localStorage.setItem('themeConfig', JSON.stringify(themeConfigRemote));
}
// function setThemeVariables(themeConfig){
//   backgroundColor = themeConfig.backgroundColor
//   primary = themeConfig.primary
//   secondary = themeConfig.secondary
//   tertiary =  themeConfig.tertiary
//   textPrimary = themeConfig.textPrimary
//   textSecondary = themeConfig.textSecondary
//   textTertiary = themeConfig.textTertiary
//   cardBackground = themeConfig.cardBackground

// }

async function fetchConfig(codParticipante) {
  let config = await fetchData(`https://estaticopre.cabal.coop.py/public/dimo/${codParticipante}/config.js`);
  // console.log("fetchConfig:",config)
  localStorage.setItem("requireAuth",JSON.parse(config?.data?.requireAuth));
  // await handdleSetStorage("requireAuth",false);
  let paleta = await fetchData(config?.data?.paleta)
  return { ...config.data, ...paleta.data }
  // return MockStyles
}

export async function handleGetStorage() {
  const urlActual = window.location.href;
  //eliminar /login y dejar solo el dominio
  const urlSplit = urlActual.split('/');
  // console.log('urlSplit', urlSplit);
  const url = urlSplit[2];
  // console.log('url', url);
  const urlEncontrada = arrayUrls.find(item => item.url === url);
  const config = await fetchConfig(urlEncontrada?.code ? urlEncontrada?.code : urlEncontrada?.afinidad)
  //setear a loadThemeConfig
  localStorage.setItem('themeConfig', JSON.stringify(config))
  return config;
};
