import React from 'react';

export function Home({size}) {
  return (
    <img
      style={{ width: size, marginRight: 10 }}
      alt="float_button"
      src={"/static/images/dimo/icon_home_green.png"}
    />
  )
}

export function Card({size}) {
  return (
    <img
      style={{ width: size, marginRight: 10 }}
      alt="float_button"
      src={"/static/images/dimo/icon_card_green.png"}
    />
  )
}

export function Cooperative({size}) {
  return (
    <img
      style={{ width: size, marginRight: 10 }}
      alt="float_button"
      src={"/static/images/dimo/icon_cooperative_green.png"}
    />
  )
}

export function Dimo({size}) {
  return (
    <img
      style={{ width: size, marginRight: 10 }}
      alt="float_button"
      src={"/static/images/dimo/icon_dimo_green.png"}
    />
  )
}

export function Transfer({size}) {
  return (
    <img
      style={{ width: size, marginRight: 10 }}
      alt="float_button"
      src={"/static/images/dimo/icon_transfer_green.png"}
    />
  )
}

export function User({size}) {
  return (
    <img
      style={{ width: size, marginRight: 10 }}
      alt="float_button"
      src={"/static/images/dimo/icon_user_green.png"}
    />
  )
}

export function Tarifario({size}) {
  return (
    <img
      style={{ width: size, marginRight: 10 }}
      alt="float_button"
      src={"/static/images/dimo/icon_tarifario_green_24.png"}
    />
  )
}

export function MiQR({size}) {
  return (
    <img
      style={{ width: size, marginRight: 10 }}
      alt="float_button"
      src={"/static/images/dimo/icon_qr_green.png"}
    />
  )
}


export function Exchange({ size }) {
  return (
    <img
      style={{
        width: size,
        marginRight: 10
      }}
      alt="float_button"
      src={'/static/images/dimo/icon_prize_green_24x24.png'}
    />
  );
}

export function Ayuda({ size }) {
  return (
    <img
      style={{ width: size, marginRight: 10 }}
      alt="float_button"
      src={"/static/images/dimo/icon_ayuda_green_24.png"}
    />
  )
}

export function Institucional({ size }) {
  const headerIcon = JSON.parse(localStorage.getItem('themeConfig')).headerIcon;
  return (
    <img
      style={{ width: "30px", marginRight: 10 }}
      alt="float_button"
      src={headerIcon}
    />
  )
}
