//import axios from 'src/utils/axios';
import axios from '../utils/axios';
import GAException from 'src/components/GAException';
import {
  creditcard_service_api_url as API_URL,
  extract_creditcard_service_api_url as API_URL_EXTRACTS,
  ceibo_prm_sp_services_api_url as API_URL_CEIBO_PRMS_SP,
  extract_descargar_service_api_url,
  account_service_api_url
}
  from 'src/services/backendApiService';

import { defaultResponseError } from 'src/services/transactionService'
import { prmTipo } from 'src/services/ceiboPrmSpService'

import { IMG_URL } from 'src/constants';
import { Buffer } from 'buffer';

//const axios_dummy = false;

let BODY_HEADER = {}

const direct_api_tar = true;

if (direct_api_tar) {
  // API TARJETA HABIENTES DIRECTAMENTE
  BODY_HEADER = {
    idSesion: "102",
    idRequest: "123456",
    codigoEF: "csoto",
    canal: "102",
    idClienteEF: "prueba",
    ciCliente: "csoto"
  }
}
else {
  // BACKEND DE DIMO
  BODY_HEADER = {
    appVersion: "1.0.2",
    token: "prueba",
    user: "csoto"
  }
}

export const toCreditCardData = (itemData) => {

  const item = (itemData && itemData !== null) ? itemData : {}

  const data = {
    "DOCUMENTO": item.hasOwnProperty("DOCUMENTO") ? (item.DOCUMENTO + "") : "",
    "PROCESADOR": item.hasOwnProperty("PROCESADOR") ? (item.PROCESADOR + "") : "",
    "DESC_PROCESADOR": item.hasOwnProperty("DESC_PROCESADOR") ? (item.DESC_PROCESADOR + "") : "",
    "EMISOR": item.hasOwnProperty("EMISOR") ? (item.EMISOR + "") : "",
    "DESC_EMISOR": item.hasOwnProperty("DESC_EMISOR") ? (item.DESC_EMISOR + "") : "",
    "GRUPO_AFINIDAD": item.hasOwnProperty("GRUPO_AFINIDAD") ? (item.GRUPO_AFINIDAD + "") : "",
    "DESC_GRUPO_AF": item.hasOwnProperty("DESC_GRUPO_AF") ? (item.DESC_GRUPO_AF + "") : "",
    "NUMERO_CUENTA": item.hasOwnProperty("NUMERO_CUENTA") ? (item.NUMERO_CUENTA + "") : "",
    "NUMERO_TARJETA": item.hasOwnProperty("NUMERO_TARJETA") ? (item.NUMERO_TARJETA + "") : "",
    "TAR_MASK": item.hasOwnProperty("TAR_MASK") ? (item.TAR_MASK + "") : "",
    "TAR_CRIPTO": item.hasOwnProperty("TAR_CRIPTO") ? (item.TAR_CRIPTO + "") : "",
    "NOMBRE_USUARIO": item.hasOwnProperty("NOMBRE_USUARIO") ? (item.NOMBRE_USUARIO + "") : "",
    "COMPONENTE": item.hasOwnProperty("COMPONENTE") ? (item.COMPONENTE + "") : "",
    "TIPO_TARJETA": item.hasOwnProperty("TIPO_TARJETA") ? (item.TIPO_TARJETA + "") : "",
    "TJ_PRINCIPAL": item.hasOwnProperty("TJ_PRINCIPAL") ? (item.TJ_PRINCIPAL + "") : "",
    "FECHA_OTORGADA": item.hasOwnProperty("FECHA_OTORGADA") ? (item.FECHA_OTORGADA + "") : "",
    "ADICIONAL": item.hasOwnProperty("ADICIONAL") ? (item.ADICIONAL + "") : "",
    "FECHA_VIGENCIA": item.hasOwnProperty("FECHA_VIGENCIA") ? (item.FECHA_VIGENCIA + "") : "",
    "VENCIMIENTO_TAR": item.hasOwnProperty("VENCIMIENTO_TAR") ? (item.VENCIMIENTO_TAR + "") : "",
    "ESTADO": item.hasOwnProperty("ESTADO") ? (item.ESTADO + "") : "",
    "DESC_ESTADO": item.hasOwnProperty("DESC_ESTADO") ? (item.DESC_ESTADO + "") : "",
    "CONGELADA": item.hasOwnProperty("CONGELADA") ? (item.CONGELADA + "") : "",
    "DEUDA_AL_CIERRE": item.hasOwnProperty("DEUDA_AL_CIERRE") ? (item.DEUDA_AL_CIERRE + "") : "",
    "ULTIMO_PERIODO_CERRADO": item.hasOwnProperty("ULTIMO_PERIODO_CERRADO") ? (item.ULTIMO_PERIODO_CERRADO + "") : "",
    "FECHA_VENCIMIENTO_PAGO_MINIMO": item.hasOwnProperty("FECHA_VENCIMIENTO_PAGO_MINIMO") ? (item.FECHA_VENCIMIENTO_PAGO_MINIMO + "") : "",
    "IMPORTE_PM_FACTURADO": item.hasOwnProperty("IMPORTE_PM_FACTURADO") ? (item.IMPORTE_PM_FACTURADO + "") : "",
    "DEUDA_A_LA_FECHA": item.hasOwnProperty("DEUDA_A_LA_FECHA") ? (item.DEUDA_A_LA_FECHA + "") : "",
    "PAGOS": item.hasOwnProperty("PAGOS") ? (item.PAGOS + "") : "",
    "IMPORTE_ULTIMO_PAGO": item.hasOwnProperty("IMPORTE_ULTIMO_PAGO") ? (item.IMPORTE_ULTIMO_PAGO + "") : "",
    "FECHA_ULTIMO_PAGO": item.hasOwnProperty("FECHA_ULTIMO_PAGO") ? (item.FECHA_ULTIMO_PAGO + "") : "",
    "LIMITE_CREDITO_TOTAL_OLPY": item.hasOwnProperty("LIMITE_CREDITO_TOTAL_OLPY") ? (item.LIMITE_CREDITO_TOTAL_OLPY + "") : "",
    "LIMITE_PLANES_OLPY": item.hasOwnProperty("LIMITE_PLANES_OLPY") ? (item.LIMITE_PLANES_OLPY + "") : "",
    "LIMITE_ADELANTO_OLPY": item.hasOwnProperty("LIMITE_ADELANTO_OLPY") ? (item.LIMITE_ADELANTO_OLPY + "") : "",
    "LIMITE_SALUD_OLPY": item.hasOwnProperty("LIMITE_SALUD_OLPY") ? (item.LIMITE_SALUD_OLPY + "") : "",
    "DISPONIBLE_NORMAL": item.hasOwnProperty("DISPONIBLE_NORMAL") ? (item.DISPONIBLE_NORMAL + "") : "",
    "CANT_ADICIONALES": item.hasOwnProperty("CANT_ADICIONALES") ? (item.CANT_ADICIONALES + "") : "",
    "IMPORTE_PAGO_MINIMO": item.hasOwnProperty("IMPORTE_PAGO_MINIMO") ? (item.IMPORTE_PAGO_MINIMO + "") : "",
    "DIAS_EN_MORA": item.hasOwnProperty("DIAS_EN_MORA") ? (item.DIAS_EN_MORA + "") : "",
    "FECHA_EMISION_PAGO_MINIMO": item.hasOwnProperty("FECHA_EMISION_PAGO_MINIMO") ? (item.FECHA_EMISION_PAGO_MINIMO + "") : "",
    "FECHA_EMISION_PAGO_MIN_SIG": item.hasOwnProperty("FECHA_EMISION_PAGO_MIN_SIG") ? (item.FECHA_EMISION_PAGO_MIN_SIG + "") : "",
    "FECHA_VENCIMIENTO_PAGO_MIN_SIG": item.hasOwnProperty("FECHA_VENCIMIENTO_PAGO_MIN_SIG") ? (item.FECHA_VENCIMIENTO_PAGO_MIN_SIG + "") : "",
    "CON_AUTORIZACIONES_PENDIENTES": item.hasOwnProperty("CON_AUTORIZACIONES_PENDIENTES") ? (item.CON_AUTORIZACIONES_PENDIENTES + "") : "",
    "CON_SOBREGIRO": item.hasOwnProperty("CON_SOBREGIRO") ? (item.CON_SOBREGIRO + "") : "",
    "FLG_RENOVACION": item.hasOwnProperty("FLG_RENOVACION") ? (item.FLG_RENOVACION + "") : "",
    "IS_LINEA_UNIFICADA": item.hasOwnProperty("IS_LINEA_UNIFICADA") ? (item.IS_LINEA_UNIFICADA + "") : "",
    "DISPONIBLE_PLAN": item.hasOwnProperty("DISPONIBLE_PLAN") ? (item.DISPONIBLE_PLAN + "") : "",
    "DISPONIBLE_ADELANTO": item.hasOwnProperty("DISPONIBLE_ADELANTO") ? (item.DISPONIBLE_ADELANTO + "") : "",
    "TIENE_PLAN_SALUD": item.hasOwnProperty("TIENE_PLAN_SALUD") ? (item.TIENE_PLAN_SALUD + "") : "",
    "DISPONIBLE_PLAN_SALUD": item.hasOwnProperty("DISPONIBLE_PLAN_SALUD") ? (item.DISPONIBLE_PLAN_SALUD + "") : "",
    "IMPORTE_SOBREGIRO": item.hasOwnProperty("IMPORTE_SOBREGIRO") ? (item.IMPORTE_SOBREGIRO + "") : "",
    "PERIODO_ACTUAL_TARJETA": item.hasOwnProperty("PERIODO_ACTUAL_TARJETA") ? (item.PERIODO_ACTUAL_TARJETA + "") : "",
    "CIERRE": item.hasOwnProperty("CIERRE") ? (item.CIERRE + "") : "",
    "VINCULADA_DIMO": item.hasOwnProperty("VINCULADA_DIMO") ? (item.VINCULADA_DIMO + "") : "",

    // Datos Personalizados
    "imgTarjetaURL": IMG_URL +
      (item.hasOwnProperty("EMISOR") ? item.EMISOR : "") +
      '_' +
      (item.hasOwnProperty("GRUPO_AFINIDAD") ? item.GRUPO_AFINIDAD : "") +
      '.png'


  }
  return data;
}

export const toPreguntasVinculacionDimoTC = (item) => {
  const data = {
    ID_PREGUNTA: item.hasOwnProperty("ID_PREGUNTA") ? item.ID_PREGUNTA : "",
    LABEL: item.hasOwnProperty("LABEL") ? item.LABEL : "",
    HINT: item.hasOwnProperty("HINT") ? item.HINT : "",
    OPCIONES_S_N: item.hasOwnProperty("OPCIONES_S_N") ? item.OPCIONES_S_N : "",

    // Campo adicional que no viene en el API
    respuestas: (item.hasOwnProperty("respuestas") && Array.isArray(item.respuestas) ? Array.from(item.respuestas) : []).map(x => toRespuestasVinculacionDimoTC(x))
  }
  return data;
}

export const toRespuestasVinculacionDimoTC = (item) => {
  const data = {
    ID_PREGUNTA: item.hasOwnProperty("ID_PREGUNTA") ? item.ID_PREGUNTA : "",
    ID_RESPUESTA: item.hasOwnProperty("ID_RESPUESTA") ? item.ID_RESPUESTA : "",
    VALOR_RESPUESTA: item.hasOwnProperty("VALOR_RESPUESTA") ? item.VALOR_RESPUESTA : "",
  }

  return data;
}

export const toResultadoVinculacionDimoTC = (item) => {
  const data = {

  }

  return data;
}

// Verificar identidad
export const toPreguntasVerificarIdentidad = (item) => {
  console.log("toPreguntasVerificarIdentidad", item)
  const data = {
    ID_PREGUNTA: item.hasOwnProperty("idPregunta") ? item.idPregunta : "",
    LABEL: item.hasOwnProperty("label") ? item.label : "",
    HINT: item.hasOwnProperty("hint") ? item.hint : "",
    OPCIONES_S_N: item.hasOwnProperty("opciones") ? item.opciones : "",

    // // Campo adicional que no viene en el API
    respuestas: (item.hasOwnProperty("respuestas") && Array.isArray(item.respuestas) ? Array.from(item.respuestas) : []).map(x => toRespuestasVinculacionDimoTC(x))
  }
  return data;
}

export const toRespuestasVerificarIdentidad = (item) => {
  console.log("toRespuestasVerificarIdentidad", item, "idRespuesta",item.hasOwnProperty("idRespuesta"), "respuesta",item.hasOwnProperty("respuesta"))
  const data = {
    ID_PREGUNTA: item.hasOwnProperty("idPregunta") ? item.idPregunta : "",
    ID_RESPUESTA: item.hasOwnProperty("idRespuesta") ? item.idRespuesta : "",
    VALOR_RESPUESTA: item.hasOwnProperty("respuesta") ? item.respuesta : "",
  }
  
  return data;
}

export const toResultadoVerificarIdentidad = (item) => {
  const data = {

  }

  return data;
}

class CreditCardService {

  /*setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };*/

  adicionalTC = (AdicionalTC) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/AdicionalTC', { header: BODY_HEADER, data: AdicionalTC })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
        //GAException(error);
        reject(error);
      });
  })

  aumentoLineaTC = (AumentoLineaTC) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/AumentoLineaTC', { header: BODY_HEADER, data: AumentoLineaTC })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
        //GAException(error);
        reject(error);
      });
  })

  avisoViajeTC = (AvisoViajeTC) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/AvisoViajeTC', { header: BODY_HEADER, data: AvisoViajeTC })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
        //GAException(error);
        reject(error);
      });
  })

  bloqueoDesbloqueoTC = (BloqueoDesbloqueoTC) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/BloqueoDesbloqueoTC', { header: BODY_HEADER, data: BloqueoDesbloqueoTC })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
        //GAException(error);
        reject(error);
      });
  })

  cambioCicloTC = (CambioCicloTC) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/CambioCicloTC', { header: BODY_HEADER, data: CambioCicloTC })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
        //GAException(error);
        reject(error);
      });
  })

  consultaExtracto = (ConsultaExtracto) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/ConsultaExtracto', { header: BODY_HEADER, data: ConsultaExtracto })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
        //GAException(error);
        reject(error);
      });
  })

  consultaSaldoAdicionalTC = (ConsultaSaldoAdicionalTC) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/ConsultaSaldoAdicionalTC', { header: BODY_HEADER, data: ConsultaSaldoAdicionalTC })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
        //GAException(error);
        reject(error);
      });
  })

  consultaSaldoTC = (ConsultaSaldoTC) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/ConsultaSaldoTC', { header: BODY_HEADER, data: ConsultaSaldoTC })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
        //GAException(error);
        reject(error);
      });
  })

  descargarExtracto = (DescargarExtracto) => new Promise((resolve, reject) => {

    let BODY_HEADER_LOCAL = {
      idSesion: "102",
      idRequest: "123456",
      codigoEF: "csoto",
      canal: "102",
      idClienteEF: "prueba",
      ciCliente: "csoto"
    }

    axios({
      url: (API_URL_EXTRACTS + '/v1/DescargarExtracto'),
      method: 'POST',
      responseType: 'blob', // important
      data: { header: BODY_HEADER_LOCAL, data: DescargarExtracto }
    })
      .then((response) => {
        // console.log("response)", response);
        // console.log("DescargarExtracto", DescargarExtracto)
        
        if (response.status === 200) {
          if (window.ReactNativeWebView) {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
              // const base64data = reader.result;
              //eliminar data:application/pdf;base64,
              const base64data = reader.result.replace('data:application/pdf;base64,', '');
              // console.log("base64data", base64data);
              window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'download', data: base64data, fileName: 'Extracto_' + DescargarExtracto.anio + '_' + DescargarExtracto.mes + '.pdf' }));
              resolve("Descargado Correctamente");
            }
            //enviar en formato base64
          } else {
            ///*
            const contenType = response.headers['content-type'];
            //const name = response.headers['content-disposition'];
            //console.log("response)",response);
            //console.log("type",contenType);

            const blob = new Blob([response.data], { contenType });
            const link = document.createElement('a');

            link.href = window.URL.createObjectURL(blob);
            link.setAttribute('download', 'Extracto_' + DescargarExtracto.numeroCuenta + '_' + DescargarExtracto.emisor + '_' + DescargarExtracto.anio + '_' + DescargarExtracto.mes + '.pdf');
            // 3. Append to html page
            document.body.appendChild(link);
            // 4. Force download
            link.click();
            // 5. Clean up and remove the link
            link.parentNode.removeChild(link);

            resolve("Descargado Correctamente");
          }

        }
        else {
          //console.log(response);
          reject("Su extracto aun no esta disponible, favor vuelva a intentarlo mas tarde");
        }
      })

      .catch((error) => {
        //GAException(error);
        reject(error);
      });
  })

  descargarExtractoKude = (DescargarExtracto) => new Promise((resolve, reject) => {

    axios({
      url: (extract_descargar_service_api_url + '/obtenerKude'),
      method: 'POST',
      data: DescargarExtracto
    })
      .then((response) => {

        if (response.status === 200) {
          //base64
          let pdfData = response.data.DocumentoBase64;

          //decodificar base64 usando atob
          // const byteCharacters = window.atob(pdfData);
          //usar Buffer para decodificar, admite codificacion binaria mas amplia
          const byteCharacters = Buffer.from(pdfData, 'base64').toString('binary');

          //convertir a array
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }

          //convertir a blob
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'application/pdf' });

          if (window.ReactNativeWebView) {
            //enviar en formato base64
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'download', data: pdfData, fileName: 'Extracto_' + DescargarExtracto.DatosDocumento + '.pdf' }));


            resolve("Descargado Correctamente");
          } else {
            //crear link para descargar
            const link = document.createElement('a');

            link.href = window.URL.createObjectURL(blob);
            link.setAttribute('download', 'Extracto_' + DescargarExtracto.DatosDocumento + '.pdf');
            // 3. Append to html page
            document.body.appendChild(link);
            // 4. Force download
            link.click();
            // 5. Clean up and remove the link
            link.parentNode.removeChild(link);

            resolve("Descargado Correctamente");
          }

        }
        else {
          //console.log(response);
          reject("Su extracto aun no esta disponible, favor vuelva a intentarlo mas tarde");
        }
      })

      .catch((error) => {
        //GAException(error);
        reject(error);
      });
  })

  movimientosTC = (MovimientosTC) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/MovimientosTC', { header: BODY_HEADER, data: MovimientosTC })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
        //GAException(error);
        reject(error);
      });
  })

  reimpresionTC = (ReimpresionTC) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/ReimpresionTC', { header: BODY_HEADER, data: ReimpresionTC })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
        //GAException(error);
        reject(error);
      });
  })

  seguimientoAdicionalTC = (SeguimientoAdicionalTC) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/SeguimientoAdicionalTC', { header: BODY_HEADER, data: SeguimientoAdicionalTC })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
        //GAException(error);
        reject(error);
      });
  })

  seguimientoAumentoLinea = (SeguimientoAumentoLinea) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/SeguimientoAumentoLinea', { header: BODY_HEADER, data: SeguimientoAumentoLinea })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
        //GAException(error);
        reject(error);
      });
  })

  seguimientoCambioCicloTC = (SeguimientoCambioCicloTC) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/SeguimientoCambioCicloTC', { header: BODY_HEADER, data: SeguimientoCambioCicloTC })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
        //GAException(error);
        reject(error);
      });
  })

  seguimientoReimpresionTC = (SeguimientoReimpresionTC) => new Promise((resolve, reject) => {
    axios.post(API_URL + '/v1/SeguimientoReimpresionTC', { header: BODY_HEADER, data: SeguimientoReimpresionTC })
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
        //GAException(error);
        reject(error);
      });
  })

  tarjetasCredito = (TarjetasCredito) => new Promise((resolve, reject) => {

    const loadPanal = TarjetasCredito.hasOwnProperty("loadPanal") && TarjetasCredito.loadPanal === true ? true : false;

    const loadTCNoVinculadas = TarjetasCredito.hasOwnProperty("loadTCNoVinculadas") && TarjetasCredito.loadTCNoVinculadas === true ? true : false;

    const dataPrm = {
      documento: TarjetasCredito.hasOwnProperty("documento") ? TarjetasCredito.documento : "",
    }

    axios.post(API_URL + '/v1/TarjetasCredito', { header: BODY_HEADER, data: dataPrm })
      .then((response) => {
        if (response.data.header.codReturn === '0') {

          const tarjetasList = !Array.isArray(response.data.data) ? [] : response.data.data.filter((tarjeta) => {
            const tarjetaItem = toCreditCardData(tarjeta);

            // Si no se deben cargar las TC no vinculadas a DIMO
            if (!loadTCNoVinculadas) {
              if (tarjetaItem.VINCULADA_DIMO !== "S") {
                return false;
              }
            }

            // Si no se deben cargar las TC de Panal
            if (!loadPanal) {
              // Evaluamos que el emisor sea distinto a 806
              if (tarjetaItem.PROCESADOR === "806") {
                return false;
              }
            }

            return true;
          });

          localStorage.setItem('tcCabal', JSON.stringify(tarjetasList));
          resolve(tarjetasList);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
        //GAException(error);
        reject(error);
      });
  });

  getTCCABAL = () => {
    return JSON.parse(localStorage.getItem('tcCabal'));
  };

  // =================================================================
  // **************   Capa parametrica de Servicios   ****************
  // =================================================================

  // Obtiene las Preguntas de Seguridad para vincular una TC a Dimo
  getPreguntasVinculacionDimoTC = (dataPrm) => new Promise((resolve, reject) => {

    const requestData = {
      documento: dataPrm.hasOwnProperty("documento") ? dataPrm.documento : "",
      tarjetaCripto: dataPrm.hasOwnProperty("tarjetaCripto") ? dataPrm.tarjetaCripto : ""
    }

    axios.post(API_URL_CEIBO_PRMS_SP + '/tarjetaCredito/v1/preguntasVincDimoTC', requestData)
      .then((response) => {
        if (response.data.header.codReturn === '0') {

          if (response.data.data.idProceso === "-1") // Error inesperado
          {
            reject(defaultResponseError((response.data.data.sqlerrn + "").replace("ORA-", "")))
          }
          else {
            let arrayRespuestas = (Array.isArray(response.data.data.respuestas) ? Array.from(response.data.data.respuestas) : []).map(x => toRespuestasVinculacionDimoTC(x))
            let arrayPreguntas = (Array.isArray(response.data.data.preguntas) ? Array.from(response.data.data.preguntas) : []).map(x => toPreguntasVinculacionDimoTC(x));

            arrayPreguntas = arrayPreguntas.map((itemPregunta, idx) => {
              itemPregunta.respuestas = arrayRespuestas.filter(resp => resp.ID_PREGUNTA == itemPregunta.ID_PREGUNTA);
              return itemPregunta;
            })
            resolve({
              idProceso: response.data.data.idProceso,
              preguntas: arrayPreguntas
            });
          }
        } else {
          reject(defaultResponseError(response.data.header.txtReturn));
        }
      })
      .catch((error) => {
        //GAException(error);
        reject(error);
      });
  });

  // Enviar las respuestas a las Preguntas de Seguridad para vincular una TC a Dimo
  procesarRespuestasVinculacionDimoTC = (dataPrm) => new Promise((resolve, reject) => {

    let respuestasStr = "";
    let respuestas = dataPrm.hasOwnProperty("respuestas") && Array.isArray(dataPrm.respuestas) ? Array.from(dataPrm.respuestas) : [];

    respuestas
      .map(x => toRespuestasVinculacionDimoTC(x))
      .forEach((item) => {
        respuestasStr = respuestasStr + "[" + item.ID_PREGUNTA + "]:[" + item.ID_RESPUESTA + "][;]"
      });

    const requestData = {
      idProceso: dataPrm.hasOwnProperty("idProceso") ? dataPrm.idProceso : -999,
      respuestas: respuestasStr
    }

    axios.post(API_URL_CEIBO_PRMS_SP + '/tarjetaCredito/v1/respuestasVincDimoTC', requestData)
      .then((response) => {
        if (response.data.header.codReturn === '0') {

          if (response.data.data.codRetorno === "-1") // Error inesperado
          {
            reject(defaultResponseError((response.data.data.sqlerrn + "").replace("ORA-", "")))
          }
          else {
            resolve(response.data.data);
          }

        } else {
          reject(defaultResponseError(response.data.header.txtReturn));
        }
      })
      .catch((error) => {
        reject(defaultResponseError(error));
      });
  });

  // Obtiene las Preguntas de Seguridad para verificar la identidad del usuario
  getPreguntasVerificarIdentidad = (dataPrm) => new Promise((resolve, reject) => {

    const requestData = {
      documento: dataPrm.hasOwnProperty("documento") ? dataPrm.documento : "",
    }

    axios.post(account_service_api_url + '/v1/tu-eres-tu/obtenerPreguntas', requestData)
      .then((response) => {
        if (response.data.header.codReturn === '0') {

          if (response.data.data.idProceso === "-1") // Error inesperado
          {
            reject(defaultResponseError((response.data.data.sqlerrn + "").replace("ORA-", "")))
          }
          else {
            let arrayRespuestas = (Array.isArray(response.data.data.respuestas) ? Array.from(response.data.data.respuestas) : []).map(x => toRespuestasVerificarIdentidad(x))
            let arrayPreguntas = (Array.isArray(response.data.data.preguntas) ? Array.from(response.data.data.preguntas) : []).map(x => toPreguntasVerificarIdentidad(x));

            arrayPreguntas = arrayPreguntas.map((itemPregunta, idx) => {
              itemPregunta.respuestas = arrayRespuestas.filter(resp => resp.ID_PREGUNTA == itemPregunta.ID_PREGUNTA);
              return itemPregunta;
            })
            console.log({
              idProceso: response.data.data.idProceso,
              preguntas: arrayPreguntas,
              tarjetahabiente: response.data.data.tarjetahabiente
            })
            resolve({
              idProceso: response.data.data.idProceso,
              preguntas: arrayPreguntas,
              tarjetahabiente: response.data.data.tarjetahabiente
            });
          }
        } else {
          reject(defaultResponseError(response.data.header.txtReturn));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

  // Enviar las respuestas a las Preguntas de Seguridad para verificar la identidad del usuario
  procesarRespuestasVerificarIdentidad = (dataPrm) => new Promise((resolve, reject) => {

    let respuestasStr = "";
    let respuestas = dataPrm.hasOwnProperty("respuestas") && Array.isArray(dataPrm.respuestas) ? Array.from(dataPrm.respuestas) : [];

    const respuestasFormateadas = respuestas.map((item) => {
        return {idPregunta:item.ID_PREGUNTA, idRespuesta: Number(item.ID_RESPUESTA)} 
      });

    const requestData = {
      idProceso: dataPrm.hasOwnProperty("idProceso") ? dataPrm.idProceso : -999,
      respuestas: dataPrm.hasOwnProperty("respuestas") ? respuestasFormateadas : "",
      tarjetahabiente: dataPrm.hasOwnProperty("tarjetahabiente") ? dataPrm.tarjetahabiente : false
    }
    // axios.post(API_URL_CEIBO_PRMS_SP + '/dimo/v1/tu-eres-tu/validarRespuesta', requestData)
    axios.post(account_service_api_url + '/v1/tu-eres-tu/validarRespuesta', requestData)
      .then((response) => {
        if (response.data.header.codReturn === '0') {
            resolve(response.data);
        } else {
          reject(defaultResponseError(response.data.header.txtReturn));
        }
      })
      .catch((error) => {
        reject(defaultResponseError(error));
      });
  });



}

const creditCardService = new CreditCardService();

export default creditCardService;
