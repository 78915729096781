import axios from 'axios';
import TokenService from '../services/TokenService';
import { auth_service_api_url as API_URL } from '../services/backendApiService';
import GAException from '../components/GAException';

const instance = axios.create();

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token && !config.url.includes('/ws-user-kyc') && !config.url.includes('/kyc')) {
      config.headers.Authorization = `Bearer ${token}`; // for Spring Boot back-end
      // config.headers["x-access-token"] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => Promise.reject(error)
);

const myInterval = setInterval(() => {
  const renewToken = localStorage.getItem('renewToken');
  if (renewToken > 0) {
    localStorage.setItem('renewToken', renewToken - 5);
  }
}, 5000);

instance.interceptors.response.use(
  async (response) => {
    const renewToken = localStorage.getItem('renewToken');
    const originalConfig = response.config;
    if (!originalConfig.url.includes('inicioSesion')
      && !originalConfig.url.includes('/v1/validarToken')
      && !originalConfig.url.includes('/cerrarSesion')
      && !originalConfig.url.includes('/verificarUsuario')
      && !originalConfig.url.includes('/registrarActividadFrontend')
      && renewToken
      && renewToken === '0') {
      localStorage.setItem('renewToken', 25);
      await axios.post(
        `${API_URL}/v1/refreshToken`, {}, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${TokenService.getLocalAccessToken()}`
          }
        }
      )
        // eslint-disable-next-line consistent-return
        .then((res) => {
          if (res.data.header.codReturn === '0') {
            localStorage.setItem('renewToken', 25);
            if (res.data.data) {
              console.log(`Se actualiza el tocken -> ${res.data.data.token}`);
              TokenService.updateLocalAccessToken(res.data.data.token);
            }
          }
        })
        .catch((error) => {
          localStorage.setItem('renewToken', 0);
          GAException(error);
        });
    }
    return response;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== 'inicioSesion' && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await instance.post('/v1/refreshToken', {}, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${TokenService.getLocalAccessToken()}`
            }
          });

          const { accessToken } = rs.data;
          TokenService.updateLocalAccessToken(accessToken);

          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);
export default instance;
