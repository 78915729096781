import React, { useEffect, useState } from 'react';

import ReactGA from 'react-ga';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import {
  createStyles,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Auth from 'src/components/Auth';
import CookiesNotification from 'src/components/CookiesNotification';
//import SettingsNotification from 'src/components/SettingsNotification';
//import GoogleAnalytics from 'src/components/GoogleAnalytics';
import ScrollReset from 'src/components/ScrollReset';
import useSettings from 'src/hooks/useSettings';
import { createTheme, ThemeConfigurator } from 'src/theme';
import Routes from 'src/Routes';
//import RoutesDev from 'src/RoutesDev';

import withClearCache from './ClearCache';
import { useDispatch, useSelector } from 'react-redux';
import { themeRegister } from './actions/themeActions';
import axios from 'axios';
import { themeConfig } from './theme/constans';
import { VERSION_APP, VERSION_CACHE, VERSION_CODE } from './constants';
import SplashScreen from './components/SplashScreen';
import { handleGetStorage } from './hooks/validationParticipantes';
import { handdleGetStorage, handdleSetStorage } from './hooks/useSocioValidacion';

const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

// Initialize google analytics page view tracking
ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID); // Aqui pones tu identificador de cuenta de Google Analytics

history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%'
      },
      body: {
        height: '100%',
        width: '100%'
      },
      '#root': {
        height: '100%',
        width: '100%'
      }
    }
  })
);

// const ClearCacheComponent = withClearCache();

const refreshCacheAndReload = () => {
  try {
    let version = localStorage.getItem('versionCache');

    console.log("Se actualziara el cache de la app", version, VERSION_CACHE.appVersion);
    if (version !== VERSION_CACHE.appVersion || version === null) {
      if ('caches' in window) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
          for (const name of names) {
            caches.delete(name);
          }
        });
      }
      // delete browser cache and hard reload
      window.location.reload(true);
      localStorage.setItem("versionCache", VERSION_CACHE.appVersion);
    }else{
      console.log("No se actualizara el cache de la app")
    }
  } catch (error) {
    console.log("Error al actualizar el cache de la app", error)
  }

}


function MainApp() {
  useStyles();
  const dispatch = useDispatch();
  const [themeConfigRemote, setThemeConfigRemote] = useState(null);
  const { settings } = useSettings();
  const notistackRef = React.createRef();

  const handleVersionApp = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'versionApp', data: VERSION_APP, versionWeb: VERSION_CODE }));
    }
  }

  useEffect(() => {
    async function fetchThemeConfig() {
      try {
        const themeConfig = await handleGetStorage();
        setThemeConfigRemote(themeConfig);
        handleVersionApp();
        refreshCacheAndReload();
      } catch (error) {
        console.log("Error al obtener el themeConfig", error)
      }
      }
      fetchThemeConfig();
  }, []);

  return (
    themeConfigRemote === null || !themeConfigRemote ? <SplashScreen/> :
    <ThemeProvider theme={createTheme({settings,themeConfigRemote})}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider
            ref={notistackRef}
            maxSnack={4}
            onClose={(event, reason) => {
              if (reason === 'clickaway') {
                notistackRef.current.closeSnackbar();
              }
            }}
            autoHideDuration={20000}
          >
            <Router history={history}>
              <Auth>
                <ScrollReset />
                {/*<GoogleAnalytics />*/}
                <CookiesNotification />
                {/*<SettingsNotification />*/}
                <Routes />
              </Auth>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}

function App() {
  return <MainApp/>;
}

export default App;
