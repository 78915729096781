import axios from '../utils/axios';

import {
  bank_service_api_url,
  dimo_service_api_url as API_URL_DIMO_SERVICE,
  operations_service_api_url as API_URL_OPS,
  participants_service_api_url as API_URL_PARTICIPANTES,
  prepaidcard_service_api_url as API_URL_CARGOTX,
  transaction_service_api_url as API_URL,
  ceibo_prm_sp_services_api_url as API_URL_CEIBO_PRM_SP,
} from 'src/services/backendApiService';

let BODY_HEADER = {};

const direct_api_tar = false;

if (direct_api_tar) {
  // API TARJETA HABIENTES DIRECTAMENTE
  BODY_HEADER = {
    idSesion: '102',
    idRequest: '123456',
    codigoEF: 'csoto',
    canal: '102',
    idClienteEF: 'prueba',
    ciCliente: 'csoto'
  };
} else {
  // BACKEND DE DIMO
  BODY_HEADER = {
    appVersion: '1.0.2',
    token: 'prueba',
    user: 'csoto'
  };
}

const HEADERS = () => {
  const headers = {
    'Content-Type': 'application/json',
    // 'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
  };

  return headers;
};

const CONFIG = {
  headers: {
    'Content-Type': 'application/json',
    // 'Authorization': 'Token pruebalocal'
  }
};

const tiposTransaccion = {
  COMP: 'COMP',
  // AVAN : 'AVAN',
  // EXTR : 'EXTR',
  PAGO: 'PAGO',
  TRAN: 'TRAN',
  AVAN: 'AVAN',
  DEPO: 'DEPO',
  EXTR: 'EXTR',
};

const codigosServicio = {
  COMPRA: 'COMPRA',
  CAJADELA: 'CAJADELA',
  CAJEXTRA: 'CAJEXTRA',
  RUEDA: 'RUEDA',
  APORTE: 'APORTE',
  SOLIDARIDAD: 'SOLIDARIDAD',
  SERVICIO: 'SERVICIO',
  PAGOCR: 'PAGOCR',
  PAGOAHOPRO: 'PAGOAHOPRO',
  COUNTRY: 'COUNTRY',
  SEDE: 'SEDE',
  INCOOP: 'INCOOP',
  GASTOMANT: 'GASTOMANT',
  APORTEEXT: 'APORTEEXT',

  TRANSFER: 'TRANSFER',
  BCADELAN: 'BCADELAN',
  ATMADELA: 'ATMADELA',
  ATMADELATCPANAL: 'ATMADELATCPANAL',
  BCDEPOSI: 'BCDEPOSI',
  BCEXTRAC: 'BCEXTRAC',
  ATMEXTRA: 'ATMEXTRA',
  BCPAGOTC: 'BCPAGOTC',
  PAGOBC: 'PAGOBC',
  COMPRATP: 'COMPRATP',
  TRANDIMO: 'TRANDIMO',
  CAJDEPOS: 'CAJDEPOS',
  CAJPAGOTC: 'CAJPAGOTC',
  PAGOTC: 'PAGOTC',
  PAGOINTE: 'PAGOINTE',
  TRANINTE: 'TRANINTE',

  PAGOTCPANAL: 'PAGOTCPANAL',
};

const tiposMovimiento = {
  ACREDITAR: 'ACREDITAR',
};

export const tiposCuentaOrigen = {
  TPO: 'TPO',
  TCO: 'TCO',
  CCAHOO: 'CCAHOO',
  BANCOO: 'BANCOO', // Cuenta bancaria origen
};

export const tiposCuentaDestino = {
  CCOMD: 'CCOMD',
  BANCOD: 'BANCOD', // Cuenta bancaria destino
  CCAHOD: 'CCAHOD', // Cuenta ahorro destino
  TPD: 'TPD', // Tarjeta Prepaga Dimo
  TCD: 'TCD',

  // Nuevos, agregados para el pago de Prestamos y Obligaciones Cooperativas
  CRDD: 'CRDD', // Cuenta Credito de Cooperativa
  OBLGD: 'OBLGD', // Obligacion Cooperativa

  // Nuevos, agregados para el pago de servicios
  BCD: 'BCD', // Boca de Cobranza (?)
  RCD: 'RCD' // Red de Cobranza
};

const monedas = {
  GS: 'GUA'
};

export const transaccionesSicoop = {

  COMP: {
    value: tiposTransaccion.COMP,
    servicios: {
      COMPRA: codigosServicio.COMPRA,
      COMPRATP: codigosServicio.COMPRATP
    }
  },

  AVAN: {
    value: tiposTransaccion.AVAN,
    servicios: {
      CAJADELA: codigosServicio.CAJADELA,
      BCADELAN: codigosServicio.BCADELAN,
      ATMADELA: codigosServicio.ATMADELA,
      ATMADELATCPANAL: codigosServicio.ATMADELATCPANAL
    }
  },

  DEPO: {
    value: tiposTransaccion.DEPO,
    servicios: {
      BCDEPOSI: codigosServicio.BCDEPOSI,
      BCADELAN: codigosServicio.CAJDEPOS
    }
  },

  EXTR: {
    value: tiposTransaccion.EXTR,
    servicios: {
      CAJEXTRA: codigosServicio.CAJEXTRA,
      BCEXTRAC: codigosServicio.BCEXTRAC,
      ATMEXTRA: codigosServicio.ATMEXTRA,
    }
  },

  PAGO: {
    value: tiposTransaccion.PAGO,
    servicios: {
      RUEDA: codigosServicio.RUEDA,
      SERVICIO: codigosServicio.SERVICIO,
      PAGOCR: codigosServicio.PAGOCR,
      PAGOAHOPRO: codigosServicio.PAGOAHOPRO,

      BCPAGOTC: codigosServicio.BCPAGOTC,
      PAGOBC: codigosServicio.PAGOBC,
      CAJPAGOTC: codigosServicio.CAJPAGOTC,
      PAGOTC: codigosServicio.PAGOTC,
      PAGOINTE: codigosServicio.PAGOINTE,
      PAGOTCPANAL: codigosServicio.PAGOTCPANAL,

    }
  },

  TRAN: {
    value: tiposTransaccion.TRAN,
    servicios: {
      TRANSFER: codigosServicio.TRANSFER,
      TRANDIMO: codigosServicio.TRANDIMO,
      TRANINTE: codigosServicio.TRANINTE,
    }
  },
};

export const keyDatosAdicionales = {
  telefonoDestino: { key: 'telefonoDestino', tipo: 'number' },
  favoritoDestino: { key: 'favoritoDestino', tipo: 'boolean' },
  favoritoOrigen: { key: 'favoritoOrigen', tipo: 'boolean' },
  tokenIdTrx: { key: 'tokenIdTrx', tipo: 'string' },
  nuevoUsuarioDIMO: { key: 'nuevoUsuarioDIMO', tipo: 'boolean' },

  // Keys para enviar los codigos de Participantes Originales de la trx
  codParticipanteOrigen1A: { key: 'codParticipanteOrigen1A', tipo: 'number' },
  codParticipanteDestino1A: { key: 'codParticipanteDestino1A', tipo: 'number' },

  // Key de Dato adicional de PAGO de SERVICIOS
  PAYSERVICE: { key: 'PAYSERVICE', tipo: 'json_string' },

  // Keys de Datos Adicionales de Cargos
  cargoCostoId: { key: 'cargoCostoId', tipo: 'number' },
  cargoMontoOrigen: { key: 'cargoMontoOrigen', tipo: 'number' },
  cargoMontoDestino: { key: 'cargoMontoDestino', tipo: 'number' },
  cargoPorcentajeIva: { key: 'cargoPorcentajeIva', tipo: 'number' },
  cargoRubro: { key: 'cargoRubro', tipo: 'number' },
  cargoComercio: { key: 'cargoComercio', tipo: 'number' }, // getComercioPuenteParticipante 1600

  // Keys de Datos Adicionales para Emisores de TC
  codEmisorTCDestino: { key: 'codEmisorTCDestino', tipo: 'number' },
  codEmisorTC: { key: 'codEmisorTC', tipo: 'number' },
  descEmisorTCDestino: { key: 'descEmisorTCDestino', tipo: 'string' },

  // Keys de Datos Adicionales para las transacciones con Bancard
  bancardToEntityNumber: { key: 'toEntity', tipo: 'string' },
  bancardAccountNumber: { key: 'BANCARDACCOUNTNUMBER', tipo: 'string' },
  bancardEntityIsCoop: { key: 'bancardEntityIsCoop', tipo: 'boolean' },
  bancardEntityDesc: { key: 'bancardEntityDesc', tipo: 'string' },

  // Key de Motivo de la Operacion Transaccional
  motivoOperacion: { key: 'motivoOperacion', tipo: 'string' },
  tokenConfirmacion: { key: 'tokenConfirmacion', tipo: 'string' },

  // Compras en Cuotas (Solo valido con TP y TC)
  compraCuotasNro: { key: 'compraCuotasNro', tipo: 'number' },

  // Participantes del Sicoop. Tarjeta Puente
  participanteOriTarjetaPuente: { key: 'participanteOriTarjetaPuente', tipo: 'string' },

  // Keys Adicionales para Reportes dentro del Sicoop
  destinoFondosSicoop: { key: 'destinoFondos', tipo: 'string' },
  codEntidadDestinoSicoop: { key: 'codEntidad', tipo: 'string' },
  nombreEntidadDestinoSicoop: { key: 'nombreEntidad', tipo: 'string' },

  // Codigo de Comercio de Pago de TC
  comercioPagoTC: { key: 'comercioPagoTC', tipo: 'number' },
  // Codigo de Comercio de Adelanto a Utilizar para las TRANDIMO
  comercioAdelTranDIMO: { key: 'comercioAdelTranDIMO', tipo: 'number' },

  // Datos adicionales par Compra Qr COmercio
  idTransaccionQr: { key: 'idTransaccionQr', tipo: 'number' },

  // Utilizado para especificar el TIPO de Cuenta de PAGO de SERVICIO
  tipoCuentaPagoServicio: { key: 'tipoCuentaPagoServicio', tipo: 'number' },

  // Compra con Tarjeta puente
  tarjetaPuente: { key: 'tarjetaPuente', tipo: 'number' }
};

export const toParticipanteData = (itemPrm) => {
  const item = (itemPrm && itemPrm !== null) ? itemPrm : {};

  const participante = {
    codParticipante: item.hasOwnProperty('codParticipante') ? item.codParticipante : '',
    descripcion: item.hasOwnProperty('descripcion') ? item.descripcion : '',
    isCabal: !!((item.hasOwnProperty('isCabal') && item.isCabal === true)),
    isPanal: !!((item.hasOwnProperty('isPanal') && item.isPanal === true)),
    isCoop: !!((item.hasOwnProperty('isCoop') && item.isCoop === true)),
    logoUrl: item.hasOwnProperty('logoUrl') ? item.logoUrl : '',
    datosPuenteo: (item.hasOwnProperty('datosPuenteo') && Array.isArray(item.datosPuenteo)) ? Array.from(item.datosPuenteo) : [],
    tiposDeCuentas: (item.hasOwnProperty('tiposDeCuentas') && Array.isArray(item.tiposDeCuentas)) ? Array.from(item.tiposDeCuentas) : [],
    validarDisponibleFlag: (item.hasOwnProperty('validarDisponibleFlag') && item.validarDisponibleFlag) === true,
  };

  return participante;
};

export const toMensajeSicoop1A = (itemPrm) => {
  const item = (itemPrm && itemPrm !== null) ? itemPrm : {};

  const mensaje1A = {
    idTxOriginante: item.hasOwnProperty('idTxOriginante') ? item.idTxOriginante : '',
    codOriginante: item.hasOwnProperty('codOriginante') ? item.codOriginante : '',
    codDestinatario: item.hasOwnProperty('codDestinatario') ? item.codDestinatario : '',
    moneda: item.hasOwnProperty('moneda') ? item.moneda : '',
    monto: item.hasOwnProperty('monto') ? item.monto : '',
    tipoTransaccion: item.hasOwnProperty('tipoTransaccion') ? item.tipoTransaccion : '',
    tipoMovimiento: item.hasOwnProperty('tipoMovimiento') ? item.tipoMovimiento : '',
    tipoCuentaOrigen: item.hasOwnProperty('tipoCuentaOrigen') ? item.tipoCuentaOrigen : '',
    numCuentaOrigen: item.hasOwnProperty('numCuentaOrigen') ? item.numCuentaOrigen : '',
    tipoCuentaDestino: item.hasOwnProperty('tipoCuentaDestino') ? item.tipoCuentaDestino : '',
    numCuentaDestino: item.hasOwnProperty('numCuentaDestino') ? item.numCuentaDestino : '',
    glosa: item.hasOwnProperty('glosa') ? item.glosa : '',
    ciOriginante: item.hasOwnProperty('ciOriginante') ? item.ciOriginante : '',
    titularOriginante: item.hasOwnProperty('titularOriginante') ? item.titularOriginante : '',
    ciDestinatario: item.hasOwnProperty('ciDestinatario') ? item.ciDestinatario : '',
    titularDestinatario: item.hasOwnProperty('titularDestinatario') ? item.titularDestinatario : '',
    origenFondos: item.hasOwnProperty('origenFondos') ? item.origenFondos : '',
    codEmpresa: item.hasOwnProperty('codEmpresa') ? item.codEmpresa : '',
    codServicio: item.hasOwnProperty('codServicio') ? item.codServicio : '',
    codCajaCajero: item.hasOwnProperty('codCajaCajero') ? item.codCajaCajero : '',
    montoCargo: item.hasOwnProperty('montoCargo') ? item.montoCargo : '',
    aditionalParameters: []
  };

  mensaje1A.aditionalParameters = mensaje1A.aditionalParameters.concat(
    Array.isArray(item.datosAdicionales) ? item.datosAdicionales : []
  );

  mensaje1A.aditionalParameters = mensaje1A.aditionalParameters.concat(
    Array.isArray(item.aditionalParameters) ? item.aditionalParameters : []
  );

  return mensaje1A;
};

export const toBeneficiarioTrx = (itemData) => {
  const item = (itemData && itemData !== null) ? itemData : {};

  const beneficiario = {
    idBeneficiario: item.hasOwnProperty('idBeneficiario') ? (`${item.idBeneficiario}`) : '',
    ciBeneficiario: item.hasOwnProperty('ciBeneficiario') ? (`${item.ciBeneficiario}`) : '',
    nombreCompleto: item.hasOwnProperty('nombreCompleto') ? (`${item.nombreCompleto}`) : '',
    nroCuenta: item.hasOwnProperty('nroCuenta') ? (`${item.nroCuenta}`) : '',
    tipoCuenta: item.hasOwnProperty('tipoCuenta') ? (`${item.tipoCuenta}`) : '',
    favorito: !!((item.hasOwnProperty('favorito') && item.favorito)),
    celularBeneficiario: (item.hasOwnProperty('celularBeneficiario') && item.celularBeneficiario !== null) ? (`${item.celularBeneficiario}`) : '',
    codParticipante: item.hasOwnProperty('codParticipante') ? (`${item.codParticipante}`) : '',
    flagTercero: !!((item.hasOwnProperty('flagTercero') && item.flagTercero === true)),

    // Nuevo campo para transferencias a Bancard
    codigoEntidadBancard: item.hasOwnProperty('codigoEntidadBancard') ? item.codigoEntidadBancard : '',
    descripcionEntidadBancard: item.hasOwnProperty('descripcionEntidadBancard') ? item.descripcionEntidadBancard : '',

    // Campo adicional que no viene en el API
    logoUrl: item.hasOwnProperty('logoUrl') ? item.logoUrl : '',
    descripcionParticipante: item.hasOwnProperty('descripcionParticipante') ? (`${item.descripcionParticipante}`) : '',
  };

  if (beneficiario.celularBeneficiario === null || isNaN(Number(beneficiario.celularBeneficiario))) {
    beneficiario.celularBeneficiario = '0000000000';
  }

  if (beneficiario.descripcionParticipante === '') {
    beneficiario.descripcionParticipante = transactionService.getParticipanteByCodigo(beneficiario.codParticipante).descripcion;
  }

  return beneficiario;
};

export const toMotivoTransferencia = (itemData) => {
  const item = (itemData && itemData !== null) ? itemData : {};

  const motivo = {
    idMotivo: item.hasOwnProperty('idMotivo') ? (`${item.idMotivo}`) : '',
    descripcionMotivo: item.hasOwnProperty('descripcionMotivo') ? (`${item.descripcionMotivo}`) : '',
  };

  return motivo;
};

export const getDatoAdicionalValueFrom1A = (data1A, keyDatoAdicional) => {
  const data1APrm = data1A || {};

  if (
    data1APrm.hasOwnProperty('aditionalParameters')
    && Array.isArray(data1APrm.aditionalParameters)
  ) {
    for (let i = 0; i < data1APrm.aditionalParameters.length; i++) {
      const param = data1APrm.aditionalParameters[i];
      if (param.key === keyDatoAdicional) {
        return param.value;
      }
    }
  }

  return '';
};

export const toUltimaOperacionTrxItem = (item) => {
  const itemTrx = {
    operacionId: item.hasOwnProperty('operacionId') ? item.operacionId : '',
    codigoUuid: item.hasOwnProperty('codigoUuid') ? (`${item.codigoUuid}`) : '',
    moneda: item.hasOwnProperty('moneda') ? item.moneda : '',
    importe: item.hasOwnProperty('importe') ? item.importe : '',
    glosa: item.hasOwnProperty('glosa') ? item.glosa : '',
    cuentaDestino: item.hasOwnProperty('cuentaDestino') ? item.cuentaDestino : '',
    tipoCuentaDestino: item.hasOwnProperty('tipoCuentaDestino') ? item.tipoCuentaDestino : '',
    favorito: item.hasOwnProperty('favorito') ? item.favorito : '',
    estado: item.hasOwnProperty('estado') ? item.estado : '',
    nombreCompleto: item.hasOwnProperty('nombreCompleto') ? item.nombreCompleto : '',
    ciBeneficiario: item.hasOwnProperty('ciBeneficiario') ? item.ciBeneficiario : '',
    celularBeneficiario: item.hasOwnProperty('celularBeneficiario') ? item.celularBeneficiario : '',
    tipo: item.hasOwnProperty('tipo') ? item.tipo : '',
    request: item.hasOwnProperty('request') ? item.request : {},
    requestHeader: {},
    requestData: {},
    flagTercero: item.hasOwnProperty('flagTercero') ? item.flagTercero : '',
    codigoServicio: item.hasOwnProperty('codigoServicio') ? item.codigoServicio : '',
    codParticipanteOrigen: item.hasOwnProperty('codParticipanteOrigen') ? (`${item.codParticipanteOrigen}`) : '',
    descParticipanteOrigen: item.hasOwnProperty('descParticipanteOrigen') ? (`${item.descParticipanteOrigen}`) : '',
    codParticipanteDestino: item.hasOwnProperty('codParticipanteDestino') ? (`${item.codParticipanteDestino}`) : '',
    descParticipanteDestino: item.hasOwnProperty('descParticipanteDestino') ? (`${item.descParticipanteDestino}`) : '',
    foperacion: item.hasOwnProperty('foperacion') ? item.foperacion : '',
    hoperacion: (item.hasOwnProperty('hoperacion') ? (`${item.hoperacion}`) : '').replace(/-/g, ':'), // Hora Operacion (hh-mi-ss To hh:mi:ss)

  };

  // La propiedad request esta compuesto dentro de un String de la siguiente manereaa: "{ header:{...}, data: {...}}"
  // Extraemos el "header" y el "data"
  const requestJson = JSON.parse(itemTrx.request);
  const requestHeaderJson = requestJson.hasOwnProperty('header') ? requestJson.header : {};
  const requestDataJson = requestJson.hasOwnProperty('data') ? requestJson.data : {};

  itemTrx.requestHeader = requestHeaderJson;
  itemTrx.requestData = toMensajeSicoop1A(requestDataJson);

  if (itemTrx.celularBeneficiario === null || isNaN(Number(itemTrx.celularBeneficiario))) {
    itemTrx.celularBeneficiario = '0000000000';
  }

  return itemTrx;
};

export const defaultResponseError = (msgError, msgErrorAlt) => {
  const msgErrorAlternativo = (msgErrorAlt && msgErrorAlt !== null && msgErrorAlt !== undefined && typeof msgErrorAlt === 'string')
    ? msgErrorAlt
    : 'Ocurrió un error inesperado';
  const responseDefault = {
    error: {
      message: (msgError && msgError !== null && msgError !== undefined && typeof msgError === 'string') ? msgError : msgErrorAlternativo
    }
  };

  return responseDefault;
};

export const toDefaultResponseError = (itemError, msgErrorAlt) => {
  let msgError = (msgErrorAlt && msgErrorAlt !== null && msgErrorAlt !== undefined && typeof msgErrorAlt === 'string')
    ? msgErrorAlt : 'Ocurrió un error inesperado';

  if (
    itemError
    && itemError !== null
    && itemError !== undefined
    && itemError.hasOwnProperty('error')
    && itemError.error.hasOwnProperty('message')
    && itemError.error.message !== ''
  ) {
    msgError = itemError.error.message;
  }

  const responseDefault = {
    error: {
      message: msgError
    }
  };

  return responseDefault;
};

class TransactionService {
  loadParticipantes = () => new Promise((resolve, reject) => {
    axios.get(`${API_URL_PARTICIPANTES}/v1/getDatosParticipantes`, { headers: HEADERS() })
      .then((response) => {
        if (response.data.data && Array.isArray(response.data.data) && response.data.data.length > 0) {
          const datosTrx = {
            funcionalidad: [
              {
                tipoTrx: 'TRANSFERCOOP',
                origen: '0',
                destino: '0',
              },
              {
                tipoTrx: 'TRANSFERBILLE',
                origen: '0',
                destino: '0',
              },
              {
                tipoTrx: 'TRANSFERBANCOS',
                origen: '0',
                destino: '0',
              },
              {
                tipoTrx: 'TRANSFERDIMO',
                origen: '0',
                destino: '0',
              },
              {
                tipoTrx: 'PAGOS',
                origen: '0',
                destino: '0',
              },
              {
                tipoTrx: 'PAGOSSERVICIOS',
                origen: '0',
                destino: '0',
              },
              {
                tipoTrx: 'PAGOSCABALTC',
                origen: '0',
                destino: '0',
              },
              {
                tipoTrx: 'PAGOSCOOPCUENTAS',
                origen: '0',
                destino: '0',
              }
            ]
          };

          const result = [];

          if (response.data.data.length) {
            response.data.data.forEach((element) => {
              const dato = datosTrx;
              element.datosTrx = dato;
              result.push(element);
            });
          }

          this.obtenerParticipantesTiposCuentas()
            .then((responseTiposCtas) => {
              if (Array.isArray(response.data.data)) {
                response.data.data.forEach((element) => {
                  // Creamos una nueva propiedad "tiposDeCuentas"
                  element.tiposDeCuentas = [];
                  if (Array.isArray(responseTiposCtas)) {
                    responseTiposCtas.forEach((itemPartiTipoCta) => {
                      if (element.codParticipante === itemPartiTipoCta.codParticipante) {
                        element.tiposDeCuentas.push({
                          tipoCuenta: itemPartiTipoCta.tipoCuenta,
                          formato: itemPartiTipoCta.cuentaFormato,
                          ayudaFormato: itemPartiTipoCta.ayudaFormato,
                        });
                      }
                    });
                  }
                });
              }

              this.setSicoopsParticipants(response.data.data);
              resolve(response.data.data);
            })
            .catch(() => {
              console.log('Error al obtener los tipos de cuentas por participante');
              this.setSicoopsParticipants(response.data.data);
              resolve(response.data.data);
            });
        } else {
          // console.log('participantes incorrectos:' + response);
          this.setSicoopsParticipants(null);
          reject();
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  });

  setSicoopsParticipants = (data) => {
    // console.log('sicoopParticipants', data);
    localStorage.setItem('sicoopParticipants', JSON.stringify(data)); // TODO sacar el "_data", solo para pruebas si no se pudo obtener el participante
  }

  getParticipantes = () => {
    const participantsData = JSON.parse(localStorage.getItem('sicoopParticipants'));
    return (Array.isArray(participantsData) ? Array.from(participantsData) : []).map((x) => toParticipanteData(x));
  }

  // Retorna los participantes que son Cooperativas
  getParticipantesCooperativas = () => {
    const participantesCoop = [];
    const participantes = this.getParticipantes();

    participantes.forEach((p) => {
      if (p.isCabal !== true && p.isPanal !== true && p.isCoop === true) {
        participantesCoop.push(p);
      }
    });

    return participantesCoop;
  }

  getParticipantesCooperativasByCodigo = (participante) => {
    const participantesCoop = [];
    const participantes = this.getParticipantes();

    participantes.forEach((p) => {
      if (p.isCabal !== true && p.isCoop === true && p.codParticipante === participante) {
        participantesCoop.push(p);
      }
    });

    return participantesCoop;
  }

  isParticipanteCabal = (codParticipante) => {
    let isCabal = false;

    const participantes = this.getParticipantes();

    participantes.forEach((p) => {
      if (p.isCabal && p.codParticipante === codParticipante) {
        isCabal = true;
      }
    });

    return isCabal;
  }

  isParticipantePanal = (codParticipante) => {
    let isPanal = false;

    const participantes = this.getParticipantes();

    participantes.forEach((p) => {
      if (p.isPanal && p.codParticipante === codParticipante) {
        isPanal = true;
      }
    });

    return isPanal;
  }

  isEmisorPanal = (codEmisor) => {
    let isPanal = false;

    const participanteEmisor = this.getParticipanteEmisor(codEmisor);

    if (this.isParticipantePanal(participanteEmisor.codParticipante)) {
      isPanal = true;
    }
    return isPanal;
  }

  getParticipanteCabal = () => {
    let cabalParticipante = null;

    const participantes = this.getParticipantes();

    // console.log('participantes', participantes)

    participantes.forEach((p) => {
      if (p.isCabal) {
        cabalParticipante = p;
      }
    });

    return cabalParticipante;
  }

  getParticipantePanal = () => {
    let panalParticipante = null;

    const participantes = this.getParticipantes();

    // console.log('participantes', participantes)

    participantes.forEach((p) => {
      if (p.isPanal) {
        panalParticipante = p;
      }
    });

    return panalParticipante;
  }

  existeParticipantePanal = () => (this.getParticipantePanal() !== null)

  getParticipanteByCodigo = (codParticipante) => {
    let cabalParticipante = {
      codParticipante: '',
      descripcion: '',
      isCabal: false,
      isCoop: false,
      logoUrl: '',
      validarDisponibleFlag: true,
    };

    const participantes = this.getParticipantes();

    if (Array.isArray(participantes)) {
      participantes.forEach((p) => {
        if (p.codParticipante === codParticipante) {
          cabalParticipante = p;
        }
      });
    }

    // console.log('cabalParticipante', cabalParticipante);

    return cabalParticipante;
  }

  getParticipanteBancard = () => {
    let bancoParticipante = {
      codParticipante: '',
      descripcion: '',
      isCabal: false,
      isCoop: false,
      logoUrl: '',
      validarDisponibleFlag: true,
    };

    const participantes = this.getParticipantes();

    participantes.forEach((p) => {
      if (!p.isCabal && !p.isCoop && p.descripcion === 'BANCARD') {
        bancoParticipante = p;
      }
    });

    return bancoParticipante;
  }

  getParticipanteCabalByEmisor = (emisor) => {
    let datoEmisor = null;

    const participantesCabal = this.getParticipanteCabal();

    participantesCabal.datosPuenteo.forEach((p) => {
      if (p.emisor === emisor) {
        datoEmisor = p;
      }
    });

    return datoEmisor;
  }

  getParticipanteEmisor = (emisor) => {
    const participanteCabal = this.getParticipanteCabal();

    let participanteEmisor = null;
    let codParticipanteEmisor = null;

    if (participanteCabal !== null) {
      participanteCabal.datosPuenteo.forEach((p) => {
        if (p.emisor === emisor) {
          codParticipanteEmisor = p.codParticipante;
        }
      });
    }

    if (codParticipanteEmisor !== null) {
      const participantes = this.getParticipantes();
      participantes.forEach((p) => {
        if (p.codParticipante === codParticipanteEmisor) {
          participanteEmisor = p;
        }
      });
    }

    return participanteEmisor || participanteCabal;
  }

  getParticipanteDestinoPagoTCEmisor = (emisor) => {
    const participanteCabal = toParticipanteData(this.getParticipanteCabal());
    const participantePanal = toParticipanteData(this.getParticipantePanal());

    // Por defecto, siempre es CABAL
    let participanteEmisor = participanteCabal;

    if (participanteCabal !== null) {
      participanteCabal.datosPuenteo.forEach((p) => {
        // SOLO Para Pago de TC PANAL, sale por un participante distinto a CABAL
        // Si el emisor corresponde y su participante configurado es el Participante Panal
        if (p.emisor === emisor && p.codParticipante === participantePanal.codParticipante) {
          // asignamos el Participante PANAL
          participanteEmisor = participantePanal;
        }
      });
    }

    return participanteEmisor;
  }

  getParticipanteOrigenTrxTC = (emisor) => {
    const participanteCabal = toParticipanteData(this.getParticipanteCabal());
    const participantePanal = toParticipanteData(this.getParticipantePanal());

    // Por defecto, siempre es CABAL
    let participanteEmisor = participanteCabal;

    if (participanteCabal !== null) {
      participanteCabal.datosPuenteo.forEach((p) => {
        // SOLO Para transacciones desde TC PANAL, sale por un participante distinto a CABAL
        // Si el emisor corresponde y su participante configurado es el Participante Panal
        if (p.emisor === emisor && p.codParticipante === participantePanal.codParticipante) {
          // asignamos el Participante PANAL
          participanteEmisor = participantePanal;
        }
      });
    }

    return participanteEmisor;
  }

  getTarjetaPuenteParticipante = (codParticipante) => {
    const participanteCabal = this.getParticipanteCabal();
    let tarjetaPuente = '';

    if (participanteCabal !== null) {
      participanteCabal.datosPuenteo.forEach((p) => {
        if (p.codParticipante === codParticipante) {
          tarjetaPuente = p.tarjetaPuente;
        }
      });
    }

    return tarjetaPuente;
  }

  getComercioPuenteParticipante = (codParticipante) => {
    const participanteCabal = this.getParticipanteCabal();

    let comercioPuente = null;

    if (participanteCabal !== null) {
      participanteCabal.datosPuenteo.forEach((p) => {
        if (p.codParticipante === codParticipante) {
          comercioPuente = p.comercioPuente;
        }
        // console.log('p', p);
      });
    }

    return comercioPuente || '';
  }

  getComercioPagoParticipante = (codParticipante) => {
    const participanteCabal = this.getParticipanteCabal();

    const comercioPago = null;

    // Fix. No se debe obtener el codigo de Comercio de Pago del Participante, desde el Datos puenteo
    /*
    if (participanteCabal !== null) {
      participanteCabal.datosPuenteo.forEach(p => {
        if (p.codParticipante === codParticipante) {
          comercioPago = p.comercioPago;
        }
      });
    }
    */

    return comercioPago || '805001';
  }

  getComercioAdelantoParticipante = (codParticipante) => {
    const participanteCabal = this.getParticipanteCabal();

    let comercioAdel = null;

    if (participanteCabal !== null) {
      participanteCabal.datosPuenteo.forEach((p) => {
        if (p.codParticipante === codParticipante) {
          comercioAdel = p.comercioAdelanto;
        }
      });
    }

    return comercioAdel || '';
  }

  getTarjetaPuenteEmisor = (emisor) => {
    const participanteCabal = this.getParticipanteCabal();

    let tarjetaPuente = null;

    if (participanteCabal !== null) {
      participanteCabal.datosPuenteo.forEach((p) => {
        if (p.emisor === emisor) {
          tarjetaPuente = p.tarjetaPuente;
        }
      });
    }

    return tarjetaPuente;
  }

  getComercioPuenteEmisor = (emisor) => {
    const participanteCabal = this.getParticipanteCabal();

    let comercioPuente = null;

    if (participanteCabal !== null) {
      participanteCabal.datosPuenteo.forEach((p) => {
        if (p.emisor === emisor) {
          comercioPuente = p.comercioPuente;
        }
      });
    }

    return comercioPuente || '';
  }

  getComercioPagoEmisor = (emisor) => {
    const participanteCabal = this.getParticipanteCabal();

    let comercioPago = null;

    if (participanteCabal !== null) {
      participanteCabal.datosPuenteo.forEach((p) => {
        if (p.emisor === emisor) {
          comercioPago = p.comercioPago;
        }
      });
    }

    return comercioPago || (emisor !== '40' ? '805001' : '806001');
  }

  getComercioAdelantoEmisor = (emisor) => {
    const participanteCabal = this.getParticipanteCabal();

    let comercioAdel = null;

    if (participanteCabal !== null) {
      participanteCabal.datosPuenteo.forEach((p) => {
        if (p.emisor === emisor) {
          comercioAdel = p.comercioAdelanto;
        }
      });
    }

    return comercioAdel || '';
  }

  getTokenTrxConfirmFrontToBackend = () => {
    const tokenConfirmacion = (`${localStorage.getItem('accessToken') + new Date().getTime()}`);
    return tokenConfirmacion;
  }

  getMensajeSicoop1A = (
    tipoTransaccion, codServicio,
    // Origen
    codParticipanteOrigen, tipoCuentaOrigen, cuentaParticipanteOrigen, ciOriginante, titularOriginante,

    // Destino
    codParticipanteDestino, tipoCuentaDestino, cuentaParticipanteDestino, ciDestinatario, titularDestinatario,

    // Glosa
    glosa,

    // Importe
    importe, moneda,

    // datos adicionales para puenteo
    emisorOrigen, emisorDestino,

    // Importe de Cargo transaccional
    importeCargo, idQr,
    cuotas,
    codOperacion,
    idOperacionCaja,

    ...rest
  ) => {
    const mensajeSicoop1A = {
      idTxOriginante: '',
      codOriginante: '',
      codDestinatario: '',
      moneda: monedas.GS,
      monto: '',
      tipoTransaccion: '',
      tipoMovimiento: '',
      tipoCuentaOrigen: '',
      numCuentaOrigen: '',
      tipoCuentaDestino: '',
      numCuentaDestino: '',
      glosa: '',
      ciOriginante: '',
      titularOriginante: '',
      ciDestinatario: '',
      titularDestinatario: '',
      origenFondos: '',
      codEmpresa: '',
      codServicio: '',
      codCajaCajero: '',
      montoCargo: '0',
      idQr: '',
      tipoQr: '',
      cuotas: '',
      codOperacion: '',
      idOperacionCaja: '',
      aditionalParameters: []
    };

    mensajeSicoop1A.idTxOriginante = '';
    mensajeSicoop1A.codOriginante = codParticipanteOrigen;
    mensajeSicoop1A.codDestinatario = codParticipanteDestino;

    mensajeSicoop1A.moneda = moneda || monedas.GS; // Si no se envio la moneda, el default es GUA(RANIES)
    mensajeSicoop1A.monto = (importe !== null && !isNaN(importe)) ? (Number(importe) * 100) : 0; // El por 100 es para agregarle dos ceros a la izquierda (formato ISO)

    mensajeSicoop1A.tipoTransaccion = tipoTransaccion;
    mensajeSicoop1A.codServicio = codServicio;
    mensajeSicoop1A.tipoMovimiento = tiposMovimiento.ACREDITAR;

    // Origen
    mensajeSicoop1A.tipoCuentaOrigen = tipoCuentaOrigen;
    mensajeSicoop1A.numCuentaOrigen = cuentaParticipanteOrigen;
    mensajeSicoop1A.ciOriginante = `${ciOriginante}`;
    mensajeSicoop1A.titularOriginante = titularOriginante;

    // Destino
    mensajeSicoop1A.tipoCuentaDestino = tipoCuentaDestino;
    mensajeSicoop1A.numCuentaDestino = (cuentaParticipanteDestino && cuentaParticipanteDestino !== undefined && cuentaParticipanteDestino !== null) ? cuentaParticipanteDestino : '';
    mensajeSicoop1A.ciDestinatario = `${ciDestinatario}`;
    mensajeSicoop1A.titularDestinatario = titularDestinatario;

    // Cargo Transaccional
    mensajeSicoop1A.montoCargo = !isNaN(importeCargo) ? (Number(importeCargo)) : 0;

    // Otros
    mensajeSicoop1A.glosa = glosa;
    mensajeSicoop1A.origenFondos = 'n/a';
    mensajeSicoop1A.codCajaCajero = '0';

    mensajeSicoop1A.idQr = idQr;

    // Paramertros Adicionales por DEFECTO:
    mensajeSicoop1A.aditionalParameters = mensajeSicoop1A.aditionalParameters.concat([
      {
        key: keyDatosAdicionales.codParticipanteOrigen1A.key,
        value: codParticipanteOrigen,
      },
      {
        key: keyDatosAdicionales.codParticipanteDestino1A.key,
        value: codParticipanteDestino,
      },
    ]);

    // Si la transaccion es de Compra
    if (mensajeSicoop1A.tipoTransaccion === tiposTransaccion.DEPO) {
      mensajeSicoop1A.idOperacionCaja = idOperacionCaja;
      mensajeSicoop1A.codOperacion = codOperacion;
    } else if (mensajeSicoop1A.tipoTransaccion === tiposTransaccion.EXTR) {
      mensajeSicoop1A.cuotas = cuotas;
      mensajeSicoop1A.idOperacionCaja = idOperacionCaja;
      mensajeSicoop1A.codOperacion = codOperacion;
    } else if (mensajeSicoop1A.tipoTransaccion === tiposTransaccion.COMP) {
      mensajeSicoop1A.cuotas = cuotas;
      mensajeSicoop1A.idOperacionCaja = idOperacionCaja;
      mensajeSicoop1A.codOperacion = codOperacion;
      // La compra solo puede ser: o una compra Interna o un compra con Tarjeta Puente
      if (mensajeSicoop1A.codServicio === transaccionesSicoop.COMP.servicios.COMPRA) {
        mensajeSicoop1A.codEmpresa = 1; // cant. cuotas segun documentacion.
      }
      // La compra es una compra con tarjeta puente
      //else if (mensajeSicoop1A.codServicio === transaccionesSicoop.COMP.servicios.COMPRATP) {
      //mensajeSicoop1A.codEmpresa = this.getTarjetaPuenteParticipante(codParticipanteOrigen); // La tarjeta puente se envia en este campo ¬¬
      //}
    } else if (mensajeSicoop1A.tipoTransaccion === tiposTransaccion.TRAN) {
      // Si la transaccion es TRANsferencia, puede ser:
      // 1. Transferencia desde DIMO hasta otra cuenta X de un participante dentro del SICOOP
      // 2. Transferencia desde DIMO a DIMO
      // 3. Transferencia desde TC a DIMO
      // 4. Transferencia desde una cuenta X del SICOOP a una cuenta DIMO
      // 5. Transferencia desde TC a Cuenta X de un un participante dentro del SICOOP

      // * TRANSFERENCIA Interna CEIBO
      if (mensajeSicoop1A.codServicio === transaccionesSicoop.TRAN.servicios.TRANINTE) {
        mensajeSicoop1A.codEmpresa = this.getComercioAdelantoParticipante(codParticipanteOrigen);

        // Si se envio un emisor de origen, buscamos el comercio Adelanto por Emisor
        if (emisorOrigen) {
          const comercioAdelEmisor = this.getComercioAdelantoEmisor(emisorOrigen);
          mensajeSicoop1A.codEmpresa = comercioAdelEmisor || mensajeSicoop1A.codEmpresa;
        }
      }
      // * TRANSFERENCIA AL SICOOP
      else if (mensajeSicoop1A.codServicio === transaccionesSicoop.TRAN.servicios.TRANSFER) {
        if (mensajeSicoop1A.tipoCuentaDestino === tiposCuentaDestino.BANCOD) {
          mensajeSicoop1A.codEmpresa = this.getComercioAdelantoParticipante(codParticipanteDestino);
        } else {
          mensajeSicoop1A.codEmpresa = this.getComercioPuenteParticipante(codParticipanteOrigen);

          // Si se envio un emisor de origen, buscamos el comercio puente por Emisor
          if (emisorOrigen) {
            const comercioPuenteEmisor = this.getComercioPuenteEmisor(emisorOrigen);
            mensajeSicoop1A.codEmpresa = comercioPuenteEmisor || mensajeSicoop1A.codEmpresa;
          }

          // Si la cuenta de Origen es una TP o una TC con destino hacia el SICOOP
          if (mensajeSicoop1A.tipoCuentaOrigen === tiposCuentaOrigen.TPO || mensajeSicoop1A.tipoCuentaOrigen === tiposCuentaOrigen.TCO) {
            // El comercio debe ser el comercio de Adelanto, no el comercio Puente
            mensajeSicoop1A.codEmpresa = this.getComercioAdelantoParticipante(codParticipanteOrigen);

            // Si se envio un emisor de origen, buscamos el comercio Adelanto por Emisor
            if (emisorOrigen) {
              const comercioAdelEmisor = this.getComercioAdelantoEmisor(emisorOrigen);
              mensajeSicoop1A.codEmpresa = comercioAdelEmisor || mensajeSicoop1A.codEmpresa;
            }
          }
        }
      }
      // * TRANSFERENCIA Destino DIMO desde el Sicoop
      else if (mensajeSicoop1A.codServicio === transaccionesSicoop.TRAN.servicios.TRANDIMO) {
        mensajeSicoop1A.codEmpresa = this.getComercioPagoParticipante(codParticipanteDestino);

        // Si se envio un emisor destino, buscamos el comercio Pago por Emisor
        if (emisorDestino) {
          const comercioPagoEmisor = this.getComercioPagoEmisor(emisorDestino);
          mensajeSicoop1A.codEmpresa = comercioPagoEmisor || mensajeSicoop1A.codEmpresa;
        }

        // Si es una TRANDIMO y la cuenta de Origen es una TC o una TP
        if (mensajeSicoop1A.tipoCuentaOrigen === tiposCuentaOrigen.TPO || mensajeSicoop1A.tipoCuentaOrigen === tiposCuentaOrigen.TCO) {
          let comercioAdelantoOriginante = this.getComercioAdelantoParticipante(codParticipanteOrigen);

          if (emisorOrigen) {
            comercioAdelantoOriginante = this.getComercioAdelantoEmisor(emisorOrigen) ? this.getComercioAdelantoEmisor(emisorOrigen) : comercioAdelantoOriginante;
          }

          // Agregamos el comecio de Adelanto para la transaccion del Originante...
          mensajeSicoop1A.aditionalParameters.push({
            key: keyDatosAdicionales.comercioAdelTranDIMO.key,
            value: comercioAdelantoOriginante,
          });
        }
      }
    } else if (mensajeSicoop1A.tipoTransaccion === tiposTransaccion.PAGO) {
      // Si la transaccion es de PAGO, puede ser:

      //  1. Pago de Tarjetas de Credito Cabal con Cuenta de Ahorro
      if (mensajeSicoop1A.codServicio === transaccionesSicoop.PAGO.servicios.PAGOTC) {
        // La tarjeta se Paga con una Cuenta de Ahorro
        let comercioPagoTC = this.getComercioPagoParticipante(codParticipanteDestino);

        // Si se envio un emisor destino, buscamos el comercio Pago por Emisor
        if (emisorDestino) {
          const comercioPagoEmisor = this.getComercioPagoEmisor(emisorDestino);
          comercioPagoTC = comercioPagoEmisor || mensajeSicoop1A.codEmpresa;
        }

        mensajeSicoop1A.aditionalParameters.push({
          key: keyDatosAdicionales.comercioPagoTC.key,
          value: comercioPagoTC,
        });
      }
      //  2. Pago de Tarjetas de Credito Cabal con DIMO
      else if (mensajeSicoop1A.codServicio === transaccionesSicoop.PAGO.servicios.PAGOINTE) {
        // La tarjetas se Paga con DIMO
        mensajeSicoop1A.codEmpresa = this.getComercioAdelantoParticipante(codParticipanteOrigen);

        // Si se envio un emisor de origen, buscamos el comercio de Adelanto por Emisor
        if (emisorOrigen) {
          const comercioAdelEmisor = this.getComercioAdelantoEmisor(emisorOrigen);
          mensajeSicoop1A.codEmpresa = comercioAdelEmisor || mensajeSicoop1A.codEmpresa;
        }

        // Agregamos el comecio Pago de TC
        mensajeSicoop1A.aditionalParameters.push({
          key: keyDatosAdicionales.comercioPagoTC.key,
          value: this.getComercioPagoEmisor(emisorDestino),
        });
      }
      //  2.2 Pago de Tarjetas de Credito PANAL con DIMO/CCAHO
      else if (mensajeSicoop1A.codServicio === transaccionesSicoop.PAGO.servicios.PAGOTCPANAL) {
        // La tarjetas se Paga con DIMO

        mensajeSicoop1A.codEmpresa = this.getComercioAdelantoParticipante(codParticipanteOrigen);

        // Si se envio un emisor de origen, buscamos el comercio de Adelanto por Emisor
        if (emisorOrigen) {
          const comercioAdelEmisor = this.getComercioAdelantoEmisor(emisorOrigen);
          mensajeSicoop1A.codEmpresa = comercioAdelEmisor || mensajeSicoop1A.codEmpresa;
        }
        // Agregamos el comecio Pago de TC
        mensajeSicoop1A.aditionalParameters.push({
          key: keyDatosAdicionales.comercioPagoTC.key,
          value: this.getComercioPagoEmisor(emisorDestino),
        });
      }
      // 3. Pago de Prestamos en las Coop con: DIMO o con una Cta. Aho
      else if (mensajeSicoop1A.codServicio === transaccionesSicoop.PAGO.servicios.PAGOCR) {
        // El Prestamo se paga con DIMO
        mensajeSicoop1A.codEmpresa = this.getComercioPuenteParticipante(codParticipanteOrigen);
        // mensajeSicoop1A.tipoCuentaDestino = "CCOM"; TEST ONLY
      }
      // 4. Pago de Aporte en las Coop con: DIMO o con una Cta. Aho
      // else if (mensajeSicoop1A.codServicio === transaccionesSicoop.PAGO.servicios.APORTE) {
      //
      //   mensajeSicoop1A.codEmpresa = this.getComercioPuenteParticipante(codParticipanteOrigen);
      //   //mensajeSicoop1A.tipoCuentaDestino = "CCOM"; TEST ONLY
      // }
      // // 5. Pago de Solidaridad en las Coop con: DIMO o con una Cta. Aho
      // else if (mensajeSicoop1A.codServicio === transaccionesSicoop.PAGO.servicios.SOLIDARIDAD) {
      //   mensajeSicoop1A.codEmpresa = this.getComercioPuenteParticipante(codParticipanteOrigen);
      // }
      // 4. Pago de RUEDA DE AHORRO
      else if (mensajeSicoop1A.codServicio === transaccionesSicoop.PAGO.servicios.RUEDA) {
        mensajeSicoop1A.codEmpresa = this.getComercioPuenteParticipante(codParticipanteOrigen);
      }
      // 5. Pago de AHORRO PROGRAMADO
      else if (mensajeSicoop1A.codServicio === transaccionesSicoop.PAGO.servicios.PAGOAHOPRO) {
        mensajeSicoop1A.codEmpresa = this.getComercioPuenteParticipante(codParticipanteOrigen);
      }
      // 6. Pago de Servicios
      else if (mensajeSicoop1A.codServicio === transaccionesSicoop.PAGO.servicios.SERVICIO) {
        mensajeSicoop1A.codEmpresa = this.getComercioPuenteParticipante(codParticipanteDestino);
      } else {
        mensajeSicoop1A.codEmpresa = this.getComercioPuenteParticipante(codParticipanteOrigen);
        // mensajeSicoop1A.tipoCuentaDestino = "CCOM"; TEST ONLY
      }
      // 8. Pago de COUNTRY de Coop
      // else if (mensajeSicoop1A.codServicio === transaccionesSicoop.PAGO.servicios.COUNTRY) {
      //   mensajeSicoop1A.codEmpresa = this.getComercioPuenteParticipante(codParticipanteOrigen);
      // }
      // // 9. Pago de SEDE Social de Coop
      // else if (mensajeSicoop1A.codServicio === transaccionesSicoop.PAGO.servicios.SEDE) {
      //   mensajeSicoop1A.codEmpresa = this.getComercioPuenteParticipante(codParticipanteOrigen);
      // }
      // // 11. Pago de APORTEEXT de Coop
      // else if (mensajeSicoop1A.codServicio === transaccionesSicoop.PAGO.servicios.APORTEEXT) {
      //   mensajeSicoop1A.codEmpresa = this.getComercioPuenteParticipante(codParticipanteOrigen);
      // }
      // // 12. Pago de INCOOP de Coop
      // else if (mensajeSicoop1A.codServicio === transaccionesSicoop.PAGO.servicios.INCOOP) {
      //   mensajeSicoop1A.codEmpresa = this.getComercioPuenteParticipante(codParticipanteOrigen);
      // }
      // // 13. Pago de GASTO MANTENIMIENTO de Coop
      // else if (mensajeSicoop1A.codServicio === transaccionesSicoop.PAGO.servicios.GASTOMANT) {
      //   mensajeSicoop1A.codEmpresa = this.getComercioPuenteParticipante(codParticipanteOrigen);
      // }
    }

    return mensajeSicoop1A;
  }

  getTrxSicoopCompraTC = (emisorOrigenCompra, cuentaOrigenCompra, ciOriginante, titularOriginante,
    comercio, nombreComercial, ciUsuarioComercio, importe, cuotas,
    codOperacion, idOperacionCaja, codDestinatario) => {
    const participanteEmisor = this.getParticipanteOrigenTrxTC(emisorOrigenCompra);
    const participanteCabal = this.getParticipanteCabal();

    const tipoTrxSicoop = codOperacion ?
      codOperacion === 'DEPO' ?
        transaccionesSicoop.DEPO : codOperacion === 'EXTR' ? transaccionesSicoop.EXTR
          : transaccionesSicoop.COMP
      : transaccionesSicoop.COMP;
    const codServicio = tipoTrxSicoop.value === transaccionesSicoop.COMP.value ? tipoTrxSicoop.servicios.COMPRA :
      tipoTrxSicoop.value === transaccionesSicoop.DEPO.value ? tipoTrxSicoop.servicios.BCADELAN :
        tipoTrxSicoop.servicios.CAJEXTRA;
    return this.getMensajeSicoop1A(
      tipoTrxSicoop.value,
      codServicio,
      participanteEmisor.codParticipante,
      tiposCuentaOrigen.TCO,
      cuentaOrigenCompra,
      ciOriginante,
      titularOriginante,
      // participanteCabal.codParticipante, // Se utiliza el mismo participante Origen/Destino para Compras CABAL=CABAL /
      (codDestinatario == null ? participanteEmisor.codParticipante : codDestinatario),
      tiposCuentaDestino.CCOMD,
      comercio,
      ciUsuarioComercio,
      nombreComercial,
      'Compra TC en Comercios', // glosa
      importe,
      null,
      null,
      null,
      null,
      null,
      cuotas,
      codOperacion,
      idOperacionCaja
    );
  }

  getTrxSicoopCompraTP = (emisorOrigenCompra, cuentaOrigenCompra, ciOriginante,
    titularOriginante, comercio, nombreComercial, ciUsuarioComercio, importe,
    codOperacion, idOperacionCaja, codDestinatario) => {
    const participanteCabal = this.getParticipanteCabal();
    const participanteEmisor = participanteCabal;// this.getParticipanteEmisor(emisorOrigenCompra);
    const tipoTrxSicoop = codOperacion ?
      codOperacion === 'DEPO' ?
        transaccionesSicoop.DEPO : codOperacion === 'EXTR' ? transaccionesSicoop.EXTR
          : transaccionesSicoop.COMP
      : transaccionesSicoop.COMP;
    const codServicio = tipoTrxSicoop.value === transaccionesSicoop.COMP.value ? tipoTrxSicoop.servicios.COMPRA :
      tipoTrxSicoop.value === transaccionesSicoop.DEPO.value ? tipoTrxSicoop.servicios.BCADELAN :
        tipoTrxSicoop.servicios.CAJEXTRA;
    return this.getMensajeSicoop1A(
      tipoTrxSicoop.value,
      codServicio,
      participanteEmisor.codParticipante,
      tiposCuentaOrigen.TPO,
      cuentaOrigenCompra,
      ciOriginante,
      titularOriginante,
      (codDestinatario == null ? participanteCabal.codParticipante : codDestinatario),
      tiposCuentaDestino.CCOMD,
      comercio,
      ciUsuarioComercio,
      nombreComercial,
      'Compra DIMO/TP', // glosa
      importe,
      null,
      null,
      null,
      null,
      null,
      null,
      codOperacion,
      idOperacionCaja,
    );
  }

  getTrxSicoopCompraCtaAho = (codParticipanteOrigen, cuentaOrigenCompra,
    ciOriginante, titularOriginante, comercio, nombreComercial, ciUsuarioComercio, importe,
    codOperacion, idOperacionCaja, codDestinatario) => {
    // Compras con Tarjetas Puente
    const participanteCabal = this.getParticipanteCabal();

    const tipoTrxSicoop = codOperacion ?
      codOperacion === 'DEPO' ?
        transaccionesSicoop.DEPO : codOperacion === 'EXTR' ? transaccionesSicoop.EXTR
          : transaccionesSicoop.COMP
      : transaccionesSicoop.COMP;

    const codServicio = tipoTrxSicoop.value === transaccionesSicoop.COMP.value ? tipoTrxSicoop.servicios.COMPRATP :
      tipoTrxSicoop.value === transaccionesSicoop.DEPO.value ? tipoTrxSicoop.servicios.BCADELAN :
        tipoTrxSicoop.servicios.CAJEXTRA;
    return this.getMensajeSicoop1A(
      tipoTrxSicoop.value,
      codServicio, // Compra Tarjeta Puente
      codParticipanteOrigen,
      tiposCuentaOrigen.CCAHOO,
      cuentaOrigenCompra,
      ciOriginante,
      titularOriginante,
      (codDestinatario == null ? participanteCabal.codParticipante : codDestinatario),
      tiposCuentaDestino.CCOMD,
      comercio,
      ciUsuarioComercio,
      nombreComercial,
      'Compra en Comercio con Cta Aho Coop', // glosa
      importe,
      null,
      null,
      null,
      null,
      null,
      null,
      codOperacion,
      idOperacionCaja
    );
  }

  getTrxSicoopTransTC_CtaAho = (emisorOrigen, cuentaOrigen, ciOriginante, titularOriginante, codParticipanteDestino, cuentaDestino, ciDestinatario, titularDestinatario, importe, importeCargo, idQr) => {
    const participanteOrigen = this.getParticipanteOrigenTrxTC(emisorOrigen);
    const tipoTrxSicoop = transaccionesSicoop.TRAN;

    return this.getMensajeSicoop1A(
      tipoTrxSicoop.value,
      tipoTrxSicoop.servicios.TRANSFER,
      participanteOrigen.codParticipante,
      tiposCuentaOrigen.TCO,
      cuentaOrigen,
      ciOriginante,
      titularOriginante,
      codParticipanteDestino,
      tiposCuentaDestino.CCAHOD,
      cuentaDestino,
      ciDestinatario,
      titularDestinatario,
      'Transferencia TC a Caja Ah', // glosa
      importe,
      monedas.GS,
      emisorOrigen,
      '',
      importeCargo,
      idQr
    );
  }

  getTrxSicoopTransTP_CtaAho = (cuentaOrigen, ciOriginante, titularOriginante, codParticipanteDestino, cuentaDestino, ciDestinatario, titularDestinatario, importe, importeCargo, idQr) => {
    const participanteCabal = this.getParticipanteCabal();

    const tipoTrxSicoop = transaccionesSicoop.TRAN;

    return this.getMensajeSicoop1A(
      tipoTrxSicoop.value,
      tipoTrxSicoop.servicios.TRANSFER,
      participanteCabal.codParticipante,
      tiposCuentaOrigen.TPO,
      cuentaOrigen,
      ciOriginante,
      titularOriginante,
      codParticipanteDestino,
      tiposCuentaDestino.CCAHOD,
      cuentaDestino,
      ciDestinatario,
      titularDestinatario,
      'Transferencia TP a Caja Ah de CAC', // glosa
      importe,
      monedas.GS,
      '115',
      '',
      importeCargo,
      idQr
    );
  }

  getTrxSicoopPagoTP_BC = (cuentaOrigen, ciOriginante, titularOriginante, codParticipanteDestino, cuentaDestino, ciDestinatario, titularDestinatario, importe, importeCargo) => {
    const participanteCabal = this.getParticipanteCabal();

    const tipoTrxSicoop = transaccionesSicoop.PAGO;

    return this.getMensajeSicoop1A(
      tipoTrxSicoop.value,
      tipoTrxSicoop.servicios.SERVICIO,
      participanteCabal.codParticipante,
      tiposCuentaOrigen.TPO,
      cuentaOrigen,
      ciOriginante,
      titularOriginante,
      codParticipanteDestino,
      tiposCuentaDestino.RCD,
      cuentaDestino,
      ciDestinatario,
      titularDestinatario,
      'Pago TP a BC', // glosa
      importe,
      monedas.GS,
      '',
      '',
      importeCargo,
    );
  }

  getTrxSicoopPagoTP_CoopCRED = (cuentaOrigen, ciOriginante, titularOriginante, codParticipanteDestino, cuentaDestino, ciDestinatario, titularDestinatario, importe, importeCargo) => {
    const participanteCabal = this.getParticipanteCabal();

    const tipoTrxSicoop = transaccionesSicoop.PAGO;

    return this.getMensajeSicoop1A(
      tipoTrxSicoop.value,
      tipoTrxSicoop.servicios.PAGOCR,
      participanteCabal.codParticipante,
      tiposCuentaOrigen.TPO,
      cuentaOrigen,
      ciOriginante,
      titularOriginante,
      codParticipanteDestino,
      tiposCuentaDestino.CRDD,
      cuentaDestino,
      ciDestinatario,
      titularDestinatario,
      'Pago TP a Prestamo Coop', // glosa
      importe,
      monedas.GS,
      '',
      '',
      importeCargo,
    );
  }

  getTrxSicoopPagoCoopOBLIGACION = (codParticipanteOrigen, cuentaOrigen, ciOriginante, titularOriginante, codParticipanteDestino, cuentaDestino, ciDestinatario, titularDestinatario, importe, importeCargo, codigoServicio, obligacionNombre) => {
    // si codParticipanteOrigen es null quiere decir que el tipo de cuenta origen es TP entonces
    // se debe obtener el participante de Cabal
    let participanteCabal;
    if (codParticipanteOrigen === null) {
      // obtenemos el participante
      participanteCabal = this.getParticipanteCabal();
    }

    const tipoTrxSicoop = transaccionesSicoop.PAGO;

    return this.getMensajeSicoop1A(
      tipoTrxSicoop.value,
      codigoServicio,
      codParticipanteOrigen === null ? participanteCabal.codParticipante : codParticipanteOrigen,
      codParticipanteOrigen === null ? tiposCuentaOrigen.TPO : tiposCuentaOrigen.CCAHOO,
      cuentaOrigen,
      ciOriginante,
      titularOriginante,
      codParticipanteDestino,
      tiposCuentaDestino.OBLGD,
      cuentaDestino,
      ciDestinatario,
      titularDestinatario,
      codParticipanteOrigen === null ? `Pago TP a ${obligacionNombre}` : `Pago Cta Aho a ${obligacionNombre}`, // glosa
      importe,
      monedas.GS,
      '',
      '',
      importeCargo,
    );
  }

  getTrxSicoopPagoTP_CoopRUEDA = (cuentaOrigen, ciOriginante, titularOriginante, codParticipanteDestino, cuentaDestino, ciDestinatario, titularDestinatario, importe, importeCargo) => {
    const participanteCabal = this.getParticipanteCabal();

    const tipoTrxSicoop = transaccionesSicoop.PAGO;

    return this.getMensajeSicoop1A(
      tipoTrxSicoop.value,
      tipoTrxSicoop.servicios.RUEDA,
      participanteCabal.codParticipante,
      tiposCuentaOrigen.TPO,
      cuentaOrigen,
      ciOriginante,
      titularOriginante,
      codParticipanteDestino,
      tiposCuentaDestino.CCAHOD,
      cuentaDestino,
      ciDestinatario,
      titularDestinatario,
      'Pago con TP a Rueda de Ahorro Coop', // glosa
      importe,
      monedas.GS,
      '',
      '',
      importeCargo,
    );
  }

  getTrxSicoopPagoTP_CoopAHOPRO = (cuentaOrigen, ciOriginante, titularOriginante, codParticipanteDestino, cuentaDestino, ciDestinatario, titularDestinatario, importe, importeCargo) => {
    const participanteCabal = this.getParticipanteCabal();

    const tipoTrxSicoop = transaccionesSicoop.PAGO;

    return this.getMensajeSicoop1A(
      tipoTrxSicoop.value,
      tipoTrxSicoop.servicios.PAGOAHOPRO,
      participanteCabal.codParticipante,
      tiposCuentaOrigen.TPO,
      cuentaOrigen,
      ciOriginante,
      titularOriginante,
      codParticipanteDestino,
      tiposCuentaDestino.CCAHOD,
      cuentaDestino,
      ciDestinatario,
      titularDestinatario,
      'Pago con TP a Ahorro Programado', // glosa
      importe,
      monedas.GS,
      '',
      '',
      importeCargo,
    );
  }

  getTrxSicoopPagoCtaAho_CoopCRED = (codParticipanteOrigen, cuentaOrigen, ciOriginante, titularOriginante, codParticipanteDestino, cuentaDestino, ciDestinatario, titularDestinatario, importe, importeCargo) => {
    const tipoTrxSicoop = transaccionesSicoop.PAGO;

    return this.getMensajeSicoop1A(
      tipoTrxSicoop.value,
      tipoTrxSicoop.servicios.PAGOCR,
      codParticipanteOrigen,
      tiposCuentaOrigen.CCAHOO,
      cuentaOrigen,
      ciOriginante,
      titularOriginante,
      codParticipanteDestino,
      tiposCuentaDestino.CRDD,
      cuentaDestino,
      ciDestinatario,
      titularDestinatario,
      'Pago Cta Aho a Prestamo Coop', // glosa
      importe,
      monedas.GS,
      '',
      '',
      importeCargo,
    );
  }

  getTrxSicoopPagoCtaAho_RUEDA = (codParticipanteOrigen, cuentaOrigen, ciOriginante, titularOriginante, codParticipanteDestino, cuentaDestino, ciDestinatario, titularDestinatario, importe, importeCargo) => {
    const tipoTrxSicoop = transaccionesSicoop.PAGO;

    return this.getMensajeSicoop1A(
      tipoTrxSicoop.value,
      tipoTrxSicoop.servicios.RUEDA,
      codParticipanteOrigen,
      tiposCuentaOrigen.CCAHOO,
      cuentaOrigen,
      ciOriginante,
      titularOriginante,
      codParticipanteDestino,
      tiposCuentaDestino.CCAHOD,
      cuentaDestino,
      ciDestinatario,
      titularDestinatario,
      'Pago con Cta Aho a Rueda Ahorro Coop', // glosa
      importe,
      monedas.GS,
      '',
      '',
      importeCargo,
    );
  }

  getTrxSicoopPagoCtaAho_AHOPRO = (codParticipanteOrigen, cuentaOrigen, ciOriginante, titularOriginante, codParticipanteDestino, cuentaDestino, ciDestinatario, titularDestinatario, importe, importeCargo) => {
    const tipoTrxSicoop = transaccionesSicoop.PAGO;

    return this.getMensajeSicoop1A(
      tipoTrxSicoop.value,
      tipoTrxSicoop.servicios.PAGOAHOPRO,
      codParticipanteOrigen,
      tiposCuentaOrigen.CCAHOO,
      cuentaOrigen,
      ciOriginante,
      titularOriginante,
      codParticipanteDestino,
      tiposCuentaDestino.CCAHOD,
      cuentaDestino,
      ciDestinatario,
      titularDestinatario,
      'Pago con Cta Aho a Ahorro Programado', // glosa
      importe,
      monedas.GS,
      '',
      '',
      importeCargo,
    );
  }

  getTrxSicoopTransTC_BC = (emisorOrigen, cuentaOrigen, ciOriginante, titularOriginante, codParticipanteDestino, cuentaDestino, ciDestinatario, titularDestinatario, importe, importeCargo) => {
    const participanteOrigen = this.getParticipanteOrigenTrxTC(emisorOrigen);

    const tipoTrxSicoop = transaccionesSicoop.PAGO;

    return this.getMensajeSicoop1A(
      tipoTrxSicoop.value,
      tipoTrxSicoop.servicios.SERVICIO,
      participanteOrigen.codParticipante,
      tiposCuentaOrigen.TCO,
      cuentaOrigen,
      ciOriginante,
      titularOriginante,
      codParticipanteDestino,
      tiposCuentaDestino.RCD,
      cuentaDestino,
      ciDestinatario,
      titularDestinatario,
      'Pago TC a BC', // glosa
      importe,
      monedas.GS,
      emisorOrigen,
      '',
      importeCargo,
    );
  }

  getTrxSicoopTransCOOP_BC = (codParticipanteOrigen, cuentaOrigen, ciOriginante, titularOriginante, codParticipanteDestino, cuentaDestino, ciDestinatario, titularDestinatario, importe, importeCargo) => {
    const tipoTrxSicoop = transaccionesSicoop.PAGO;

    return this.getMensajeSicoop1A(
      tipoTrxSicoop.value,
      tipoTrxSicoop.servicios.SERVICIO,
      codParticipanteOrigen,
      tiposCuentaOrigen.CCAHOO,
      cuentaOrigen,
      ciOriginante,
      titularOriginante,
      codParticipanteDestino,
      tiposCuentaDestino.RCD,
      cuentaDestino,
      ciDestinatario,
      titularDestinatario,
      'Pago COOP a BC', // glosa
      importe,
      monedas.GS,
      '',
      '',
      importeCargo,
    );
  }

  getTrxSicoopTransCtaAho_TP = (codParticipanteOrigen, cuentaOrigen, ciOriginante, titularOriginante, cuentaDestino, ciDestinatario, titularDestinatario, importe, importeCargo, idQr) => {
    const participanteCabal = this.getParticipanteCabal();

    const tipoTrxSicoop = transaccionesSicoop.TRAN;

    return this.getMensajeSicoop1A(
      tipoTrxSicoop.value,
      tipoTrxSicoop.servicios.TRANDIMO,
      codParticipanteOrigen,
      tiposCuentaOrigen.CCAHOO,
      cuentaOrigen,
      ciOriginante,
      titularOriginante,
      participanteCabal.codParticipante,
      tiposCuentaDestino.TPD,
      cuentaDestino,
      ciDestinatario,
      titularDestinatario,
      'Transferencia Caja Ah de CAC a TP', // glosa
      importe,
      monedas.GS,
      '',
      '',
      importeCargo,
      idQr
    );
  }

  getTrxSicoopTransBC_MDW = (emisorOrigen, cuentaOrigen, ciOriginante, titularOriginante, tipoCuentaOrigen,
    codParticipanteDestino, cuentaDestino, ciDestinatario, titularDestinatario,
    importe, importeCargo) => {

    let participanteOrigen;
    if (tipoCuentaOrigen === "TCO" || tipoCuentaOrigen === "TPO")
      participanteOrigen = this.getParticipanteOrigenTrxTC(emisorOrigen).codParticipante;

    else
      participanteOrigen = emisorOrigen;

    const tipoTrxSicoop = transaccionesSicoop.PAGO;

    return this.getMensajeSicoop1A(
      tipoTrxSicoop.value,
      tipoTrxSicoop.servicios.SERVICIO,
      participanteOrigen,
      tipoCuentaOrigen,
      cuentaOrigen,
      ciOriginante,
      titularOriginante,
      codParticipanteDestino,
      tiposCuentaDestino.RCD,
      cuentaDestino,
      ciDestinatario,
      titularDestinatario,
      'Pago de Servicio', // glosa
      importe,
      monedas.GS,
      emisorOrigen,
      '',
      importeCargo,
    );
  }

  getTrxSicoopTransCtaAho_BANCO = (codParticipanteOrigen, cuentaOrigen, ciOriginante, titularOriginante, cuentaDestino, ciDestinatario, titularDestinatario, importe, importeCargo) => {
    const participanteDestino = this.getParticipanteBancard();

    const tipoTrxSicoop = transaccionesSicoop.TRAN;

    return this.getMensajeSicoop1A(
      tipoTrxSicoop.value,
      tipoTrxSicoop.servicios.TRANSFER,
      codParticipanteOrigen,
      tiposCuentaOrigen.CCAHOO,
      cuentaOrigen,
      ciOriginante,
      titularOriginante,
      participanteDestino.codParticipante,
      tiposCuentaDestino.BANCOD,
      cuentaDestino,
      ciDestinatario,
      titularDestinatario,
      'Transferencia Caja Ah de CAC a BANCO', // glosa
      importe,
      monedas.GS,
      '',
      '',
      importeCargo,
    );
  }

  getTrxSicoopTransCtaAho_CtaAho = (codParticipanteOrigen, cuentaOrigen, ciOriginante, titularOriginante, codParticipanteDestino, cuentaDestino, ciDestinatario, titularDestinatario, importe, importeCargo, idQr) => {
    const participanteCabal = this.getParticipanteCabal();

    const tipoTrxSicoop = transaccionesSicoop.TRAN;

    return this.getMensajeSicoop1A(
      tipoTrxSicoop.value,
      tipoTrxSicoop.servicios.TRANSFER,
      codParticipanteOrigen,
      tiposCuentaOrigen.CCAHOO,
      cuentaOrigen,
      ciOriginante,
      titularOriginante,
      codParticipanteDestino,
      tiposCuentaDestino.CCAHOD,
      cuentaDestino,
      ciDestinatario,
      titularDestinatario,
      'Transferencia Caja Ah de CAC a Caja Ah de CAC', // glosa
      importe,
      monedas.GS,
      '',
      '',
      importeCargo,
      idQr,
    );
  }

  getTrxSicoopTransInteTP_TP = (emisorOrigen, cuentaOrigen, ciOriginante, titularOriginante, emisorDestinatario, cuentaDestino, ciDestinatario, titularDestinatario, importe, importeCargo, idQr) => {
    const participanteOrigen = this.getParticipanteEmisor(emisorOrigen);
    const participanteDestino = this.getParticipanteEmisor(emisorDestinatario);

    const tipoTrxSicoop = transaccionesSicoop.TRAN;

    return this.getMensajeSicoop1A(
      tipoTrxSicoop.value,
      tipoTrxSicoop.servicios.TRANINTE,
      participanteOrigen.codParticipante,
      tiposCuentaOrigen.TPO,
      cuentaOrigen,
      ciOriginante,
      titularOriginante,
      participanteDestino.codParticipante,
      tiposCuentaDestino.TPD,
      cuentaDestino,
      ciDestinatario,
      titularDestinatario,
      'Transferencia DIMO a DIMO', // glosa
      importe,
      monedas.GS,
      emisorOrigen,
      emisorDestinatario,
      importeCargo,
      idQr
    );
  }

  getTrxSicoopTransTP_BANCO = (emisorOrigen, cuentaOrigen, ciOriginante, titularOriginante, emisorDestinatario, cuentaDestino, ciDestinatario, titularDestinatario, importe, importeCargo) => {
    const participanteOrigen = this.getParticipanteEmisor(emisorOrigen);
    const participanteDestino = this.getParticipanteBancard();

    const tipoTrxSicoop = transaccionesSicoop.TRAN;

    return this.getMensajeSicoop1A(
      tipoTrxSicoop.value,
      tipoTrxSicoop.servicios.TRANSFER,
      participanteOrigen.codParticipante,
      tiposCuentaOrigen.TPO,
      cuentaOrigen,
      ciOriginante,
      titularOriginante,
      participanteDestino.codParticipante,
      tiposCuentaDestino.BANCOD,
      cuentaDestino,
      ciDestinatario,
      titularDestinatario,
      'Transferencia DIMO a BANCO', // glosa
      importe,
      monedas.GS,
      emisorOrigen,
      emisorDestinatario,
      importeCargo
    );
  }

  getTrxSicoopTransTC_TP = (emisorOrigen, cuentaOrigen, ciOriginante, titularOriginante, emisorDestinatario, cuentaDestino, ciDestinatario, titularDestinatario, importe, importeCargo, idQr) => {
    const participanteOrigen = this.getParticipanteOrigenTrxTC(emisorOrigen);
    const participanteDestino = this.getParticipanteEmisor(emisorDestinatario);

    const tipoTrxSicoop = transaccionesSicoop.TRAN;
    // Por Defecto es TRANINTE, salvo que se pague una PANAL
    const codServicioTrxSicoop = !this.isParticipantePanal(participanteOrigen.codParticipante) ? tipoTrxSicoop.servicios.TRANINTE : tipoTrxSicoop.servicios.TRANDIMO;

    return this.getMensajeSicoop1A(
      tipoTrxSicoop.value,
      codServicioTrxSicoop,
      participanteOrigen.codParticipante,
      tiposCuentaOrigen.TCO,
      cuentaOrigen,
      ciOriginante,
      titularOriginante,
      participanteDestino.codParticipante,
      tiposCuentaDestino.TPD,
      cuentaDestino,
      ciDestinatario,
      titularDestinatario,
      'Transferencia TC a DIMO', // glosa
      importe,
      monedas.GS,
      emisorOrigen,
      emisorDestinatario,
      importeCargo,
      idQr
    );
  }

  getTrxSicoopTransTC_BANCO = (emisorOrigen, cuentaOrigen, ciOriginante, titularOriginante, emisorDestinatario, cuentaDestino, ciDestinatario, titularDestinatario, importe, importeCargo) => {
    const participanteOrigen = this.getParticipanteOrigenTrxTC(emisorOrigen);
    const participanteDestino = this.getParticipanteBancard();

    const tipoTrxSicoop = transaccionesSicoop.TRAN;

    return this.getMensajeSicoop1A(
      tipoTrxSicoop.value,
      tipoTrxSicoop.servicios.TRANSFER,
      participanteOrigen.codParticipante,
      tiposCuentaOrigen.TCO,
      cuentaOrigen,
      ciOriginante,
      titularOriginante,
      participanteDestino.codParticipante,
      tiposCuentaDestino.BANCOD,
      cuentaDestino,
      ciDestinatario,
      titularDestinatario,
      'Transferencia TC a BANCO', // glosa
      importe,
      monedas.GS,
      emisorOrigen,
      emisorDestinatario,
      importeCargo
    );
  }

  getTrxSicoopPagoCtaAho_TC = (codParticipanteOrigen, cuentaOrigen, ciOriginante, titularOriginante, emisorDestinatario, cuentaDestino, ciDestinatario, titularDestinatario, importe, importeCargo) => {
    const participanteDestino = this.getParticipanteDestinoPagoTCEmisor(emisorDestinatario);

    const tipoTrxSicoop = transaccionesSicoop.PAGO;
    const codServTrxSicoop = !this.isParticipantePanal(participanteDestino.codParticipante) ? tipoTrxSicoop.servicios.PAGOTC : tipoTrxSicoop.servicios.PAGOTCPANAL;

    return this.getMensajeSicoop1A(
      tipoTrxSicoop.value,
      // tipoTrxSicoop.servicios.PAGOTC,
      codServTrxSicoop,
      codParticipanteOrigen,
      tiposCuentaOrigen.CCAHOO,
      cuentaOrigen,
      ciOriginante,
      titularOriginante,
      participanteDestino.codParticipante,
      tiposCuentaDestino.TCD,
      cuentaDestino,
      ciDestinatario,
      titularDestinatario,
      'Pago de extracto de TC desde Caja de Ahorro', // glosa
      importe,
      monedas.GS,
      '',
      emisorDestinatario,
      importeCargo,
    );
  }

  getTrxSicoopPagoTP_TC = (emisorOrigen, cuentaOrigen, ciOriginante, titularOriginante, emisorDestinatario, cuentaDestino, ciDestinatario, titularDestinatario, importe, importeCargo) => {
    const participanteOrigen = this.getParticipanteEmisor(emisorOrigen);
    const participanteDestino = this.getParticipanteDestinoPagoTCEmisor(emisorDestinatario);

    // Tipo Transacion y Codigo de Servicio
    const tipoTrxSicoop = transaccionesSicoop.PAGO;
    // Por Defecto es PAGOINTE, salvo que se pague una PANAL
    const codServTrxSicoop = !this.isParticipantePanal(participanteDestino.codParticipante) ? tipoTrxSicoop.servicios.PAGOINTE : tipoTrxSicoop.servicios.PAGOTCPANAL;

    return this.getMensajeSicoop1A(
      tipoTrxSicoop.value,
      codServTrxSicoop,
      participanteOrigen.codParticipante,
      tiposCuentaOrigen.TPO,
      cuentaOrigen,
      ciOriginante,
      titularOriginante,
      participanteDestino.codParticipante,
      tiposCuentaDestino.TCD,
      cuentaDestino,
      ciDestinatario,
      titularDestinatario,
      'Pago de extracto de TC desde DIMO', // glosa
      importe,
      monedas.GS,
      emisorOrigen,
      emisorDestinatario,
      importeCargo,
    );
  }

  getTrxSicoopcompraTicket = (emisorOrigen, cuentaOrigen, ciOriginante, titularOriginante, codDestinatario, cuentaDestino, ciDestinatario, titularDestinatario, importe, importeCargo, tipoCuentaOrigen) => {
    const codParticipanteOrigen = tipoCuentaOrigen === tiposCuentaOrigen.CCAHOO ? emisorOrigen : this.getParticipanteDestinoPagoTCEmisor(emisorOrigen).codParticipante;
    const participanteDestino = codDestinatario;

    // Tipo Transacion y Codigo de Servicio
    const tipoTrxSicoop = transaccionesSicoop.PAGO;
    const codServTrxSicoop = transaccionesSicoop.PAGO.servicios.SERVICIO;

    return this.getMensajeSicoop1A(
      tipoTrxSicoop.value,
      codServTrxSicoop,
      codParticipanteOrigen,
      tipoCuentaOrigen,
      cuentaOrigen,
      ciOriginante,
      titularOriginante,
      participanteDestino,
      tiposCuentaDestino.RCD,
      cuentaDestino,
      ciDestinatario,
      titularDestinatario,
      'Compra de ticket', // glosa
      importe,
      monedas.GS,
      emisorOrigen,
      null,
      importeCargo,
    );
  }

  // ======================= VERIFICAR PROVEEDOR ============================== //
  verificarProveedor = (dataPrm) => new Promise((resolve, reject) => {

    axios.post(`${bank_service_api_url}/wsVerificarProveedor`, { Request: dataPrm })
      .then((response) => {
        if (response.data.header) {
          if (response.data.header.codReturn === '0') {
            resolve(response.data.data)
          } else {
            reject({ error: { message: response.data.header.txtReturn } });
          }
        } else {
          // console.log('response incorrecto:' + response);
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

  // ======================= TRANSACCIONES SICOOP ============================== //

  // ***** Pin Transaccional ***** //
  // Consulta si Requiere Pin Transaccional
  requierePinTransaccional = (dataPrm) => new Promise((resolve, reject) => {
    let codParticipanteOrigen = dataPrm.codParticipanteOrigen ? dataPrm.codParticipanteOrigen : null;
    let codParticipanteDestino = dataPrm.codParticipanteDestino ? dataPrm.codParticipanteDestino : null;

    // Si no se pudo obtener el codigo de Participante, verificamos si se envio el codigo de Emisor
    if (!codParticipanteOrigen) {
      if (dataPrm.emisorOrigen) {
        codParticipanteOrigen = this.getParticipanteEmisor(dataPrm.emisorOrigen).codParticipante;
      }
    }

    if (!codParticipanteDestino) {
      if (dataPrm.emisorDestinatario) {
        codParticipanteDestino = this.getParticipanteEmisor(dataPrm.emisorDestinatario).codParticipante;
      }
    }

    const dataPost = {
      codParticipanteOrigen,
      codParticipanteDestino,
      documentoOriginante: dataPrm.documentoOriginante,
      documentoDestinatario: dataPrm.documentoDestinatario,
      tipoTransaccion: dataPrm.tipoTransaccion,
      codigoServicio: dataPrm.codigoServicio,
      importe: dataPrm.importe,
    };

    /// /console.log("dataReqPinPost", JSON.stringify(dataPost,null,2));

    axios.post(`${API_URL}/v1/requierePin`, { header: BODY_HEADER, data: dataPost }, { headers: HEADERS() })
      .then((response) => {
        if (response.data.header) {
          if (response.data.header.codReturn === '0') {
            if (response.data.data.hasOwnProperty('requierePin'))
            // resolve(false); //test
            { resolve(response.data.data.requierePin); } else reject({ error: { message: 'No se encontro la propiedad "requierePin"' } });
          } else {
            reject({ error: { message: response.data.header.txtReturn } });
          }
        } else {
          // console.log('response incorrecto:' + response);
          reject();
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
        // resolve(false); //test
      });
  });

  requierePinTransaccionalServicio = (dataPrm) => new Promise((resolve, reject) => {
    let codParticipanteOrigen = dataPrm.codParticipanteOrigen ? dataPrm.codParticipanteOrigen : null;
    let codParticipanteDestino = dataPrm.codParticipanteDestino ? dataPrm.codParticipanteDestino : null;

    // Si no se pudo obtener el codigo de Participante, verificamos si se envio el codigo de Emisor
    if (!codParticipanteOrigen) {
      if (dataPrm.emisorOrigen) {
        codParticipanteOrigen = this.getParticipanteEmisor(dataPrm.emisorOrigen).codParticipante;
      }
    }

    if (!codParticipanteDestino) {
      if (dataPrm.emisorDestinatario) {
        codParticipanteDestino = this.getParticipanteEmisor(dataPrm.emisorDestinatario).codParticipante;
      }
    }

    const dataPost = {
      codParticipanteOrigen,
      codParticipanteDestino,
      documentoOriginante: dataPrm.documentoOriginante,
      documentoDestinatario: dataPrm.documentoDestinatario,
      tipoTransaccion: dataPrm.tipoTransaccion,
      codigoServicio: dataPrm.codigoServicio,
      tipoCuentaOrigen: dataPrm.tipoCuentaOrigen,
      serviceId: dataPrm.serviceId,
      entityId: dataPrm.entityId,
      importe: dataPrm.importe,
    };

    /// /console.log("dataReqPinPost", JSON.stringify(dataPost,null,2));

    axios.post(`${API_URL}/v1/requierePinPagoServicio`, { header: BODY_HEADER, data: dataPost }, { headers: HEADERS() })
      .then((response) => {
        if (response.data.header) {
          if (response.data.header.codReturn === '0') {
            if (response.data.data.hasOwnProperty('requierePin'))
            // resolve(false); //test
            { resolve(response.data.data.requierePin); } else reject({ error: { message: 'No se encontro la propiedad "requierePin"' } });
          } else {
            reject({ error: { message: response.data.header.txtReturn } });
          }
        } else {
          // console.log('response incorrecto:' + response);
          reject();
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
        // resolve(false); //test
      });
  });

  // Genera y envia el OTP del Pin Transaccional
  generarPinTransaccional = (dataPrm) => new Promise((resolve, reject) => {
    const dataPost = {
      documento: dataPrm.documento,
    };

    axios.post(`${API_URL}/v1/generarPinTransaccional`, { header: BODY_HEADER, data: dataPost }, { headers: HEADERS() })
      .then((response) => {
        if (response.data.header) {
          if (response.data.header.codReturn === '0') {
            resolve(response.data.data);
          } else {
            reject({ error: { message: response.data.header.txtReturn } });
          }
        } else {
          // console.log('response incorrecto:' + response);

          reject();
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  });

  // Procesar y  Validar si el Pin transaccional es valido
  procesarPinTransaccional = (dataPrm) => new Promise((resolve, reject) => {
    const dataPost = {
      tokenIdTrx: dataPrm.tokenIdTrx ? dataPrm.tokenIdTrx : '',
      documento: dataPrm.documento ? dataPrm.documento : '',
      pin: dataPrm.pin ? dataPrm.pin : '',
    };

    axios.post(`${API_URL}/v1/procesarPinTransaccional`, { header: BODY_HEADER, data: dataPost }, { headers: HEADERS() })
      .then((response) => {
        if (response.data.header) {
          if (response.data.header.codReturn === '0') {
            resolve(response.data.data);
          } else {
            reject({ error: { message: response.data.header.txtReturn } });
          }
        } else {
          // console.log('response incorrecto:' + response);

          reject();
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  });

  // Consulta si la operacion tiene un Cargo Transaccional
  tieneCargoTransaccional = (dataPrm) => new Promise((resolve, reject) => {
    const dataPost = {
      canal: dataPrm.canal ? dataPrm.canal : 'WEB',
      tipoTransaccion: dataPrm.tipoTransaccion ? dataPrm.tipoTransaccion : '',
      codigoServicio: dataPrm.codigoServicio ? dataPrm.codigoServicio : '',
      EFOriginante: dataPrm.EFOriginante ? dataPrm.EFOriginante : '',
      cuentaOriginante: dataPrm.numeroCuentaOrigen ? dataPrm.numeroCuentaOrigen : '',
      tipoCuentaOrigen: dataPrm.tipoCuentaOrigen ? dataPrm.tipoCuentaOrigen : '',
      EFDestinatario: dataPrm.EFDestinatario ? dataPrm.EFDestinatario : '',
      cuentaDestinatario: dataPrm.numeroCuentaDestino ? dataPrm.numeroCuentaDestino : '',
      tipoCuentaDestino: dataPrm.tipoCuentaDestino ? dataPrm.tipoCuentaDestino : '',
      montoTransaccion: dataPrm.montoTransaccion ? dataPrm.montoTransaccion : '0'
    };
    
    // console.log("dataPostGetCargo", JSON.stringify(dataPost,null,2));

    axios.post(`${API_URL_CARGOTX}/v1/CargoTxsDimo`, { header: BODY_HEADER, data: dataPost }, { headers: HEADERS() })
      .then((response) => {
        if (response.data.header) {
          if (response.data.header.codReturn === '0') {
            if (response.data.hasOwnProperty('data')) {
              const dataResponse = response.data.data;
              const dataCargoTrx = {
                costoId: dataResponse.hasOwnProperty('COSTO_ID') ? dataResponse.COSTO_ID : '',
                costoDescripcion: dataResponse.hasOwnProperty('COSTO_DESCRIPCION') ? dataResponse.COSTO_DESCRIPCION : '',
                montoCargoOriginante: dataResponse.hasOwnProperty('MONTO_CARGO_ORIGINANTE') ? dataResponse.MONTO_CARGO_ORIGINANTE : '0',
                montoCargoDestinatario: dataResponse.hasOwnProperty('MONTO_CARGO_DESTINATARIO') ? dataResponse.MONTO_CARGO_DESTINATARIO : '0',
                iva: dataResponse.hasOwnProperty('IVA') ? dataResponse.IVA : '0',
                rubro: dataResponse.hasOwnProperty('RUBRO') ? dataResponse.RUBRO : '0',
              };
              if(dataResponse.COSTO_ID !== null || dataResponse.COSTO_ID !== "0"){
                localStorage.setItem("CargoTrxData", JSON.stringify({PI_ID_CARGO:dataResponse.COSTO_ID,PI_CUENTA:dataPost.cuentaOriginante}));
              }
              resolve(dataCargoTrx);
            } else reject({ error: { message: 'No se encontro la propiedad "data"' } });
          } else {
            reject({ error: { message: response.data.header.txtReturn } });
          }
        } else {
          // console.log('response incorrecto:' + response);
          reject({ error: { message: 'Retorno Invalido' } });
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  });

  // ***** Pagos ***** //
  pagosTP_TC = (Pago) => new Promise((resolve, reject) => {
    const mensaje1A = this.getTrxSicoopPagoTP_TC(
      Pago.emisorOrigen,
      Pago.cuentaOrigen,
      Pago.ciOriginante ? Pago.ciOriginante : '',
      Pago.titularOriginante ? Pago.titularOriginante : '',
      Pago.emisorDestinatario,
      Pago.cuentaDestino,
      Pago.ciDestinatario ? Pago.ciDestinatario : '',
      Pago.titularDestinatario ? Pago.titularDestinatario : '',
      Pago.importe,
      Pago.importeCargo ? Pago.importeCargo : '0',
    );

    mensaje1A.aditionalParameters = mensaje1A.aditionalParameters.concat(
      Array.isArray(Pago.datosAdicionales) ? Pago.datosAdicionales : []
    );

    /// /console.log("mensaje1A", JSON.stringify(mensaje1A,null,2));
    // axios.post(API_URL + '/v1/transferenciaAutorizador', { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
    axios.post(`${API_URL}/v1/pago`, { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
      .then((response) => {
        if (response.data.hasOwnProperty('header')) {
          if (response.data.header.codReturn === '0' || response.data.header.codResultado === '0') {
            if (!response.data.hasOwnProperty('data')) reject({ error: { message: 'No se pudo validar la respuesta de la operacion' } });
            // Si tiene la propiedad Data...
            // *** Evaluamos mejor el Response desde el Backend ***
            const { idOperacion } = response.data.data;
            const estadoResponse = response.data.data.estado;
            const descEstadoResponse = (response.data.data.hasOwnProperty('txtRespuesta')
              && response.data.data.txtRespuesta !== null
              && response.data.data.txtRespuesta.length > 1) ? response.data.data.txtRespuesta : 'Transaccion Rechazada';
            if (estadoResponse === 'AP') // Aprobado
            {
              resolve({
                estado: 'A', descripcion: 'Aprobada', idOperacion, request: mensaje1A, response: response.data.data
              }); // Aprobado Simple
            } else if (estadoResponse === 'SR' || estadoResponse === 'NA') // Rechazado por alguna razon
            {
              resolve({
                estado: 'R', descripcion: descEstadoResponse, idOperacion, request: mensaje1A, response: response.data.data
              });// Rechazo Simple
            } else if (estadoResponse === 'PE') // Pendiente
            {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            } else {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            }
          } else {
            // console.log("response", JSON.stringify(response, null, 2))
            reject({ error: { message: response.data.header.txtReturn ? response.data.header.txtReturn : response.data.header.txtResultado } });
          }
        } else {
          // console.log('response incorrecto:' + response);
          reject({ error: {} });
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  });

  pagosTP_CoopCRED = (Pago) => new Promise((resolve, reject) => {
    const mensaje1A = this.getTrxSicoopPagoTP_CoopCRED(
      Pago.cuentaOrigen,
      Pago.ciOriginante ? Pago.ciOriginante : '',
      Pago.titularOriginante ? Pago.titularOriginante : '',
      Pago.codParticipanteDestino,
      Pago.cuentaDestino,
      Pago.ciDestinatario ? Pago.ciDestinatario : '',
      Pago.titularDestinatario ? Pago.titularDestinatario : '',
      Pago.importe,
      Pago.importeCargo ? Pago.importeCargo : '0',
    );

    mensaje1A.aditionalParameters = mensaje1A.aditionalParameters.concat(
      Array.isArray(Pago.datosAdicionales) ? Pago.datosAdicionales : []
    );

    // console.log("PagoCrd", JSON.stringify(Pago,null,2));
    // console.log("mensaje1A", JSON.stringify(mensaje1A,null,2));
    // axios.post(API_URL + '/v1/transferenciaAutorizador', { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
    axios.post(`${API_URL}/v1/pago`, { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
      .then((response) => {
        if (response.data.hasOwnProperty('header')) {
          if (response.data.header.codReturn === '0' || response.data.header.codResultado === '0') {
            if (!response.data.hasOwnProperty('data')) reject({ error: { message: 'No se pudo validar la respuesta de la operacion' } });
            // Si tiene la propiedad Data...
            // *** Evaluamos mejor el Response desde el Backend ***
            const { idOperacion } = response.data.data;
            const estadoResponse = response.data.data.estado;
            const descEstadoResponse = (response.data.data.hasOwnProperty('txtRespuesta')
              && response.data.data.txtRespuesta !== null
              && response.data.data.txtRespuesta.length > 1) ? response.data.data.txtRespuesta : 'Transaccion Rechazada';
            if (estadoResponse === 'AP') // Aprobado
            {
              resolve({
                estado: 'A', descripcion: 'Aprobada', idOperacion, request: mensaje1A, response: response.data.data
              }); // Aprobado Simple
            } else if (estadoResponse === 'SR' || estadoResponse === 'NA') // Rechazado por alguna razon
            {
              resolve({
                estado: 'R', descripcion: descEstadoResponse, idOperacion, request: mensaje1A, response: response.data.data
              });// Rechazo Simple
            } else if (estadoResponse === 'PE') // Pendiente
            {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            } else {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            }
          } else {
            // console.log("response", JSON.stringify(response, null, 2))
            reject({ error: { message: response.data.header.txtReturn ? response.data.header.txtReturn : response.data.header.txtResultado } });
          }
        } else {
          // console.log('response incorrecto:' + response);
          reject({ error: {} });
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  });

  pagosCoopOBLIGACION = (Pago) => new Promise((resolve, reject) => {
    const mensaje1A = this.getTrxSicoopPagoCoopOBLIGACION(
      // si el tipo de cuenta Origen es TPO se envia null para el codParticipanteOrigen en caso
      // contrario se envia el codigoParticipante que se encuentra en la variable Pago
      Pago.tipoCuentaOrigen === tiposCuentaOrigen.TPO ? null : Pago.codParticipanteOrigen,
      Pago.cuentaOrigen,
      Pago.ciOriginante ? Pago.ciOriginante : '',
      Pago.titularOriginante ? Pago.titularOriginante : '',
      Pago.codParticipanteDestino,
      Pago.cuentaDestino,
      Pago.ciDestinatario ? Pago.ciDestinatario : '',
      Pago.titularDestinatario ? Pago.titularDestinatario : '',
      Pago.importe,
      Pago.importeCargo ? Pago.importeCargo : '0',
      // codigo servicio que es el codigo que se envia para la transaccion del pago de obligacion
      Pago.codigoServicio,
      // Nombre de la Obligacion que se utilizara en la glosa, viene de la base de datos en DIMO
      Pago.obligacionNombre
    );

    mensaje1A.aditionalParameters = mensaje1A.aditionalParameters.concat(
      Array.isArray(Pago.datosAdicionales) ? Pago.datosAdicionales : []
    );

    // axios.post(API_URL + '/v1/transferenciaAutorizador', { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
    axios.post(`${API_URL}/v1/pago`, { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
      .then((response) => {
        if (response.data.hasOwnProperty('header')) {
          if (response.data.header.codReturn === '0' || response.data.header.codResultado === '0') {
            if (!response.data.hasOwnProperty('data')) reject({ error: { message: 'No se pudo validar la respuesta de la operacion' } });
            // Si tiene la propiedad Data...
            // *** Evaluamos mejor el Response desde el Backend ***
            const { idOperacion } = response.data.data;
            const estadoResponse = response.data.data.estado;
            const descEstadoResponse = (response.data.data.hasOwnProperty('txtRespuesta')
              && response.data.data.txtRespuesta !== null
              && response.data.data.txtRespuesta.length > 1) ? response.data.data.txtRespuesta : 'Transaccion Rechazada';
            if (estadoResponse === 'AP') // Aprobado
            {
              resolve({
                estado: 'A', descripcion: 'Aprobada', idOperacion, request: mensaje1A, response: response.data.data
              }); // Aprobado Simple
            } else if (estadoResponse === 'SR' || estadoResponse === 'NA') // Rechazado por alguna razon
            {
              resolve({
                estado: 'R', descripcion: descEstadoResponse, idOperacion, request: mensaje1A, response: response.data.data
              });// Rechazo Simple
            } else if (estadoResponse === 'PE') // Pendiente
            {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            } else {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            }
          } else {
            // console.log("response", JSON.stringify(response, null, 2))
            reject({ error: { message: response.data.header.txtReturn ? response.data.header.txtReturn : response.data.header.txtResultado } });
          }
        } else {
          // console.log('response incorrecto:' + response);
          reject({ error: {} });
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  });

  pagosTP_CoopRUEDA = (Pago) => new Promise((resolve, reject) => {
    const mensaje1A = this.getTrxSicoopPagoTP_CoopRUEDA(
      Pago.cuentaOrigen,
      Pago.ciOriginante ? Pago.ciOriginante : '',
      Pago.titularOriginante ? Pago.titularOriginante : '',
      Pago.codParticipanteDestino,
      Pago.cuentaDestino,
      Pago.ciDestinatario ? Pago.ciDestinatario : '',
      Pago.titularDestinatario ? Pago.titularDestinatario : '',
      Pago.importe,
      Pago.importeCargo ? Pago.importeCargo : '0',
    );

    mensaje1A.aditionalParameters = mensaje1A.aditionalParameters.concat(
      Array.isArray(Pago.datosAdicionales) ? Pago.datosAdicionales : []
    );

    /// /console.log("mensaje1A", JSON.stringify(mensaje1A,null,2));
    // axios.post(API_URL + '/v1/transferenciaAutorizador', { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
    axios.post(`${API_URL}/v1/pago`, { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
      .then((response) => {
        if (response.data.hasOwnProperty('header')) {
          if (response.data.header.codReturn === '0' || response.data.header.codResultado === '0') {
            if (!response.data.hasOwnProperty('data')) reject({ error: { message: 'No se pudo validar la respuesta de la operacion' } });
            // Si tiene la propiedad Data...
            // *** Evaluamos mejor el Response desde el Backend ***
            const { idOperacion } = response.data.data;
            const estadoResponse = response.data.data.estado;
            const descEstadoResponse = (response.data.data.hasOwnProperty('txtRespuesta')
              && response.data.data.txtRespuesta !== null
              && response.data.data.txtRespuesta.length > 1) ? response.data.data.txtRespuesta : 'Transaccion Rechazada';
            if (estadoResponse === 'AP') // Aprobado
            {
              resolve({
                estado: 'A', descripcion: 'Aprobada', idOperacion, request: mensaje1A, response: response.data.data
              }); // Aprobado Simple
            } else if (estadoResponse === 'SR' || estadoResponse === 'NA') // Rechazado por alguna razon
            {
              resolve({
                estado: 'R', descripcion: descEstadoResponse, idOperacion, request: mensaje1A, response: response.data.data
              });// Rechazo Simple
            } else if (estadoResponse === 'PE') // Pendiente
            {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            } else {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            }
          } else {
            // console.log("response", JSON.stringify(response, null, 2))
            reject({ error: { message: response.data.header.txtReturn ? response.data.header.txtReturn : response.data.header.txtResultado } });
          }
        } else {
          // console.log('response incorrecto:' + response);
          reject({ error: {} });
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  });

  pagosTP_CoopAHOPRO = (Pago) => new Promise((resolve, reject) => {
    const mensaje1A = this.getTrxSicoopPagoTP_CoopAHOPRO(
      Pago.cuentaOrigen,
      Pago.ciOriginante ? Pago.ciOriginante : '',
      Pago.titularOriginante ? Pago.titularOriginante : '',
      Pago.codParticipanteDestino,
      Pago.cuentaDestino,
      Pago.ciDestinatario ? Pago.ciDestinatario : '',
      Pago.titularDestinatario ? Pago.titularDestinatario : '',
      Pago.importe,
      Pago.importeCargo ? Pago.importeCargo : '0',
    );

    mensaje1A.aditionalParameters = mensaje1A.aditionalParameters.concat(
      Array.isArray(Pago.datosAdicionales) ? Pago.datosAdicionales : []
    );

    /// /console.log("mensaje1A", JSON.stringify(mensaje1A,null,2));
    // axios.post(API_URL + '/v1/transferenciaAutorizador', { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
    axios.post(`${API_URL}/v1/pago`, { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
      .then((response) => {
        if (response.data.hasOwnProperty('header')) {
          if (response.data.header.codReturn === '0' || response.data.header.codResultado === '0') {
            if (!response.data.hasOwnProperty('data')) reject({ error: { message: 'No se pudo validar la respuesta de la operacion' } });
            // Si tiene la propiedad Data...
            // *** Evaluamos mejor el Response desde el Backend ***
            const { idOperacion } = response.data.data;
            const estadoResponse = response.data.data.estado;
            const descEstadoResponse = (response.data.data.hasOwnProperty('txtRespuesta')
              && response.data.data.txtRespuesta !== null
              && response.data.data.txtRespuesta.length > 1) ? response.data.data.txtRespuesta : 'Transaccion Rechazada';
            if (estadoResponse === 'AP') // Aprobado
            {
              resolve({
                estado: 'A', descripcion: 'Aprobada', idOperacion, request: mensaje1A, response: response.data.data
              }); // Aprobado Simple
            } else if (estadoResponse === 'SR' || estadoResponse === 'NA') // Rechazado por alguna razon
            {
              resolve({
                estado: 'R', descripcion: descEstadoResponse, idOperacion, request: mensaje1A, response: response.data.data
              });// Rechazo Simple
            } else if (estadoResponse === 'PE') // Pendiente
            {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            } else {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            }
          } else {
            // console.log("response", JSON.stringify(response, null, 2))
            reject({ error: { message: response.data.header.txtReturn ? response.data.header.txtReturn : response.data.header.txtResultado } });
          }
        } else {
          // console.log('response incorrecto:' + response);
          reject({ error: {} });
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  });

  pagosCtaAho_TC = (Pago) => new Promise((resolve, reject) => {
    const mensaje1A = this.getTrxSicoopPagoCtaAho_TC(
      Pago.codParticipanteOrigen,
      Pago.cuentaOrigen,
      Pago.ciOriginante ? Pago.ciOriginante : '',
      Pago.titularOriginante ? Pago.titularOriginante : '',
      Pago.emisorDestinatario ? Pago.emisorDestinatario : '',
      Pago.cuentaDestino,
      Pago.ciDestinatario ? Pago.ciDestinatario : '',
      Pago.titularDestinatario ? Pago.titularDestinatario : '',
      Pago.importe,
      Pago.importeCargo ? Pago.importeCargo : '0'
    );

    mensaje1A.aditionalParameters = mensaje1A.aditionalParameters.concat(
      Array.isArray(Pago.datosAdicionales) ? Pago.datosAdicionales : []
    );

    // console.log("mensaje1A", JSON.stringify(mensaje1A,null,2));
    // axios.post(API_URL + '/v1/transferenciaAutorizador', { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
    axios.post(`${API_URL}/v1/pago`, { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
      .then((response) => {
        if (response.data.hasOwnProperty('header')) {
          if (response.data.header.codReturn === '0' || response.data.header.codResultado === '0') {
            if (!response.data.hasOwnProperty('data')) reject({ error: { message: 'No se pudo validar la respuesta de la operacion' } });
            // Si tiene la propiedad Data...
            // *** Evaluamos mejor el Response desde el Backend ***
            const { idOperacion } = response.data.data;
            const estadoResponse = response.data.data.estado;
            const descEstadoResponse = (response.data.data.hasOwnProperty('txtRespuesta')
              && response.data.data.txtRespuesta !== null
              && response.data.data.txtRespuesta.length > 1) ? response.data.data.txtRespuesta : 'Transaccion Rechazada';
            if (estadoResponse === 'AP') // Aprobado
            {
              resolve({
                estado: 'A', descripcion: 'Aprobada', idOperacion, request: mensaje1A, response: response.data.data
              }); // Aprobado Simple
            } else if (estadoResponse === 'SR' || estadoResponse === 'NA') // Rechazado por alguna razon
            {
              resolve({
                estado: 'R', descripcion: descEstadoResponse, idOperacion, request: mensaje1A, response: response.data.data
              });// Rechazo Simple
            } else if (estadoResponse === 'PE') // Pendiente
            {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            } else {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            }
          } else {
            // console.log("response", JSON.stringify(response, null, 2))
            reject({ error: { message: response.data.header.txtReturn ? response.data.header.txtReturn : response.data.header.txtResultado } });
          }
        } else {
          // console.log('response incorrecto:' + response);
          reject({ error: {} });
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  });

  pagosCtaAho_CoopCRED = (Pago) => new Promise((resolve, reject) => {
    const mensaje1A = this.getTrxSicoopPagoCtaAho_CoopCRED(
      Pago.codParticipanteOrigen,
      Pago.cuentaOrigen,
      Pago.ciOriginante ? Pago.ciOriginante : '',
      Pago.titularOriginante ? Pago.titularOriginante : '',
      Pago.codParticipanteDestino,
      Pago.cuentaDestino,
      Pago.ciDestinatario ? Pago.ciDestinatario : '',
      Pago.titularDestinatario ? Pago.titularDestinatario : '',
      Pago.importe,
      Pago.importeCargo ? Pago.importeCargo : '0',
    );

    mensaje1A.aditionalParameters = mensaje1A.aditionalParameters.concat(
      Array.isArray(Pago.datosAdicionales) ? Pago.datosAdicionales : []
    );

    /// /console.log("mensaje1A", JSON.stringify(mensaje1A,null,2));
    // axios.post(API_URL + '/v1/transferenciaAutorizador', { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
    axios.post(`${API_URL}/v1/pago`, { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
      .then((response) => {
        if (response.data.hasOwnProperty('header')) {
          if (response.data.header.codReturn === '0' || response.data.header.codResultado === '0') {
            if (!response.data.hasOwnProperty('data')) reject({ error: { message: 'No se pudo validar la respuesta de la operacion' } });
            // Si tiene la propiedad Data...
            // *** Evaluamos mejor el Response desde el Backend ***
            const { idOperacion } = response.data.data;
            const estadoResponse = response.data.data.estado;
            const descEstadoResponse = (response.data.data.hasOwnProperty('txtRespuesta')
              && response.data.data.txtRespuesta !== null
              && response.data.data.txtRespuesta.length > 1) ? response.data.data.txtRespuesta : 'Transaccion Rechazada';
            if (estadoResponse === 'AP') // Aprobado
            {
              resolve({
                estado: 'A', descripcion: 'Aprobada', idOperacion, request: mensaje1A, response: response.data.data
              }); // Aprobado Simple
            } else if (estadoResponse === 'SR' || estadoResponse === 'NA') // Rechazado por alguna razon
            {
              resolve({
                estado: 'R', descripcion: descEstadoResponse, idOperacion, request: mensaje1A, response: response.data.data
              });// Rechazo Simple
            } else if (estadoResponse === 'PE') // Pendiente
            {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            } else {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            }
          } else {
            // console.log("response", JSON.stringify(response, null, 2))
            reject({ error: { message: response.data.header.txtReturn ? response.data.header.txtReturn : response.data.header.txtResultado } });
          }
        } else {
          // console.log('response incorrecto:' + response);
          reject({ error: {} });
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  });

  pagosCtaAho_CoopRUEDA = (Pago) => new Promise((resolve, reject) => {
    const mensaje1A = this.getTrxSicoopPagoCtaAho_RUEDA(
      Pago.codParticipanteOrigen,
      Pago.cuentaOrigen,
      Pago.ciOriginante ? Pago.ciOriginante : '',
      Pago.titularOriginante ? Pago.titularOriginante : '',
      Pago.codParticipanteDestino,
      Pago.cuentaDestino,
      Pago.ciDestinatario ? Pago.ciDestinatario : '',
      Pago.titularDestinatario ? Pago.titularDestinatario : '',
      Pago.importe,
      Pago.importeCargo ? Pago.importeCargo : '0',
    );

    mensaje1A.aditionalParameters = mensaje1A.aditionalParameters.concat(
      Array.isArray(Pago.datosAdicionales) ? Pago.datosAdicionales : []
    );

    /// /console.log("mensaje1A", JSON.stringify(mensaje1A,null,2));
    // axios.post(API_URL + '/v1/transferenciaAutorizador', { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
    axios.post(`${API_URL}/v1/pago`, { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
      .then((response) => {
        if (response.data.hasOwnProperty('header')) {
          if (response.data.header.codReturn === '0' || response.data.header.codResultado === '0') {
            if (!response.data.hasOwnProperty('data')) reject({ error: { message: 'No se pudo validar la respuesta de la operacion' } });
            // Si tiene la propiedad Data...
            // *** Evaluamos mejor el Response desde el Backend ***
            const { idOperacion } = response.data.data;
            const estadoResponse = response.data.data.estado;
            const descEstadoResponse = (response.data.data.hasOwnProperty('txtRespuesta')
              && response.data.data.txtRespuesta !== null
              && response.data.data.txtRespuesta.length > 1) ? response.data.data.txtRespuesta : 'Transaccion Rechazada';
            if (estadoResponse === 'AP') // Aprobado
            {
              resolve({
                estado: 'A', descripcion: 'Aprobada', idOperacion, request: mensaje1A, response: response.data.data
              }); // Aprobado Simple
            } else if (estadoResponse === 'SR' || estadoResponse === 'NA') // Rechazado por alguna razon
            {
              resolve({
                estado: 'R', descripcion: descEstadoResponse, idOperacion, request: mensaje1A, response: response.data.data
              });// Rechazo Simple
            } else if (estadoResponse === 'PE') // Pendiente
            {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            } else {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            }
          } else {
            // console.log("response", JSON.stringify(response, null, 2))
            reject({ error: { message: response.data.header.txtReturn ? response.data.header.txtReturn : response.data.header.txtResultado } });
          }
        } else {
          // console.log('response incorrecto:' + response);
          reject({ error: {} });
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  });

  pagosCtaAho_CoopAHOPRO = (Pago) => new Promise((resolve, reject) => {
    const mensaje1A = this.getTrxSicoopPagoCtaAho_AHOPRO(
      Pago.codParticipanteOrigen,
      Pago.cuentaOrigen,
      Pago.ciOriginante ? Pago.ciOriginante : '',
      Pago.titularOriginante ? Pago.titularOriginante : '',
      Pago.codParticipanteDestino,
      Pago.cuentaDestino,
      Pago.ciDestinatario ? Pago.ciDestinatario : '',
      Pago.titularDestinatario ? Pago.titularDestinatario : '',
      Pago.importe,
      Pago.importeCargo ? Pago.importeCargo : '0',
    );

    mensaje1A.aditionalParameters = mensaje1A.aditionalParameters.concat(
      Array.isArray(Pago.datosAdicionales) ? Pago.datosAdicionales : []
    );

    /// /console.log("mensaje1A", JSON.stringify(mensaje1A,null,2));
    // axios.post(API_URL + '/v1/transferenciaAutorizador', { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
    axios.post(`${API_URL}/v1/pago`, { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
      .then((response) => {
        if (response.data.hasOwnProperty('header')) {
          if (response.data.header.codReturn === '0' || response.data.header.codResultado === '0') {
            if (!response.data.hasOwnProperty('data')) reject({ error: { message: 'No se pudo validar la respuesta de la operacion' } });
            // Si tiene la propiedad Data...
            // *** Evaluamos mejor el Response desde el Backend ***
            const { idOperacion } = response.data.data;
            const estadoResponse = response.data.data.estado;
            const descEstadoResponse = (response.data.data.hasOwnProperty('txtRespuesta')
              && response.data.data.txtRespuesta !== null
              && response.data.data.txtRespuesta.length > 1) ? response.data.data.txtRespuesta : 'Transaccion Rechazada';
            if (estadoResponse === 'AP') // Aprobado
            {
              resolve({
                estado: 'A', descripcion: 'Aprobada', idOperacion, request: mensaje1A, response: response.data.data
              }); // Aprobado Simple
            } else if (estadoResponse === 'SR' || estadoResponse === 'NA') // Rechazado por alguna razon
            {
              resolve({
                estado: 'R', descripcion: descEstadoResponse, idOperacion, request: mensaje1A, response: response.data.data
              });// Rechazo Simple
            } else if (estadoResponse === 'PE') // Pendiente
            {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            } else {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            }
          } else {
            // console.log("response", JSON.stringify(response, null, 2))
            reject({ error: { message: response.data.header.txtReturn ? response.data.header.txtReturn : response.data.header.txtResultado } });
          }
        } else {
          // console.log('response incorrecto:' + response);
          reject({ error: {} });
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  });

  compraTicket = (Pago) => new Promise((resolve, reject) => {
    const mensaje1A = this.getTrxSicoopcompraTicket(
      Pago.emisorOrigen,
      Pago.cuentaOrigen,
      Pago.ciOriginante ? Pago.ciOriginante : '',
      Pago.titularOriginante ? Pago.titularOriginante : '',
      Pago.codDestinatario,
      Pago.cuentaDestino,
      Pago.ciDestinatario ? Pago.ciDestinatario : '',
      Pago.titularDestinatario ? Pago.titularDestinatario : '',
      Pago.importe,
      Pago.importeCargo ? Pago.importeCargo : '0',
      Pago.tipoCuentaOrigen
    );

    mensaje1A.aditionalParameters = mensaje1A.aditionalParameters.concat(
      Array.isArray(Pago.datosAdicionales) ? Pago.datosAdicionales : []
    );

    /// /console.log("mensaje1A", JSON.stringify(mensaje1A,null,2));
    // axios.post(API_URL + '/v1/transferenciaAutorizador', { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
    axios.post(`${API_URL}/v1/ticket/comprar`, { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
      .then((response) => {
        if (response.data.hasOwnProperty('header')) {
          if (response.data.header.codRespuesta === '0') {
            if (response.data.data.codRespuesta === '0') {
              if (!response.data.hasOwnProperty('data')) reject({ error: { message: 'No se pudo validar la respuesta de la operacion' } });
              // Si tiene la propiedad Data...
              // *** Evaluamos mejor el Response desde el Backend ***
              resolve({ imagen: response.data.data.image });
            } else {
              reject({ error: { message: response.data.data.txtRespuesta } });
            }
          } else {
            reject({
              error: {
                code: response.data.header.codRespuesta,
                message: response.data.header.txtRespuesta
              }
            });
          }
          // console.log("response", JSON.stringify(response, null, 2))

        } else {
          // console.log('response incorrecto:' + response);
          reject({ error: { message: 'No se pudo comprar el ticket. Favor vuelva a intentarlo mas tarde' } });
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  });



  // ***** Transferencias ***** //
  // Dimo a Dimo
  transferenciasTP_TP = (Transferencia, TransferTerceros) => new Promise((resolve, reject) => {
    const mensaje1A = this.getTrxSicoopTransInteTP_TP(
      Transferencia.emisorOrigen,
      Transferencia.cuentaOrigen,
      Transferencia.ciOriginante ? Transferencia.ciOriginante : '',
      Transferencia.titularOriginante ? Transferencia.titularOriginante : '',
      Transferencia.emisorDestinatario,
      Transferencia.cuentaDestino,
      Transferencia.ciDestinatario ? Transferencia.ciDestinatario : '',
      Transferencia.titularDestinatario ? Transferencia.titularDestinatario : '',
      Transferencia.importe,
      Transferencia.importeCargo ? Transferencia.importeCargo : '0',
      Transferencia.idQr
    );

    mensaje1A.aditionalParameters = mensaje1A.aditionalParameters.concat(
      Array.isArray(Transferencia.datosAdicionales) ? Transferencia.datosAdicionales : []
    );

    let URL = '';

    if (TransferTerceros) {
      URL = '/v1/transferenciaNoUsuario';
    } else {
      URL = '/v1/transferencia';
    }

    axios.post(API_URL + URL, { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
      .then((response) => {
        // console.log("reponse", response);
        // console.log("statusHTTP", response.status);
        if (response.data.hasOwnProperty('header')) {
          if (response.data.header.codReturn === '0' || response.data.header.codResultado === '0') {
            if (!response.data.hasOwnProperty('data')) reject({ error: { message: 'No se pudo validar la respuesta de la operacion' } });
            // Si tiene la propiedad Data...
            // *** Evaluamos mejor el Response desde el Backend ***
            const { idOperacion } = response.data.data;
            const estadoResponse = response.data.data.estado;
            const descEstadoResponse = (response.data.data.hasOwnProperty('txtRespuesta')
              && response.data.data.txtRespuesta !== null
              && response.data.data.txtRespuesta.length > 1) ? response.data.data.txtRespuesta : 'Transaccion Rechazada';
            if (estadoResponse === 'AP') // Aprobado
            {
              resolve({
                estado: 'A', descripcion: 'Aprobada', idOperacion, request: mensaje1A, response: response.data.data
              }); // Aprobado Simple
            } else if (estadoResponse === 'SR' || estadoResponse === 'NA') // Rechazado por alguna razon
            {
              resolve({
                estado: 'R', descripcion: descEstadoResponse, idOperacion, request: mensaje1A, response: response.data.data
              });// Rechazo Simple
            } else if (estadoResponse === 'PE') // Pendiente
            {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            } else {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            }
          } else {
            // console.log("response",JSON.stringify(response,null,2))
            reject({ error: { message: response.data.header.txtReturn ? response.data.header.txtReturn : response.data.header.txtResultado } });
          }
        } else {
          // console.log('response incorrecto:' + response);
          reject({ error: {} });
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  });

  // Dimo a BANCO
  transferenciasTP_BANCO = (Transferencia, TransferTerceros) => new Promise((resolve, reject) => {
    const mensaje1A = this.getTrxSicoopTransTP_BANCO(
      Transferencia.emisorOrigen,
      Transferencia.cuentaOrigen,
      Transferencia.ciOriginante ? Transferencia.ciOriginante : '',
      Transferencia.titularOriginante ? Transferencia.titularOriginante : '',
      Transferencia.emisorDestinatario,
      Transferencia.cuentaDestino,
      Transferencia.ciDestinatario ? Transferencia.ciDestinatario : '',
      Transferencia.titularDestinatario ? Transferencia.titularDestinatario : '',
      Transferencia.importe,
      Transferencia.importeCargo ? Transferencia.importeCargo : '0',
    );

    mensaje1A.aditionalParameters = mensaje1A.aditionalParameters.concat(
      Array.isArray(Transferencia.datosAdicionales) ? Transferencia.datosAdicionales : []
    );

    let URL = '';

    if (TransferTerceros) {
      URL = '/v1/transferenciaNoUsuario';
    } else {
      URL = '/v1/transferenciaMdw';
    }
    axios.post(API_URL + URL, { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
      .then((response) => {
        const procesarResponse = (response) => {
          if (response.data.hasOwnProperty('header')) {
            if (response.data.header.codReturn === '0' || response.data.header.codResultado === '0') {
              if (!response.data.hasOwnProperty('data')) reject({ error: { message: 'No se pudo validar la respuesta de la operacion' } });
              // Si tiene la propiedad Data...
              // *** Evaluamos mejor el Response desde el Backend ***
              const { idOperacion } = response.data.data;
              const estadoResponse = response.data.data.estado;
              const descEstadoResponse = (response.data.data.hasOwnProperty('txtRespuesta')
                && response.data.data.txtRespuesta !== null
                && response.data.data.txtRespuesta.length > 1) ? response.data.data.txtRespuesta : 'Transaccion Rechazada';
              if (estadoResponse === 'AP') // Aprobado
              {
                resolve({
                  estado: 'A', descripcion: 'Aprobada', idOperacion, request: mensaje1A, response: response.data.data
                }); // Aprobado Simple
              } else if (estadoResponse === 'SR' || estadoResponse === 'NA') // Rechazado por alguna razon
              {
                resolve({
                  estado: 'R', descripcion: descEstadoResponse, idOperacion, request: mensaje1A, response: response.data.data
                });// Rechazo Simple
              } else if (estadoResponse === 'PE') // Pendiente
              {
                resolve({
                  estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
                }); // Pendiente Simple
              } else {
                resolve({
                  estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
                }); // Pendiente Simple
              }
            } else {
              // console.log("response",JSON.stringify(response,null,2))
              reject({ error: { message: response.data.header.txtReturn ? response.data.header.txtReturn : response.data.header.txtResultado } });
            }
          } else {
            // console.log('response incorrecto:' + response);
            reject({ error: {} });
          }
        };

        if (response.status == 504) {
          const tokenPrm = {
            tokenConfirmacion: getDatoAdicionalValueFrom1A(mensaje1A, keyDatosAdicionales.tokenConfirmacion.key),
          };

          axios.post(`${API_URL}/v1/validarTransaccion`, { header: BODY_HEADER, data: tokenPrm }, { headers: HEADERS() })
            .then((respTokenConfirm) => {
              procesarResponse(respTokenConfirm);
            })
            .catch((error) => {
              // GAException(error);
              reject(error);
            });
        } else {
          procesarResponse(response);
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  });

  // Dimo a COOP
  transferenciasTP_COOP = (Transferencia, TransferTerceros) => new Promise((resolve, reject) => {
    const mensaje1A = this.getTrxSicoopTransTP_CtaAho(
      Transferencia.cuentaOrigen,
      Transferencia.ciOriginante ? Transferencia.ciOriginante : '',
      Transferencia.titularOriginante ? Transferencia.titularOriginante : '',
      Transferencia.emisorDestinatario,
      Transferencia.cuentaDestino,
      Transferencia.ciDestinatario ? Transferencia.ciDestinatario : '',
      Transferencia.titularDestinatario ? Transferencia.titularDestinatario : '',
      Transferencia.importe,
      Transferencia.importeCargo ? Transferencia.importeCargo : '0',
      Transferencia.idQr
    );

    mensaje1A.aditionalParameters = mensaje1A.aditionalParameters.concat(
      Array.isArray(Transferencia.datosAdicionales) ? Transferencia.datosAdicionales : []
    );

    let URL = '';

    if (TransferTerceros) {
      URL = '/v1/transferenciaNoUsuario';
    } else {
      URL = '/v1/transferencia';
    }

    axios.post(API_URL + URL, { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
      .then((response) => {
        if (response.data.hasOwnProperty('header')) {
          if (response.data.header.codReturn === '0' || response.data.header.codResultado === '0') {
            if (!response.data.hasOwnProperty('data')) reject({ error: { message: 'No se pudo validar la respuesta de la operacion' } });
            // Si tiene la propiedad Data...
            // *** Evaluamos mejor el Response desde el Backend ***
            const { idOperacion } = response.data.data;
            const estadoResponse = response.data.data.estado;
            const descEstadoResponse = (response.data.data.hasOwnProperty('txtRespuesta')
              && response.data.data.txtRespuesta !== null
              && response.data.data.txtRespuesta.length > 1) ? response.data.data.txtRespuesta : 'Transaccion Rechazada';
            if (estadoResponse === 'AP') // Aprobado
            {
              resolve({
                estado: 'A', descripcion: 'Aprobada', idOperacion, request: mensaje1A, response: response.data.data
              }); // Aprobado Simple
            } else if (estadoResponse === 'SR' || estadoResponse === 'NA') // Rechazado por alguna razon
            {
              resolve({
                estado: 'R', descripcion: descEstadoResponse, idOperacion, request: mensaje1A, response: response.data.data
              });// Rechazo Simple
            } else if (estadoResponse === 'PE') // Pendiente
            {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            } else {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            }
          } else {
            // console.log("response",JSON.stringify(response,null,2))
            reject({ error: { message: response.data.header.txtReturn ? response.data.header.txtReturn : response.data.header.txtResultado } });
          }
        } else {
          // console.log('response incorrecto:' + response);
          reject({ error: {} });
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  });

  // TP a BOCA COBRANZAS
  pagoTP_BC = (Transferencia, TransferTerceros) => new Promise((resolve, reject) => {
    const mensaje1A = this.getTrxSicoopPagoTP_BC(
      Transferencia.cuentaOrigen,
      Transferencia.ciOriginante ? Transferencia.ciOriginante : '',
      Transferencia.titularOriginante ? Transferencia.titularOriginante : '',
      Transferencia.emisorDestinatario,
      Transferencia.cuentaDestino,
      Transferencia.ciDestinatario ? Transferencia.ciDestinatario : '',
      Transferencia.titularDestinatario ? Transferencia.titularDestinatario : '',
      Transferencia.importe,
      Transferencia.importeCargo ? Transferencia.importeCargo : '0',
    );

    mensaje1A.aditionalParameters = mensaje1A.aditionalParameters.concat(
      Array.isArray(Transferencia.datosAdicionales) ? Transferencia.datosAdicionales : []
    );

    let URL = '';

    if (TransferTerceros) {
      URL = '/v1/transferenciaNoUsuario';
    } else {
      URL = '/v2/pago';
    }

    axios.post(API_URL + URL, { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
      .then((response) => {
        if (response.data.hasOwnProperty('header')) {
          if (response.data.header.codReturn === '0' || response.data.header.codResultado === '0') {
            if (!response.data.hasOwnProperty('data')) reject({ error: { message: 'No se pudo validar la respuesta de la operacion' } });
            // Si tiene la propiedad Data...
            // *** Evaluamos mejor el Response desde el Backend ***
            const { idOperacion } = response.data.data;
            const estadoResponse = response.data.data.estado;
            const descEstadoResponse = (response.data.data.hasOwnProperty('txtRespuesta')
              && response.data.data.txtRespuesta !== null
              && response.data.data.txtRespuesta.length > 1) ? response.data.data.txtRespuesta : 'Transaccion Rechazada';
            if (estadoResponse === 'AP') // Aprobado
            {
              resolve({
                estado: 'A', descripcion: 'Aprobada', idOperacion, request: mensaje1A, response: response.data.data
              }); // Aprobado Simple
            } else if (estadoResponse === 'SR' || estadoResponse === 'NA') // Rechazado por alguna razon
            {
              resolve({
                estado: 'R', descripcion: descEstadoResponse, idOperacion, request: mensaje1A, response: response.data.data
              });// Rechazo Simple
            } else if (estadoResponse === 'PE') // Pendiente
            {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            } else {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            }
          } else {
            // console.log("response",JSON.stringify(response,null,2))
            reject({ error: { message: response.data.header.txtReturn ? response.data.header.txtReturn : response.data.header.txtResultado } });
          }
        } else {
          // console.log('response incorrecto:' + response);
          reject({ error: {} });
        }
      })
      .catch((error) => {
        // GAException(error);
        // console.log('transferenciasTP_BC-error', error)
        reject(error);
      });
  });

  // Tc a Dimo
  transferenciasTC_TP = (Transferencia, TransferTerceros) => new Promise((resolve, reject) => {
    const mensaje1A = this.getTrxSicoopTransTC_TP(
      Transferencia.emisorOrigen,
      Transferencia.cuentaOrigen,
      Transferencia.ciOriginante ? Transferencia.ciOriginante : '',
      Transferencia.titularOriginante ? Transferencia.titularOriginante : '',
      Transferencia.emisorDestinatario,
      Transferencia.cuentaDestino,
      Transferencia.ciDestinatario ? Transferencia.ciDestinatario : '',
      Transferencia.titularDestinatario ? Transferencia.titularDestinatario : '',
      Transferencia.importe,
      Transferencia.importeCargo ? Transferencia.importeCargo : '0',
      Transferencia.idQr
    );

    mensaje1A.aditionalParameters = mensaje1A.aditionalParameters.concat(
      Array.isArray(Transferencia.datosAdicionales) ? Transferencia.datosAdicionales : []
    );

    let URL = '';

    if (TransferTerceros) {
      URL = '/v1/transferenciaNoUsuario';
    } else {
      URL = '/v1/transferencia';
    }

    /// /console.log("mensaje1A", JSON.stringify(mensaje1A,null,2));
    axios.post(API_URL + URL, { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
      .then((response) => {
        if (response.data.hasOwnProperty('header')) {
          if (response.data.header.codReturn === '0' || response.data.header.codResultado === '0') {
            if (!response.data.hasOwnProperty('data')) reject({ error: { message: 'No se pudo validar la respuesta de la operacion' } });
            // Si tiene la propiedad Data...
            // *** Evaluamos mejor el Response desde el Backend ***
            const { idOperacion } = response.data.data;
            const estadoResponse = response.data.data.estado;
            const descEstadoResponse = (response.data.data.hasOwnProperty('txtRespuesta')
              && response.data.data.txtRespuesta !== null
              && response.data.data.txtRespuesta.length > 1) ? response.data.data.txtRespuesta : 'Transaccion Rechazada';
            if (estadoResponse === 'AP') // Aprobado
            {
              resolve({
                estado: 'A', descripcion: 'Aprobada', idOperacion, request: mensaje1A, response: response.data.data
              }); // Aprobado Simple
            } else if (estadoResponse === 'SR' || estadoResponse === 'NA') // Rechazado por alguna razon
            {
              resolve({
                estado: 'R', descripcion: descEstadoResponse, idOperacion, request: mensaje1A, response: response.data.data
              });// Rechazo Simple
            } else if (estadoResponse === 'PE') // Pendiente
            {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            } else {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            }
          } else {
            // console.log("response",JSON.stringify(response,null,2))
            reject({ error: { message: response.data.header.txtReturn ? response.data.header.txtReturn : response.data.header.txtResultado } });
          }
        } else {
          // console.log('response incorrecto:' + response);
          reject({ error: {} });
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  });

  // TC a BANCO
  transferenciasTC_BANCO = (Transferencia, TransferTerceros) => new Promise((resolve, reject) => {
    const mensaje1A = this.getTrxSicoopTransTC_BANCO(
      Transferencia.emisorOrigen,
      Transferencia.cuentaOrigen,
      Transferencia.ciOriginante ? Transferencia.ciOriginante : '',
      Transferencia.titularOriginante ? Transferencia.titularOriginante : '',
      Transferencia.emisorDestinatario,
      Transferencia.cuentaDestino,
      Transferencia.ciDestinatario ? Transferencia.ciDestinatario : '',
      Transferencia.titularDestinatario ? Transferencia.titularDestinatario : '',
      Transferencia.importe,
      Transferencia.importeCargo ? Transferencia.importeCargo : '0',
    );

    mensaje1A.aditionalParameters = mensaje1A.aditionalParameters.concat(
      Array.isArray(Transferencia.datosAdicionales) ? Transferencia.datosAdicionales : []
    );

    let URL = '';

    if (TransferTerceros) {
      URL = '/v1/transferenciaNoUsuario';
    } else {
      URL = '/v1/transferenciaMdw';
    }

    // Si el participante Origen es PANAL
    if (this.isParticipantePanal(mensaje1A.codOriginante)) {
      // Entonces reemplazamos el codigo de Comercio asignado a la BC por el codigo de Comercio PANAL de la BC
      // enviado y que fue obtenido previamente...
      mensaje1A.codEmpresa = Transferencia.comercioPanal ? Transferencia.comercioPanal : mensaje1A.codEmpresa;
    }

    /// /console.log("mensaje1A", JSON.stringify(mensaje1A,null,2));
    axios.post(API_URL + URL, { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
      .then((response) => {
        const procesarResponse = (response) => {
          if (response.data.hasOwnProperty('header')) {
            if (response.data.header.codReturn === '0' || response.data.header.codResultado === '0') {
              if (!response.data.hasOwnProperty('data')) reject({ error: { message: 'No se pudo validar la respuesta de la operacion' } });
              // Si tiene la propiedad Data...
              // *** Evaluamos mejor el Response desde el Backend ***
              const { idOperacion } = response.data.data;
              const estadoResponse = response.data.data.estado;
              const descEstadoResponse = (response.data.data.hasOwnProperty('txtRespuesta')
                && response.data.data.txtRespuesta !== null
                && response.data.data.txtRespuesta.length > 1) ? response.data.data.txtRespuesta : 'Transaccion Rechazada';
              if (estadoResponse === 'AP') // Aprobado
              {
                resolve({
                  estado: 'A', descripcion: 'Aprobada', idOperacion, request: mensaje1A, response: response.data.data
                }); // Aprobado Simple
              } else if (estadoResponse === 'SR' || estadoResponse === 'NA') // Rechazado por alguna razon
              {
                resolve({
                  estado: 'R', descripcion: descEstadoResponse, idOperacion, request: mensaje1A, response: response.data.data
                });// Rechazo Simple
              } else if (estadoResponse === 'PE') // Pendiente
              {
                resolve({
                  estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
                }); // Pendiente Simple
              } else {
                resolve({
                  estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
                }); // Pendiente Simple
              }
            } else {
              // console.log("response",JSON.stringify(response,null,2))
              reject({ error: { message: response.data.header.txtReturn ? response.data.header.txtReturn : response.data.header.txtResultado } });
            }
          } else {
            // console.log('response incorrecto:' + response);
            reject({ error: {} });
          }
        };

        if (response.status == 504) {
          const tokenPrm = {
            tokenConfirmacion: getDatoAdicionalValueFrom1A(mensaje1A, keyDatosAdicionales.tokenConfirmacion.key),
          };

          axios.post(`${API_URL}/v1/validarTransaccion`, { header: BODY_HEADER, data: tokenPrm }, { headers: HEADERS() })
            .then((respTokenConfirm) => {
              procesarResponse(respTokenConfirm);
            })
            .catch((error) => {
              // GAException(error);
              reject(error);
            });
        } else {
          procesarResponse(response);
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  });

  // TC a COOP
  transferenciasTC_COOP = (Transferencia, TransferTerceros) => new Promise((resolve, reject) => {
    const mensaje1A = this.getTrxSicoopTransTC_CtaAho(
      Transferencia.emisorOrigen,
      Transferencia.cuentaOrigen,
      Transferencia.ciOriginante ? Transferencia.ciOriginante : '',
      Transferencia.titularOriginante ? Transferencia.titularOriginante : '',
      Transferencia.emisorDestinatario,
      Transferencia.cuentaDestino,
      Transferencia.ciDestinatario ? Transferencia.ciDestinatario : '',
      Transferencia.titularDestinatario ? Transferencia.titularDestinatario : '',
      Transferencia.importe,
      Transferencia.importeCargo ? Transferencia.importeCargo : '0',
      Transferencia.idQr
    );

    mensaje1A.aditionalParameters = mensaje1A.aditionalParameters.concat(
      Array.isArray(Transferencia.datosAdicionales) ? Transferencia.datosAdicionales : []
    );

    let URL = '';

    if (TransferTerceros) {
      URL = '/v1/transferenciaNoUsuario';
    } else {
      URL = '/v1/transferencia';
    }

    /// /console.log("mensaje1A", JSON.stringify(mensaje1A,null,2));
    axios.post(API_URL + URL, { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
      .then((response) => {
        if (response.data.hasOwnProperty('header')) {
          if (response.data.header.codReturn === '0' || response.data.header.codResultado === '0') {
            if (!response.data.hasOwnProperty('data')) reject({ error: { message: 'No se pudo validar la respuesta de la operacion' } });
            // Si tiene la propiedad Data...
            // *** Evaluamos mejor el Response desde el Backend ***
            const { idOperacion } = response.data.data;
            const estadoResponse = response.data.data.estado;
            const descEstadoResponse = (response.data.data.hasOwnProperty('txtRespuesta')
              && response.data.data.txtRespuesta !== null
              && response.data.data.txtRespuesta.length > 1) ? response.data.data.txtRespuesta : 'Transaccion Rechazada';
            if (estadoResponse === 'AP') // Aprobado
            {
              resolve({
                estado: 'A', descripcion: 'Aprobada', idOperacion, request: mensaje1A, response: response.data.data
              }); // Aprobado Simple
            } else if (estadoResponse === 'SR' || estadoResponse === 'NA') // Rechazado por alguna razon
            {
              resolve({
                estado: 'R', descripcion: descEstadoResponse, idOperacion, request: mensaje1A, response: response.data.data
              });// Rechazo Simple
            } else if (estadoResponse === 'PE') // Pendiente
            {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            } else {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            }
          } else {
            // console.log("response",JSON.stringify(response,null,2))
            reject({ error: { message: response.data.header.txtReturn ? response.data.header.txtReturn : response.data.header.txtResultado } });
          }
        } else {
          // console.log('response incorrecto:' + response);
          reject({ error: {} });
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  });

  // TC a BOCA COBRANZAS
  pagoTC_BC = (Transferencia, TransferTerceros) => new Promise((resolve, reject) => {
    const mensaje1A = this.getTrxSicoopTransTC_BC(
      Transferencia.emisorOrigen,
      Transferencia.cuentaOrigen,
      Transferencia.ciOriginante ? Transferencia.ciOriginante : '',
      Transferencia.titularOriginante ? Transferencia.titularOriginante : '',
      Transferencia.emisorDestinatario,
      Transferencia.cuentaDestino,
      Transferencia.ciDestinatario ? Transferencia.ciDestinatario : '',
      Transferencia.titularDestinatario ? Transferencia.titularDestinatario : '',
      Transferencia.importe,
      Transferencia.importeCargo ? Transferencia.importeCargo : '0',
    );

    mensaje1A.aditionalParameters = mensaje1A.aditionalParameters.concat(
      Array.isArray(Transferencia.datosAdicionales) ? Transferencia.datosAdicionales : []
    );

    let URL = '';

    if (TransferTerceros) {
      URL = '/v1/transferenciaNoUsuario';
    } else {
      URL = '/v2/pago';
    }

    // Si el participante Origen es PANAL
    if (this.isParticipantePanal(mensaje1A.codOriginante)) {
      // Entonces reemplazamos el codigo de Comercio asignado a la BC por el codigo de Comercio PANAL de la BC
      // enviado y que fue obtenido previamente...
      mensaje1A.codEmpresa = Transferencia.comercioPanal ? Transferencia.comercioPanal : mensaje1A.codEmpresa;
    }

    axios.post(API_URL + URL, { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
      .then((response) => {
        if (response.data.hasOwnProperty('header')) {
          if (response.data.header.codReturn === '0' || response.data.header.codResultado === '0') {
            if (!response.data.hasOwnProperty('data')) reject({ error: { message: 'No se pudo validar la respuesta de la operacion' } });
            // Si tiene la propiedad Data...
            // *** Evaluamos mejor el Response desde el Backend ***
            const { idOperacion } = response.data.data;
            const estadoResponse = response.data.data.estado;
            const descEstadoResponse = (response.data.data.hasOwnProperty('txtRespuesta')
              && response.data.data.txtRespuesta !== null
              && response.data.data.txtRespuesta.length > 1) ? response.data.data.txtRespuesta : 'Transaccion Rechazada';
            if (estadoResponse === 'AP') // Aprobado
            {
              resolve({
                estado: 'A', descripcion: 'Aprobada', idOperacion, request: mensaje1A, response: response.data.data
              }); // Aprobado Simple
            } else if (estadoResponse === 'SR' || estadoResponse === 'NA') // Rechazado por alguna razon
            {
              resolve({
                estado: 'R', descripcion: descEstadoResponse, idOperacion, request: mensaje1A, response: response.data.data
              });// Rechazo Simple
            } else if (estadoResponse === 'PE') // Pendiente
            {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            } else {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            }
          } else {
            // console.log("response",JSON.stringify(response,null,2))
            reject({ error: { message: response.data.header.txtReturn ? response.data.header.txtReturn : response.data.header.txtResultado } });
          }
        } else {
          // console.log('response incorrecto:' + response);
          reject({ error: {} });
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  });

  // COOP a TP
  transferenciasCOOP_TP = (Transferencia, TransferTerceros) => new Promise((resolve, reject) => {
    const mensaje1A = this.getTrxSicoopTransCtaAho_TP(
      Transferencia.emisorOrigen,
      Transferencia.cuentaOrigen,
      Transferencia.ciOriginante ? Transferencia.ciOriginante : '',
      Transferencia.titularOriginante ? Transferencia.titularOriginante : '',
      // Transferencia.emisorDestinatario,
      Transferencia.cuentaDestino,
      Transferencia.ciDestinatario ? Transferencia.ciDestinatario : '',
      Transferencia.titularDestinatario ? Transferencia.titularDestinatario : '',
      Transferencia.importe ? Transferencia.importe : '0',
      Transferencia.importeCargo ? Transferencia.importeCargo : '0',
      Transferencia.idQr
    );

    // console.log("Transferencia", JSON.stringify(Transferencia,null, 2));
    // console.log("mensaje1A", JSON.stringify(mensaje1A,null, 2));

    mensaje1A.aditionalParameters = mensaje1A.aditionalParameters.concat(
      Array.isArray(Transferencia.datosAdicionales) ? Transferencia.datosAdicionales : []
    );

    let URL = '';

    if (TransferTerceros) {
      URL = '/v1/transferenciaNoUsuario';
    } else {
      URL = '/v1/transferencia';
    }

    axios.post(API_URL + URL, { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
      .then((response) => {
        if (response.data.hasOwnProperty('header')) {
          if (response.data.header.codReturn === '0' || response.data.header.codResultado === '0') {
            if (!response.data.hasOwnProperty('data')) reject({ error: { message: 'No se pudo validar la respuesta de la operacion' } });
            // Si tiene la propiedad Data...
            // *** Evaluamos mejor el Response desde el Backend ***
            const { idOperacion } = response.data.data;
            const estadoResponse = response.data.data.estado;
            const descEstadoResponse = (response.data.data.hasOwnProperty('txtRespuesta')
              && response.data.data.txtRespuesta !== null
              && response.data.data.txtRespuesta.length > 1) ? response.data.data.txtRespuesta : 'Transaccion Rechazada';
            if (estadoResponse === 'AP') // Aprobado
            {
              resolve({
                estado: 'A', descripcion: 'Aprobada', idOperacion, request: mensaje1A, response: response.data.data
              }); // Aprobado Simple
            } else if (estadoResponse === 'SR' || estadoResponse === 'NA') // Rechazado por alguna razon
            {
              resolve({
                estado: 'R', descripcion: descEstadoResponse, idOperacion, request: mensaje1A, response: response.data.data
              });// Rechazo Simple
            } else if (estadoResponse === 'PE') // Pendiente
            {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            } else {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            }
          } else {
            // console.log("response",JSON.stringify(response,null,2))
            reject({ error: { message: response.data.header.txtReturn ? response.data.header.txtReturn : response.data.header.txtResultado } });
          }
        } else {
          // console.log('response incorrecto:' + response);
          reject({ error: {} });
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  });

  // COOP a COOP
  transferenciasCOOP_COOP = (Transferencia, TransferTerceros) => new Promise((resolve, reject) => {
    const { moneda } = Transferencia;

    const mensaje1A = this.getTrxSicoopTransCtaAho_CtaAho(
      Transferencia.codParticipanteOrigen,
      Transferencia.cuentaOrigen,
      Transferencia.ciOriginante ? Transferencia.ciOriginante : '',
      Transferencia.titularOriginante ? Transferencia.titularOriginante : '',
      Transferencia.codParticipanteDestino,
      Transferencia.cuentaDestino,
      Transferencia.ciDestinatario ? Transferencia.ciDestinatario : '',
      Transferencia.titularDestinatario ? Transferencia.titularDestinatario : '',
      Transferencia.importe,
      Transferencia.importeCargo ? Transferencia.importeCargo : '0',
      Transferencia.idQr
    );

    Transferencia.moneda = moneda;

    mensaje1A.aditionalParameters = mensaje1A.aditionalParameters.concat(
      Array.isArray(Transferencia.datosAdicionales) ? Transferencia.datosAdicionales : []
    );

    let URL = '';

    if (TransferTerceros) {
      URL = '/v1/transferenciaNoUsuario';
    } else {
      URL = '/v1/transferencia';
    }

    axios.post(API_URL + URL, { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
      .then((response) => {
        if (response.data.hasOwnProperty('header')) {
          if (response.data.header.codReturn === '0' || response.data.header.codResultado === '0') {
            if (!response.data.hasOwnProperty('data')) reject({ error: { message: 'No se pudo validar la respuesta de la operacion' } });
            // Si tiene la propiedad Data...
            // *** Evaluamos mejor el Response desde el Backend ***
            const { idOperacion } = response.data.data;
            const estadoResponse = response.data.data.estado;
            const descEstadoResponse = (response.data.data.hasOwnProperty('txtRespuesta')
              && response.data.data.txtRespuesta !== null
              && response.data.data.txtRespuesta.length > 1) ? response.data.data.txtRespuesta : 'Transaccion Rechazada';
            if (estadoResponse === 'AP') // Aprobado
            {
              resolve({
                estado: 'A', descripcion: 'Aprobada', idOperacion, request: mensaje1A, response: response.data.data
              }); // Aprobado Simple
            } else if (estadoResponse === 'SR' || estadoResponse === 'NA') // Rechazado por alguna razon
            {
              resolve({
                estado: 'R', descripcion: descEstadoResponse, idOperacion, request: mensaje1A, response: response.data.data
              });// Rechazo Simple
            } else if (estadoResponse === 'PE') // Pendiente
            {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            } else {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            }
          } else {
            // console.log("response",JSON.stringify(response,null,2))
            reject({ error: { message: response.data.header.txtReturn ? response.data.header.txtReturn : response.data.header.txtResultado } });
          }
        } else {
          // console.log('response incorrecto:' + response);
          reject({ error: {} });
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  });

  // COOP a BANCO
  transferenciasCOOP_BANCO = (Transferencia, TransferTerceros) => new Promise((resolve, reject) => {
    const { moneda } = Transferencia;

    const mensaje1A = this.getTrxSicoopTransCtaAho_BANCO(
      Transferencia.emisorOrigen,
      Transferencia.cuentaOrigen,
      Transferencia.ciOriginante ? Transferencia.ciOriginante : '',
      Transferencia.titularOriginante ? Transferencia.titularOriginante : '',
      Transferencia.cuentaDestino,
      Transferencia.ciDestinatario ? Transferencia.ciDestinatario : '',
      Transferencia.titularDestinatario ? Transferencia.titularDestinatario : '',
      Transferencia.importe,
      Transferencia.importeCargo ? Transferencia.importeCargo : '0',
    );

    Transferencia.moneda = moneda;

    mensaje1A.aditionalParameters = mensaje1A.aditionalParameters.concat(
      Array.isArray(Transferencia.datosAdicionales) ? Transferencia.datosAdicionales : []
    );

    // console.log('mensaje1A', mensaje1A);

    let URL = '';

    if (TransferTerceros) {
      URL = '/v1/transferenciaNoUsuario';
    } else {
      URL = '/v1/transferenciaMdw';
    }

    axios.post(API_URL + URL, { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
      .then((response) => {
        const procesarResponse = (response) => {
          if (response.data.hasOwnProperty('header')) {
            if (response.data.header.codReturn === '0' || response.data.header.codResultado === '0') {
              if (!response.data.hasOwnProperty('data')) reject({ error: { message: 'No se pudo validar la respuesta de la operacion' } });
              // Si tiene la propiedad Data...
              // *** Evaluamos mejor el Response desde el Backend ***
              const { idOperacion } = response.data.data;
              const estadoResponse = response.data.data.estado;
              const descEstadoResponse = (response.data.data.hasOwnProperty('txtRespuesta')
                && response.data.data.txtRespuesta !== null
                && response.data.data.txtRespuesta.length > 1) ? response.data.data.txtRespuesta : 'Transaccion Rechazada';
              if (estadoResponse === 'AP') // Aprobado
              {
                resolve({
                  estado: 'A', descripcion: 'Aprobada', idOperacion, request: mensaje1A, response: response.data.data
                }); // Aprobado Simple
              } else if (estadoResponse === 'SR' || estadoResponse === 'NA') // Rechazado por alguna razon
              {
                resolve({
                  estado: 'R', descripcion: descEstadoResponse, idOperacion, request: mensaje1A, response: response.data.data
                });// Rechazo Simple
              } else if (estadoResponse === 'PE') // Pendiente
              {
                resolve({
                  estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
                }); // Pendiente Simple
              } else {
                resolve({
                  estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
                }); // Pendiente Simple
              }
            } else {
              // console.log("response",JSON.stringify(response,null,2))
              reject({ error: { message: response.data.header.txtReturn ? response.data.header.txtReturn : response.data.header.txtResultado } });
            }
          } else {
            // console.log('response incorrecto:' + response);
            reject({ error: {} });
          }
        };

        if (response.status == 504) {
          const tokenPrm = {
            tokenConfirmacion: getDatoAdicionalValueFrom1A(mensaje1A, keyDatosAdicionales.tokenConfirmacion.key),
          };

          axios.post(`${API_URL}/v1/validarTransaccion`, { header: BODY_HEADER, data: tokenPrm }, { headers: HEADERS() })
            .then((respTokenConfirm) => {
              procesarResponse(respTokenConfirm);
            })
            .catch((error) => {
              // GAException(error);
              reject(error);
            });
        } else {
          procesarResponse(response);
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  });

  // COOP a BOCA COBRANZAS
  pagoCOOP_BC = (Transferencia, TransferTerceros) => new Promise((resolve, reject) => {
    const mensaje1A = this.getTrxSicoopTransCOOP_BC(
      Transferencia.emisorOrigen,
      Transferencia.cuentaOrigen,
      Transferencia.ciOriginante ? Transferencia.ciOriginante : '',
      Transferencia.titularOriginante ? Transferencia.titularOriginante : '',
      Transferencia.emisorDestinatario,
      Transferencia.cuentaDestino,
      Transferencia.ciDestinatario ? Transferencia.ciDestinatario : '',
      Transferencia.titularDestinatario ? Transferencia.titularDestinatario : '',
      Transferencia.importe,
      Transferencia.importeCargo ? Transferencia.importeCargo : '0',
    );

    mensaje1A.aditionalParameters = mensaje1A.aditionalParameters.concat(
      Array.isArray(Transferencia.datosAdicionales) ? Transferencia.datosAdicionales : []
    );

    let URL = '';

    // TODO falta URL
    if (TransferTerceros) {
      URL = '';
    } else {
      URL = '/v1/pago';
    }

    axios.post(API_URL + URL, { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
      .then((response) => {
        if (response.data.hasOwnProperty('header')) {
          if (response.data.header.codReturn === '0' || response.data.header.codResultado === '0') {
            if (!response.data.hasOwnProperty('data')) reject({ error: { message: 'No se pudo validar la respuesta de la operacion' } });
            // Si tiene la propiedad Data...
            // *** Evaluamos mejor el Response desde el Backend ***
            const { idOperacion } = response.data.data;
            const estadoResponse = response.data.data.estado;
            const descEstadoResponse = (response.data.data.hasOwnProperty('txtRespuesta')
              && response.data.data.txtRespuesta !== null
              && response.data.data.txtRespuesta.length > 1) ? response.data.data.txtRespuesta : 'Transaccion Rechazada';
            if (estadoResponse === 'AP') // Aprobado
            {
              resolve({
                estado: 'A', descripcion: 'Aprobada', idOperacion, request: mensaje1A, response: response.data.data
              }); // Aprobado Simple
            } else if (estadoResponse === 'SR' || estadoResponse === 'NA') // Rechazado por alguna razon
            {
              resolve({
                estado: 'R', descripcion: descEstadoResponse, idOperacion, request: mensaje1A, response: response.data.data
              });// Rechazo Simple
            } else if (estadoResponse === 'PE') // Pendiente
            {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            } else {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            }
          } else {
            // console.log("response",JSON.stringify(response,null,2))
            reject({ error: { message: response.data.header.txtReturn ? response.data.header.txtReturn : response.data.header.txtResultado } });
          }
        } else {
          // console.log('response incorrecto:' + response);
          reject({ error: {} });
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  });

  // COMPRA TICKET



  // ***** Compras ***** //
  comprasTP_ComercioQR = (compra) => new Promise((resolve, reject) => {
    const mensaje1A = this.getTrxSicoopCompraTP(
      compra.emisorOrigen,
      compra.cuentaOrigen,
      compra.ciOriginante ? compra.ciOriginante : '',
      compra.titularOriginante ? compra.titularOriginante : '',
      compra.comercio,
      compra.titularDestinatario ? compra.titularDestinatario : '', // Nombre Comercial
      compra.ciDestinatario ? compra.ciDestinatario : '', // Ci Usuario Comercio
      compra.importe,
      compra.codOperacion ? compra.codOperacion : null,
      compra.idOperacionCaja,
      compra.codDestinatario,
      // Compra.importeCargo ? Compra.importeCargo : "0",
    );

    // Agregamos al 1A el campo "idQR" para este tipo de Operaciones
    mensaje1A.idQr = compra.idQr;
    mensaje1A.tipoQr = compra.tipoQr;
    mensaje1A.origenQr = compra.origenQr;

    mensaje1A.aditionalParameters = mensaje1A.aditionalParameters.concat(
      Array.isArray(compra.datosAdicionales) ? compra.datosAdicionales : []
    );

    /// /console.log("mensaje1A", JSON.stringify(mensaje1A,null,2));
    axios.post(`${API_URL}/v1/CompraQr`, { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
      .then((response) => {
        if (response.data.hasOwnProperty('header')) {
          if (response.data.header.codReturn === '0' || response.data.header.codResultado === '0') {
            if (!response.data.hasOwnProperty('data')) reject({ error: { message: 'No se pudo validar la respuesta de la operacion' } });
            // Si tiene la propiedad Data...
            // *** Evaluamos mejor el Response desde el Backend ***
            const { idOperacion } = response.data.data;
            const estadoResponse = response.data.data.estado;
            const descEstadoResponse = (response.data.data.hasOwnProperty('txtRespuesta')
              && response.data.data.txtRespuesta !== null
              && response.data.data.txtRespuesta.length > 1) ? response.data.data.txtRespuesta : 'Transaccion Rechazada';
            if (estadoResponse === 'AP') // Aprobado
            {
              resolve({
                estado: 'A', descripcion: 'Aprobada', idOperacion, request: mensaje1A, response: response.data.data
              }); // Aprobado Simple
            } else if (estadoResponse === 'SR' || estadoResponse === 'NA') // Rechazado por alguna razon
            {
              resolve({
                estado: 'R', descripcion: descEstadoResponse, idOperacion, request: mensaje1A, response: response.data.data
              });// Rechazo Simple
            } else if (estadoResponse === 'PE') // Pendiente
            {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            } else {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            }
          } else {
            // console.log("response", JSON.stringify(response, null, 2))
            reject({ error: { message: response.data.header.txtReturn ? (response.data.data && response.data.data.txtRespuesta) ? response.data.data.txtRespuesta : response.data.header.txtReturn : response.data.header.txtResultado } });
          }
        } else {
          // console.log('response incorrecto:' + response);
          reject({ error: {} });
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  });

  comprasTC_ComercioQR = (compra) => new Promise((resolve, reject) => {
    const mensaje1A = this.getTrxSicoopCompraTC(
      compra.emisorOrigen,
      compra.cuentaOrigen,
      compra.ciOriginante ? compra.ciOriginante : '',
      compra.titularOriginante ? compra.titularOriginante : '',
      compra.comercio,
      compra.titularDestinatario ? compra.titularDestinatario : '', // Nombre Comercial
      compra.ciDestinatario ? compra.ciDestinatario : '', // Ci Usuario Comercio
      compra.importe,
      compra.cuotas,
      compra.codOperacion ? compra.codOperacion : null,
      compra.idOperacionCaja,
      compra.codDestinatario,
      // compra.importeCargo ? compra.importeCargo : "0",
    );

    // Agregamos al 1A el campo "idQR" para este tipo de Operaciones
    mensaje1A.idQr = compra.idQr;
    mensaje1A.origenQr = compra.origenQr;
    mensaje1A.tipoQr = compra.tipoQr;

    mensaje1A.aditionalParameters = mensaje1A.aditionalParameters.concat(
      Array.isArray(compra.datosAdicionales) ? compra.datosAdicionales : []
    );

    // Si el participante Origen es PANAL
    // if (this.isParticipantePanal(mensaje1A.codOriginante)) {
    //   // Entonces reemplazamos el codigo de Comercio asignado por el codigo de Comercio PANAL
    //   // enviado y que fue obtenido previamente...
    //   mensaje1A.numCuentaDestino = compra.comercioPanal ? compra.comercioPanal : mensaje1A.numCuentaDestino;
    // }

    /// /console.log("mensaje1A", JSON.stringify(mensaje1A,null,2));
    axios.post(`${API_URL}/v1/CompraQr`, { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
      .then((response) => {
        if (response.data.hasOwnProperty('header')) {
          if (response.data.header.codReturn === '0' || response.data.header.codResultado === '0') {
            if (!response.data.hasOwnProperty('data')) reject({ error: { message: 'No se pudo validar la respuesta de la operacion' } });
            // Si tiene la propiedad Data...
            // *** Evaluamos mejor el Response desde el Backend ***
            const { idOperacion } = response.data.data;
            const estadoResponse = response.data.data.estado;
            const descEstadoResponse = (response.data.data.hasOwnProperty('txtRespuesta')
              && response.data.data.txtRespuesta !== null
              && response.data.data.txtRespuesta.length > 1) ? response.data.data.txtRespuesta : 'Transaccion Rechazada';
            if (estadoResponse === 'AP') // Aprobado
            {
              resolve({
                estado: 'A', descripcion: 'Aprobada', idOperacion, request: mensaje1A, response: response.data.data
              }); // Aprobado Simple
            } else if (estadoResponse === 'SR' || estadoResponse === 'NA') // Rechazado por alguna razon
            {
              resolve({
                estado: 'R', descripcion: descEstadoResponse, idOperacion, request: mensaje1A, response: response.data.data
              });// Rechazo Simple
            } else if (estadoResponse === 'PE') // Pendiente
            {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            } else {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            }
          } else {
            // console.log("response", JSON.stringify(response, null, 2))
            reject({ error: { message: response.data.header.txtReturn ? response.data.header.txtReturn : response.data.header.txtResultado } });
          }
        } else {
          // console.log('response incorrecto:' + response);
          reject({ error: {} });
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  });

  comprasCCAHO_ComercioQR = (compra) => new Promise((resolve, reject) => {
    const mensaje1A = this.getTrxSicoopCompraCtaAho(
      compra.codParticipanteOrigen,
      compra.cuentaOrigen,
      compra.ciOriginante ? compra.ciOriginante : '',
      compra.titularOriginante ? compra.titularOriginante : '',
      compra.comercio,
      compra.titularDestinatario ? compra.titularDestinatario : '', // Nombre Comercial
      compra.ciDestinatario ? compra.ciDestinatario : '', // Ci Usuario Comercio
      compra.importe,
      compra.codOperacion ? compra.codOperacion : null,
      compra.idOperacionCaja,
      compra.codDestinatario,
      // compra.importeCargo ? compra.importeCargo : "0",
    );

    // Agregamos al 1A el campo "idQr" para este tipo de Operaciones
    mensaje1A.idQr = compra.idQr;
    mensaje1A.tipoQr = compra.tipoQr;
    mensaje1A.origenQr = compra.origenQr;

    mensaje1A.aditionalParameters = mensaje1A.aditionalParameters.concat(
      Array.isArray(compra.datosAdicionales) ? compra.datosAdicionales : []
    );

    /// /console.log("mensaje1A", JSON.stringify(mensaje1A,null,2));
    axios.post(`${API_URL}/v1/CompraQr`, { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
      .then((response) => {
        if (response.data.hasOwnProperty('header')) {
          if (response.data.header.codReturn === '0' || response.data.header.codResultado === '0') {
            if (!response.data.hasOwnProperty('data')) reject({ error: { message: 'No se pudo validar la respuesta de la operacion' } });
            // Si tiene la propiedad Data...
            // *** Evaluamos mejor el Response desde el Backend ***
            const { idOperacion } = response.data.data;
            const estadoResponse = response.data.data.estado;
            const descEstadoResponse = (response.data.data.hasOwnProperty('txtRespuesta')
              && response.data.data.txtRespuesta !== null
              && response.data.data.txtRespuesta.length > 1) ? response.data.data.txtRespuesta : 'Transaccion Rechazada';
            if (estadoResponse === 'AP') // Aprobado
            {
              resolve({
                estado: 'A', descripcion: 'Aprobada', idOperacion, request: mensaje1A, response: response.data.data
              }); // Aprobado Simple
            } else if (estadoResponse === 'SR' || estadoResponse === 'NA') // Rechazado por alguna razon
            {
              resolve({
                estado: 'R', descripcion: descEstadoResponse, idOperacion, request: mensaje1A, response: response.data.data
              });// Rechazo Simple
            } else if (estadoResponse === 'PE') // Pendiente
            {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            } else {
              resolve({
                estado: 'P', idOperacion, request: mensaje1A, response: response.data.data
              }); // Pendiente Simple
            }
          } else {
            // console.log("response", JSON.stringify(response, null, 2))
            reject({ error: { message: response.data.header.txtReturn ? response.data.header.txtReturn : response.data.header.txtResultado } });
          }
        } else {
          // console.log('response incorrecto:' + response);
          reject({ error: {} });
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  });


  // ***** Validacion para Extraccion de Efectivo por ATM *****

  validarExtraccionAtm = (param) => new Promise((resolve, reject) => {
    axios.post(`${API_URL}/v1/qr/atm/validar`, { header: BODY_HEADER, data: param }, { headers: HEADERS() })
      .then((response) => {
        if (response.data.hasOwnProperty('header')) {
          if (response.data.header.codRespuesta === '0') {
            resolve(response.data.header);
          } else {
            reject({ error: { message: response.data.header.txtRespuesta } });
          }
        }
      })
      .catch((error) => {
        reject(error);
      })
  })

  // ***** Consulta de Estado de la Tx *****
  // Consulta el estado de una Tx Enviada
  consultaEstadoTrx = (dataPrm) => new Promise((resolve, reject) => {
    const dataPost = {
      idOperacion: dataPrm.idOperacion,
    };

    /// /console.log('dataPostEstado',JSON.stringify(dataPost,null,2));

    axios.post(`${API_URL}/v1/confirmacionOperacionDimo`, { header: BODY_HEADER, data: dataPost }, { headers: HEADERS() })
      .then((response) => {
        if (response.data.hasOwnProperty('header') && response.data.hasOwnProperty('data') && response.data.data.hasOwnProperty('estado')) {
          if (response.data.header.codReturn === '0') {
            // resolve(response.data.data);
            // *** Evaluamos mejor el Response desde el Backend ***
            const estadoResponse = response.data.data.estado;
            const descEstadoResponse = (response.data.data.hasOwnProperty('txtRespuesta')
              && response.data.data.txtRespuesta !== null
              && response.data.data.txtRespuesta.length > 1) ? response.data.data.txtRespuesta : 'Transaccion Rechazada';
            if (estadoResponse === 'AP') // Aprobado
            {
              resolve({ estado: 'A', descripcion: 'Aprobada' }); // Aprobado Simple
            } else if (estadoResponse === 'SR' || estadoResponse === 'NA') // Rechazado por alguna razon
            {
              resolve({ estado: 'R', descripcion: descEstadoResponse });// Rechazo Simple
            } else if (estadoResponse === 'PE') // Pendiente
            {
              resolve({ estado: 'P' }); // Pendiente Simple
            } else if (estadoResponse === 'TN') // Solicitud de transferencia a NO USUARIO DIM
            {
              resolve({ estado: 'A' }); // Solicitud de transferencia a NO USUARIO DIM
            } else {
              // console.log("No se pudo evaluar correctamente el estado de la Transaccion. Response ", JSON.stringify(response))
              reject();
            }
          } else {
            reject({ error: { message: response.data.header.txtReturn } });
          }
        } else {
          // console.log('response incorrecto:' + response);
          reject();
        }
      })
      .catch((error) => {
        // GAException(error);
        // resolve({"estado":"A", "descripcion":""});
        // resolve({"estado":"P", "descripcion":""});
        // resolve({"estado":"R", "descripcion":"Cuenta Sin Saldo"});
        // reject({ error: { message: "Cuenta Sin Saldo"} });
        reject(error);
      });
  });

  // ***** Acumulacion cargos de Estado de la Tx *****
  // Si la Trx fue aprobada llama al servicio de cargos acumular cargos
  acumularTrxCargos = (dataPrm) => new Promise((resolve, reject) => {
  //   {
  //     PI_ID_CARGO: 39,
  //     PI_CUENTA: "123456789",
  //     pi_fecha_operacion: "24/11/2023",
  //     pi_signo_operacion: ""
  // }
// costoId: "5"
// ​

    const fecha = new Date();
    const CargoTrxData = JSON.parse(localStorage.getItem("CargoTrxData"))
    const dataPost = {
        PI_ID_CARGO: CargoTrxData.PI_ID_CARGO,
        PI_CUENTA: CargoTrxData.PI_CUENTA,
        pi_fecha_operacion: `${fecha.getDate()}/${fecha.getMonth() + 1}/${fecha.getFullYear()}`,
        pi_signo_operacion: ""
    };
    axios.post(`${API_URL_CEIBO_PRM_SP}/ceiboConfirmarCargo/v1/acumularTrxCargos`, dataPost, { headers: HEADERS() })
    .then((response) => {
        if (response.data.hasOwnProperty('header') && response.data.hasOwnProperty('data')) {
          if (response.data.header.codReturn === '0') {
            resolve(response.data.data);
          } else {
            reject({ error: { message: response.data.header.txtReturn } });
          }
        } else {
          // console.log('response incorrecto:' + response);
          reject();
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  })

  // ** otros **...
  cabalBenefitsTransaction = (cabalBenefits) => new Promise((resolve, reject) => {
    const mensaje1A = {
      header: {
        appVersion: '1.0.2',
        token: 'prueba',
        user: 'csoto'
      },
      data: {
        codDestinatario: 1600,
        moneda: 'GUA',
        monto: cabalBenefits.IMPORTE,
        tipoTransaccion: 'TRAN',
        tipoMovimiento: 'ACREDITAR',
        tipoCuentaOrigen: 'TPO',
        numCuentaOrigen: 6043512000000141, // TODO cambiar por tarjeta a la que se aplicara el debito
        tipoCuentaDestino: 'TPD',
        numCuentaDestino: cabalBenefits.NUMERO_TARJETA,
        glosa: 'Acreditacion de Beneficios a funcionarios de CABAL',
        ciOriginante: '0',
        titularOriginante: 'ENTIDAD 120',
        ciDestinatario: cabalBenefits.ciDestinatario,
        titularDestinatario: cabalBenefits.titularDestinatario,
        origenFondos: '',
        codEmpresa: 1,
        codServicio: 'TRANINTE',
        codCajaCajero: 0,
        montoCargo: 0
      }
    };

    axios.post(
      `${API_URL_OPS}/v1/transferenciaAutorizador`,
      mensaje1A,
      CONFIG
    )
      .then((response) => {
        if (response.data.header.codReturn === '0') {
          resolve(response.data.data);
        } else {
          reject({ error: { message: response.data.header.txtReturn } });
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  });

  // Obtiene las ultimas transacciones del usuario
  getLastTransactionList = (lastTransaction) => new Promise((resolve, reject) => {
    const dataPost = {
      ciOriginante: lastTransaction.ciOriginante,
      cuentaOrigen: '0',
      ciDestinatario: '0',
      cuentaDestino: '0',
      estado: '0',
      codigoServicio: '0',
      codigoUuid: '0',
      mes: lastTransaction.mes,
      anio: lastTransaction.anio
    };

    axios.post(
      `${API_URL}/v1/ultimasOperaciones`,
      {
        header: BODY_HEADER,
        data: dataPost
      },
      {
        headers: HEADERS()
      }
    )
      .then((response) => {
        if (response.data.header) {
          if (response.data.header.codReturn === '0') {
            resolve(response.data.data);
          } else {
            reject({ error: { message: response.data.header.txtReturn } });
          }
        } else {
          // console.log('response incorrecto:' + response);

          reject();
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  });

  // Reversa una tranferencia a una NO DIMO
  reversarTransferenciaNoDimo = (reverso) => new Promise((resolve, reject) => {
    const dataPost = {
      idOperacion: reverso.idOperacion,
      documentoUsuario: reverso.documentoUsuario,
    };

    axios.post(
      `${API_URL}/v1/descartarTransferenciaNoDimo`,
      {
        header: BODY_HEADER,
        data: dataPost
      },
      {
        headers: HEADERS()
      }
    )
      .then((response) => {
        if (response.data.header) {
          if (response.data.header.codReturn === '0') {
            resolve(response.data.data);
          } else {
            reject({ error: { message: response.data.header.txtReturn } });
          }
        } else {
          // console.log('response incorrecto:' + response);

          reject();
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  });

  // Obtiene los datos de una operacion por su ID
  getTransactionById = (idOperacion) => new Promise((resolve, reject) => {
    const dataPost = {
      idOperacion,
      codigoUuid: null,
    };

    axios.post(
      `${API_URL}/v1/obtenerOperacion`,
      {
        header: BODY_HEADER,
        data: dataPost
      },
      {
        headers: HEADERS()
      }
    )
      .then((response) => {
        if (response.data.header) {
          if (response.data.header.codReturn === '0') {
            resolve(response.data.data);
          } else {
            reject({ error: { message: response.data.header.txtReturn } });
          }
        } else {
          // console.log('response incorrecto:' + response);

          reject();
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  });

  // Obtiene los beneficiarios por documento del Usuario
  getBeneficiariosTrxByDocOrigen = (lastTransaction) => new Promise((resolve, reject) => {
    const dataPost = {
      documento: `${lastTransaction.documento}`,
    };

    axios.post(
      `${API_URL}/v1/BeneficiariosPorCliente`,
      {
        header: BODY_HEADER,
        data: dataPost
      },
      {
        headers: HEADERS()
      }
    )
      .then((response) => {
        if (response.data.header) {
          if (response.data.header.codReturn === '0') {
            resolve(response.data.data);
          } else {
            reject({ error: { message: response.data.header.txtReturn } });
          }
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

  // Obtiene los beneficiarios por documento del Usuario
  obtenerMotivosTransferencia = () => new Promise((resolve, reject) => {
    axios.get(
      `${API_URL}/v1/obtenerMotivos`,
      {},
      {
        headers: HEADERS()
      }
    )
      .then((response) => {
        if (response.data.header) {
          if (response.data.header.codReturn === '0') {
            resolve(response.data.data);
          } else {
            reject({ error: { message: response.data.header.txtReturn } });
          }
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

  // Eliminar Beneficiarios de una transaccion
  eliminarBeneficiarios = (data) => new Promise((resolve, reject) => {
    const dataPrm = {
      beneficiarioId: data.hasOwnProperty('beneficiarioId') ? data.beneficiarioId : '',
    };

    axios.post(
      `${API_URL}/v1/ocultarBeneficiario`,
      { data: dataPrm },
      {
        headers: HEADERS()
      }
    )
      .then((response) => {
        if (response.data.header) {
          if (response.data.header.codReturn === '0') {
            resolve(response.data.data);
          } else {
            reject({ error: { message: response.data.header.txtReturn } });
          }
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

  ocultarTransaccion = (data) => new Promise((resolve, reject) => {
    const dataPrm = {
      idOperacion: data.hasOwnProperty('idOperacion') ? data.idOperacion : '',
    };

    axios.post(
      `${API_URL}/v1/ocultarTransaccion`,
      { data: dataPrm },
      {
        headers: HEADERS()
      }
    )
      .then((response) => {
        if (response.data.header) {
          if (response.data.header.codReturn === '0') {
            resolve(response.data.data);
          } else {
            reject({ error: { message: response.data.header.txtReturn } });
          }
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

  // Obtiene los datos de la Relacion de los Participantes y los Tipos de Cuentas
  obtenerParticipantesTiposCuentas = () => new Promise((resolve, reject) => {
    axios.post(
      `${API_URL_DIMO_SERVICE}/ws_ParticipanteTipoCuenta`,
    )
      .then((response) => {
        if (response.data.header) {
          if (response.data.header.codReturn === '0') {
            resolve(response.data.data);
          } else {
            reject({ error: { message: response.data.header.txtReturn } });
          }
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

  getObligacionesSocietariasByCodigo = (data) => new Promise((resolve, reject) => {
    axios.post(
      `${API_URL_DIMO_SERVICE}/ws_obligacion_societaria`, { codigoConsulta: data }
    )
      .then((response) => {
        if (response.data.header) {
          if (response.data.header.codReturn === '0') {
            resolve(response.data.data);
          } else {
            reject({ error: { message: response.data.header.txtReturn } });
          }
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });


  generarPinExtraccion = (dataPrm) => new Promise((resolve, reject) => {
    axios.post(`${API_URL}/v1/extraccion/generar-pin`, dataPrm, { headers: HEADERS() })
      .then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
  });

  generarPinDeposito = (dataPrm) => new Promise((resolve, reject) => {
    axios.post(`${API_URL}/v1/deposito/generar-pin`, dataPrm, { headers: HEADERS() })
      .then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
  });

  //Obtener lista de ticket pagados
  getTicketList = (params) => new Promise((resolve, reject) => {
    let msgError = 'No se pudieron obtener los datos. Por favor intente nuevamente mas tarde.';
    axios.post(API_URL + '/v1/ticket/listar', params)
      .then((response) => {
        if (response.data.header.codRespuesta === '0') {
          resolve(response.data.data);
        } else {
          reject({
            error: {
              message: response.data.header ? response.data.header.txtReturn : msgError
            }
          });
        }
      })
      .catch((err) => {
        reject({
          error: {
            message: "Ocurrio un error inesperado." + " Favor vuelva a intentarlo mas tarde",
          }
        });
      });
  });

  getTicketItems = (params) => new Promise((resolve, reject) => {
    let msgError = 'No hay horarios disponobles. Por favor intente nuevamente mas tarde.';
    axios.post(API_URL + '/v1/ticket/items/listar', params)
      .then((response) => {
        if (response.data.header.codRespuesta === '0' && response.data.data.length > 0) {
          resolve(response.data.data);
        } else {
          reject({
            error: {
              message: response.data.header.codRespuesta === '0' ? msgError : response.data.header.txtRespuesta
            }
          });
        }
      })
      .catch((err) => {
        reject({
          error: {
            message: "Ocurrio un error inesperado." + " Favor vuelva a intentarlo mas tarde",
          }
        });
      });
  });

  getTicketTarifas = (params) => new Promise((resolve, reject) => {
    let msgError = 'No hay tarifas disponobles. Por favor intente nuevamente mas tarde.';
    axios.post(API_URL + '/v1/ticket/tarifa/listar', params)
      .then((response) => {
        if (response.data.header.codRespuesta === '0' && response.data.data.length > 0) {
          resolve(response.data.data);
        } else {
          reject({
            error: {
              message: response.data.header.codRespuesta === '0' ? msgError : response.data.header.txtRespuesta
            }
          });
        }
      })
      .catch((err) => {
        reject({
          error: {
            message: "Ocurrio un error inesperado." + " Favor vuelva a intentarlo mas tarde",
          }
        });
      });
  })

  // PAGO A BOCA COBRANZAS
  pagoBcMdw = (Transferencia, tipoCuentaOrigen) => new Promise((resolve, reject) => {

    const mensaje1A = this.getTrxSicoopTransBC_MDW(
      Transferencia.emisorOrigen,
      Transferencia.cuentaOrigen,
      Transferencia.ciOriginante ? Transferencia.ciOriginante : '',
      Transferencia.titularOriginante ? Transferencia.titularOriginante : '',
      tipoCuentaOrigen,
      Transferencia.emisorDestinatario,
      Transferencia.cuentaDestino,
      Transferencia.ciDestinatario ? Transferencia.ciDestinatario : '',
      Transferencia.titularDestinatario ? Transferencia.titularDestinatario : '',
      Transferencia.importe,
      Transferencia.importeCargo ? Transferencia.importeCargo : '0',
    );

    mensaje1A.aditionalParameters = mensaje1A.aditionalParameters.concat(
      Array.isArray(Transferencia.datosAdicionales) ? Transferencia.datosAdicionales : []
    );

    let URL = '/v1/pagoservicio';


    axios.post(API_URL + URL, { header: BODY_HEADER, data: mensaje1A }, { headers: HEADERS() })
      .then((response) => {
        if (response.data.hasOwnProperty('header')) {
          if (response.data.header.codReturn === '0') {
            if (!response.data.hasOwnProperty('data')) reject({ error: { message: 'No se pudo validar la respuesta de la operacion' } });
            // Si tiene la propiedad Data...
            // *** Evaluamos mejor el Response desde el Backend ***
            const { idOperacion } = response.data.data;
            const estadoResponse = response.data.data.estado;
            const descEstadoResponse = (response.data.data.hasOwnProperty('txtRespuesta')
              && response.data.data.txtRespuesta !== null && response.data.data.txtRespuesta.length > 1) ? response.data.data.txtRespuesta : 'Transaccion Rechazada';
            if (estadoResponse === 'AP') // Aprobado
            {
              resolve({
                estado: 'A', descripcion: 'Aprobada', idOperacion, request: mensaje1A, response: response.data.data
              });
            } else if (estadoResponse === 'NA') // Rechazado por alguna razon
            {
              resolve({
                estado: 'R', descripcion: descEstadoResponse, idOperacion, request: mensaje1A, response: response.data.data
              });// Rechazo Simple
            } else {
              reject({ error: { message: response.data.header.txtReturn } });
            }
          } else {
            reject({ error: { message: response.data.header.txtReturn } });
          }
        } else {
          // console.log('response incorrecto:' + response);
          reject({ error: {} });
        }
      })
      .catch((error) => {
        // GAException(error);
        reject(error);
      });
  });

}

const transactionService = new TransactionService();

export default transactionService;
